import { createRef } from 'react'
import { prune } from 'utilities/array'

export const onElementPresent = (selector, callback) => {
    const element = document.querySelector(selector)

    if(!element) {
        global.requestAnimationFrame(() => onElementPresent(selector, callback))
    } else {
        callback(element)
    }
}

export const onElementAdded = ({ element, parent, callback }) => {
    if(!(element instanceof Element) || !(parent instanceof Element)) {
        return
    }

    const observer = new MutationObserver(ms => ms.forEach(m => {
        m.addedNodes.forEach(addedNode => {
            if(addedNode === element || addedNode.contains(element)) {
                callback()
                observer.disconnect()
            }
        })
    }))

    observer.observe(parent, {
        subtree: true,
        childList: true
    })

    return observer
}

export const intersectionObserver = callback => {
    const observer = createRef()

    const ref = node => {
        observer?.current?.disconnect?.()

        if(node) {
            observer.current = new IntersectionObserver(([entry]) => {
                if(entry?.isIntersecting) {
                    callback(node)
                }
            })

            observer.current.observe(node)
        }
    }

    return {
        ref,
        destroy: observer?.current?.disconnect ?? (() => {})
    }
}

export const htmlToDomNodes = (html, wrapped = false) => {
    const parent = document.createElement('div')
    parent.innerHTML = html

    return wrapped ?
        parent :
        parent.children
}

export const cls = (...args) => prune(args
    .flat()
    .filter(item => typeof item === 'string')
    .flatMap(className => className.split(' '))
).join(' ')