import styled from 'styled-components'
import { fromPhone } from 'utilities/styled'
import { Grid } from 'components/grid'

export const Wrapper = styled(Grid)`
    row-gap: 24px;

    margin-inline: auto;
    width: min(var(--huma-global-content-tight-width), 100%);

    ${fromPhone`
        padding-block-start: 40px;
    `}
`

export const LoadingContainer = styled.div`
    position: relative;

    height: 96px;
`