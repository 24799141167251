import styled from 'styled-components'
import { Grid } from 'components/grid'
import Caption from 'components/typography/caption'
import { Plain } from 'components/button'

export const Title = styled.span`
    display: inline-block;
    position: relative;
    line-height: 24px;

    &.on {
        padding-inline-start: 32px;
    }
`

export const Emoji = styled.span`
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    font-size: 24px;
    line-height: 1;
    margin-top: -12px;

    [dir="rtl"] & {
        left: auto;
        right: 0;
    }
`

export const Columns = styled(Grid).attrs(() => ({ as: 'span' }))`
    grid-auto-columns: columns;
    grid-auto-flow: column;
    gap: 16px;
    justify-content: start;
`

export const Misty = styled(Caption).attrs(() => ({ as: 'span' }))`
    font-size: inherit;
`

export const PlainSmall = styled(Plain)`
    height: 24px;
`