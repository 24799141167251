import React, { Component, createRef } from 'react'
import { injectIntl } from 'react-intl'
import { FormContext } from 'components/form/controller'
import { omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import { Field, Label, Control, Error } from 'components/form/field/s'
import Toggler from 'components/form/input/toggler'
import OffsetPicker from 'pages/processes/pages/template/edit-task/offset-picker'

const empty = null

class OffsetField extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        const {
            name,
            field = {}
        } = props

        let {
            value,
            required = false,
            include = 'touched'
        } = field

        if(!value && value !== 0) {
            value = empty
        }

        this.state = {
            value,
            picking: false
        }

        context.registerField(name, {
            empty,
            required,
            include,

            unset: () => this.setState({
                value: empty,
                picking: false
            }),

            validator: value => required ?
                !!value :
                true
        })

        this.field = createRef()
        this.control = createRef()
    }

    componentDidMount() {
        this.context.triggerChange(this.props.name, { touched: false })
    }

    componentDidUpdate = ({ name }, { value }) => {
        if(value !== this.state.value) {
            this.context.triggerChange(name)
        }
    }

    componentWillUnmount() {
        this.context.unregisterField(this.props.name)
    }

    openPicker = () => this.setState({ picking: true })
    closePicker = () => this.setState({ picking: false })
    unset = () => this.onPicked(empty)

    onPicked = value => {
        this.setState({ value })

        const { onChange, name } = this.props
        onChange?.({ [name]: value })

        this.context.triggerChange(name)
    }

    render() {
        const {
            value = null,
            picking
        } = this.state

        const {
            className,
            field = {},
            picker = {},
            controlProps = {},
            valueDisplayFormatters = {},
            label,
            salt,
            name,
            enabled = true,
            loading = false,
            error: outsideError,
            intl: { formatMessage },
            ...props
        } = this.props

        const { required } = field

        const {
            heading,
            outer = true
        } = picker

        const touched = this.context.touched.includes(name)
        const error = ((name in this.context.errors) && touched) || !!outsideError

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const id = `${salt}:${name}`

        return (
            <Field
                {...(fieldClassName ? { className: fieldClassName } : null)}
                ref={this.field}>
                {!!label && (
                    <Label
                        htmlFor={id}
                        required={required}>
                        {label}
                    </Label>
                )}
                <Control>
                    <input
                        name={name}
                        defaultValue={value}
                        type="hidden"
                        key={`${id}:input:${value}`} />
                    <Toggler
                        {...controlProps}
                        {...omit(props, 'field')}
                        id={id}
                        onClick={this.openPicker}
                        active={picking}
                        type="date"
                        disabled={!enabled}
                        unset={Number.isInteger(value) && this.unset}
                        ref={this.control}>
                        {getDisplayValue(value, valueDisplayFormatters)}
                    </Toggler>
                    <Error animate={outsideError ? 'reveal' : 'hide'}>
                        {outsideError}
                    </Error>
                    <OffsetPicker
                        {...picker}
                        {...{
                            heading,
                            outer,
                            salt
                        }}
                        offset={value}
                        show={picking}
                        dismiss={this.closePicker}
                        cancelAction={() => ({
                            label: formatMessage({
                                id: 'action_cancel',
                                defaultMessage: 'Cancel'
                            }),
                            effect: 'neutral',
                            onClick: this.closePicker
                        })}
                        doneAction={({ picked }) => ({
                            label: formatMessage({
                                id: 'action_done',
                                defaultMessage: 'Done'
                            }),
                            effect: 'constructive',
                            onClick: () => this.onPicked(picked)
                        })} />
                </Control>
            </Field>
        )
    }
}

const getDisplayValue = (value, formatters) => {
    if(value === null) {
        return ''
    }

    const values = { count: Math.abs(value) }

    if(formatters?.negative && value < 0) {
        return formatters.negative(values)
    }

    if(formatters?.neutral && value === 0) {
        return formatters.neutral(values)
    }

    if(formatters?.positive && value > 0) {
        return formatters.positive(values)
    }

    return value
}

export default injectIntl(OffsetField)