import React, { Component, createContext, useContext } from 'react'
import { local } from 'utilities/storage'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import constants from 'constants'

const ThemeContext = createContext()
ThemeContext.displayName = 'Theme'

export default class ThemeProvider extends Component {
    constructor(props) {
        super(props)

        const name = local.get('theme') ?? getThemeNameFromUserPreference()

        this.state = {
            name,

            setTheme: this.set
        }
    }

    componentDidMount() {
        this.set(this.state.name)
    }

    set = name => {
        if(!['light', 'dark'].includes(name)) {
            return
        }

        const html = document.documentElement
        const colorSchemeMeta = document.querySelector('meta[name="color-scheme"]')

        if(name === 'system' || !name) {
            const isDarkMode = global.matchMedia('(prefers-color-scheme: dark)').matches

            html.dataset.theme = isDarkMode ? 'dark' : 'light'
            colorSchemeMeta.content = isDarkMode ? 'dark' : 'light'

        } else {
            html.dataset.theme = name
            colorSchemeMeta.content = name
        }

        this.setState({ name }, this.store)
    }

    store = () => local.set('theme', this.state.name)

    render() {
        const { children = null } = this.props

        return (
            <ThemeContext.Provider value={this.state}>
                <StyledThemeProvider theme={constants}>
                    {(typeof children === 'function') && children(this.state)}
                    {(typeof children !== 'function') && children}
                </StyledThemeProvider>
            </ThemeContext.Provider>
        )
    }
}

const getThemeNameFromUserPreference = () => {
    return global.matchMedia?.('(prefers-color-scheme: dark)').matches ?
        'dark' :
        'light'
}

export const useTheme = () => useContext(ThemeContext)