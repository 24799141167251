import { css } from 'styled-components'
import { svgStroke } from 'utilities/styled'

export const emojiCss = css`
    ${svgStroke()}

    &.poor { color: var(--huma-color-chart-grade-poor); }
    &.fair { color: var(--huma-color-chart-grade-fair); }
    &.good { color: var(--huma-color-chart-grade-good); }
    &.excellent { color: var(--huma-color-chart-grade-excellent); }
`