import React, { useState, useLayoutEffect, createContext, useContext } from 'react'
import { useEnvironment } from 'contexts/environment'
import { AnalyticsBrowser } from '@segment/analytics-next'

const noop = () => {}

const SegmentContext = createContext()
SegmentContext.displayName = 'Segment'

const SegmentProvider = ({ children }) => {
    const { integrations } = useEnvironment()

    const [value, setValue] = useState({
        identify: noop,
        group: noop,
        page: noop,
        track: noop,
        reset: noop
    })

    const [initialized, setInitialized] = useState(false)

    useLayoutEffect(() => {
        if(!!integrations?.segmentAppId && !initialized) {
            setInitialized(true)

            const segment = AnalyticsBrowser.load({ writeKey: integrations?.segmentAppId })

            segment.addSourceMiddleware(({ payload, next }) => {
                delete payload.obj?.context?.page?.url
                delete payload.obj?.properties?.url
                delete payload.obj?.context?.page?.title
                delete payload.obj?.properties?.title
                delete payload.obj?.context?.page?.search
                delete payload.obj?.properties?.search

                next(payload)
            })

            setValue(segment)
        }
    }, [initialized, integrations?.segmentAppId])

    return (
        <SegmentContext.Provider value={value}>
            {(typeof children === 'function') && children(value)}
            {(typeof children !== 'function') && children}
        </SegmentContext.Provider>
    )
}

export const useSegment = () => useContext(SegmentContext)

export default SegmentProvider