import { useI18n } from 'contexts/i18n'
import { useIntl } from 'react-intl'
import { useDurationFormatter } from 'utilities/date-time'
import { safeFormat } from 'utilities/date-time'
import { surveyLabels, surveyClassNames } from 'pages/surveys/constants'

export const useSurveyStatus = () => {
    const { formatMessage } = useIntl()

    return status => {
        if(!status) {
            return null
        }

        status = status.toLowerCase()

        const label = surveyLabels[status] ?? null

        return {
            name: status,
            className: surveyClassNames[status] ?? 'neutral',
            label: label ?
                formatMessage(label)
                : null
        }
    }
}

export const useFormatSurveyRunTitle = () => {
    const { dateLocale: locale } = useI18n()
    return (run, format = 'PP') => safeFormat(run.createdAt, format, { locale })
}

export const getSurveyFrequencyDescriptionTranslation = frequency => {
    if(!frequency) {
        return null
    }

    frequency.unit = frequency.unit.toLowerCase()

    // Describe as [fractions of] years
    if(
        (frequency.unit === 'years') ||
        // (frequency.unit === 'quarters' && frequency.value % 4 === 0) ||
        (frequency.unit === 'months' && (frequency.value % 12 === 0 || frequency.value === 6))
    ) {
        if(frequency.unit === 'months' && frequency.value === 6) {
            return {
                id: 'frequency_years_twice',
                defaultMessage: 'Twice a year'
            }
        }

        const denominator = (frequency.unit === 'months') ? 12 : 1
        const count = frequency.value / denominator

        if(count === 2) {
            return {
                id: 'frequency_years_every_other',
                defaultMessage: 'Every other year'
            }
        }

        return {
            id: 'frequency_years_every',
            defaultMessage: '{count, plural, =0 {} =1 {Every year} other {Every {count} years}}',
            values: { count }
        }
    }

    // Describe as quarters
    if(frequency.unit === 'months' && frequency.value === 3) {
        return {
            id: 'frequency_quarters_every',
            defaultMessage: '{count, plural, =0 {} =1 {Every quarter} other {Every {count} quarters}}',
            values: { count: 1 }
        }
    }

    // Describe as months
    if(frequency.unit === 'months') {
        if(frequency.value === 2) {
            return {
                id: 'frequency_months_every_other',
                defaultMessage: 'Every other month'
            }
        }

        return {
            id: 'frequency_months_every',
            defaultMessage: '{count, plural, =0 {} =1 {Every month} other {Every {count} months}}',
            values: { count: frequency.value }
        }
    }

    if(
        (frequency.unit === 'weeks') ||
        (frequency.unit === 'days' && frequency.value % 7 === 0)
    ) {
        const denominator = (frequency.unit === 'days') ? 7 : 1
        const count = frequency.value / denominator

        if(count === 2) {
            return {
                id: 'frequency_weeks_every_other',
                defaultMessage: 'Every other week'
            }
        }

        return {
            id: 'frequency_weeks_every',
            defaultMessage: '{count, plural, =0 {} =1 {Every week} other {Every {count} weeks}}',
            values: { count }
        }
    }

    if(frequency.unit === 'days') {
        if(frequency.value === 2) {
            return {
                id: 'frequency_days_every_other',
                defaultMessage: 'Every other day'
            }
        }

        return {
            id: 'frequency_days_every',
            defaultMessage: '{count, plural, =0 {} =1 {Every day} other {Every {count} days}}',
            values: { count: frequency.value }
        }
    }

    return null
}

const customFallbackSymbol = {
    emoji: 'speech_balloon',
    colorToken: 'symbol-blue-1'
}

const enpsFallbackSymbol = {
    emoji: 'relaxed',
    colorToken: 'symbol-yellow-1'
}

export const getFallbackSymbol = survey => ({
    enps: enpsFallbackSymbol,
    custom: customFallbackSymbol
})?.[survey?.type] ?? customFallbackSymbol

const questionTypeToDuration = {
    text: 15,
    textarea: 30,
    radiobuttons: 15,
    checkboxes: 20,
    rating: 10,
    scale: 10,
    date: 20
}

export const estimateSurveyRunDuration = questions => questions.reduce((total, question) => (
    total + (questionTypeToDuration?.[question.meta.type] ?? 10)
), 0)

export const useFormatSurveyRunDuration = () => {
    const { formatMessage } = useIntl()
    const formatDuration = useDurationFormatter()

    return surveyRun => {
        const duration = estimateSurveyRunDuration(surveyRun.questions)

        return formatMessage({
            id: 'about_time',
            defaultMessage: 'About {time}'
        }, {
            time: formatDuration({
                minutes: Math.floor(duration / 60),
                ...((Math.floor(duration / 60) === 0) ? { seconds: duration % 60 } : null)
            })
        })
    }
}

export const useFriendlyFormatNextSurveyRunTime = () => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const defaultFormat = formatMessage({
        id: 'date_fns_format_weekday_date_time_friendly',
        defaultMessage: "EEEE PPP 'at' p"
    })

    return (survey, format = defaultFormat) => safeFormat(
        survey.nextRunTime,
        format,
        { locale }
    )
}

export const weeksInHours = weeks => Number(weeks) * 24 * 7
export const hoursInWeeks = hours => Number(hours) / 24 / 7