import React, { Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useSurvey } from 'contexts/survey'
import { cls } from 'utilities/dom'
import { StatusLabel } from './s'
import Tooltip from 'components/tooltip'
import { Anonymous, Identified } from 'components/feather'

const AnonymousStatus = ({ perspective = 'respondent', className }) => {
    const { formatMessage } = useIntl()

    const { getResponseRun } = useSurvey()
    const source = getResponseRun()

    const { anonymous = false } = source

    const StatusIcon = anonymous ?
        Anonymous :
        Identified

    const [Wrapper, wrapperProps = null] = (anonymous && source.type === 'custom' && perspective === 'respondent') ?
        [Tooltip, {
            content: formatMessage({
                id: 'survey_responses_anonymous_details',
                defaultMessage: 'A characteristic writing style, or unique set of group memberships, may indirectly hint at who you are, regardless of the technical measures in place to keep you anonymous.'
            }),
            position: 'top'
        }] :
        [Fragment]

    return (
        <Wrapper {...wrapperProps}>
            <StatusLabel className={cls(['neutral', className])}>
                <StatusIcon size={16} />
                <FormattedMessage {...getStatusMessage(anonymous, perspective)} />
            </StatusLabel>
        </Wrapper>
    )
}

const getStatusMessage = (anonymous, perspective) => {
    if(anonymous) {
        if(perspective === 'respondent') {
            return {
                id: 'survey_responses_user_anonymous',
                defaultMessage: 'Responses will not be linked to you'
            }
        }

        return {
            id: 'survey_responses_anonymous',
            defaultMessage: 'Responses are anonymous'
        }
    }

    if(perspective === 'respondent') {
        return {
            id: 'survey_responses_user_not_anonymous',
            defaultMessage: 'Responses will be linked to you'
        }
    }

    return {
        id: 'survey_responses_not_anonymous',
        defaultMessage: 'Responses are not anonymous'
    }
}

export default AnonymousStatus