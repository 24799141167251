import React, { Component, createContext, useContext } from 'react'
import { get } from 'api'
import debounce from 'lodash.debounce'

const PendingSurveyRunsContext = createContext()
PendingSurveyRunsContext.displayName = 'PendingSurveyRuns'

export default class PendingSurveyRunsProvider extends Component {
    constructor(props) {
        super(props)

        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        this.state = {
            pendingSurveyRuns: [],

            fetchPendingSurveyRuns: this.fetch,
            completeSurveyRun: this.completeSurveyRun,
            checkSurveyRunStatus: this.checkSurveyRunStatus,

            fetching: false,
            hasFetched: false
        }
    }

    componentDidMount() {
        const {
            fetchAccess = true,
            fetchOnMount = true
        } = this.props

        if(fetchAccess && fetchOnMount) {
            this.fetchDebounced()
        }
    }

    componentDidUpdate(props) {
        const { fetchAccess: previousFetchAccess = true } = props
        const { fetchAccess = true } = this.props

        if(!previousFetchAccess && fetchAccess) {
            this.fetchDebounced()
        }
    }

    fetch = async () => {
        this.setState({ fetching: true })

        const { ok, response } = await get({ path: '/surveys/pending' })

        const state = {
            fetching: false,
            hasFetched: true
        }

        if(ok && !!response?.items?.length) {
            const { types = ['custom', 'enps'] } = this.props

            state.pendingSurveyRuns = response.items.filter(({ type }) => types.includes(type))
        }

        this.setState(state)
    }

    completeSurveyRun = run => void this.setState(({ pendingSurveyRuns }) => ({
        pendingSurveyRuns: pendingSurveyRuns.filter(({ id }) => id !== run.id)
    }))

    checkSurveyRunStatus = async id => {
        const { ok, response, status } = await get({ path: `/surveys/runs/${id}/response` })

        return {
            ok,
            response: {
                status: ((status === 404) ?
                    'not-found' :
                    response?.status?.toLowerCase()
                ) ?? null
            }
        }
    }

    render() {
        const { children = null } = this.props

        return (
            <PendingSurveyRunsContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </PendingSurveyRunsContext.Provider>
        )
    }
}

export const usePendingSurveyRuns = () => useContext(PendingSurveyRunsContext)