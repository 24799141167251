import React, { Component, createContext, useContext, createRef } from 'react'
import { intersectionObserver } from 'utilities/dom'
import { local } from 'utilities/storage'
import debounce from 'lodash.debounce'
import PubSub from 'pubsub-js'
import isEqual from 'react-fast-compare'
import { size, reduce, withoutEmptyArrays } from 'utilities/object'
import { get, post, patch, remove } from 'api'

const EquipmentByTypeContext = createContext()
EquipmentByTypeContext.displayName = 'EquipmentByType'

export default class EquipmentByTypeProvider extends Component {
    constructor(props) {
        super(props)

        const { eternal = true } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        this.intersectionObserver = intersectionObserver(this.onIntersect)
        this.pagingDefaults = pagingDefaults(props?.paging)
        this.sortCacheKey = props?.sortCacheKey

        let sorting = sortingDefaults()
        if(this.sortCacheKey) {
            const cachedSorting = local.get(this.sortCacheKey)
            if(cachedSorting) {
                sorting = cachedSorting
            }
        }

        this.flash = createRef()

        this.state = {
            types: [],
            filter: props?.filter ?? {},
            sorting,
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            flash: this.flash,
            clearFlash: this.clearFlash,

            fetch: this.fetch,
            addType: this.add,
            updateType: this.update,
            removeType: this.remove,
            setTypesFilter: this.setFilter,
            toggleSorting: this.toggleSorting,

            hasFetched: false,
            fetchError: false,
            autoFetch: false,
            fetching: false,
            loading: false
        }

        PubSub.subscribe('equipmentByType.refresh', () => {
            this.setState({
                types: [],
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }, this.fetchDebounced)
        })
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetchDebounced()
    }

    componentDidUpdate({ paging }, { filter, sorting }) {
        const pagingChanged = !isEqual(paging, this.props.paging)
        const filterChanged = !isEqual(filter, this.state.filter)
        const sortingChanged = !isEqual(sorting, this.state.sorting)

        const state = {}

        if(pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props.paging)
            state.paging = this.pagingDefaults()
        }

        this.setState(size(state) ? state : null, () => {
            if(filterChanged || sortingChanged) {
                this.fetchDebounced(true)
            }
        })
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver.destroy()
        PubSub.unsubscribe('equipmentByType.refresh')
    }

    fetch = async (force = false) => {
        const {
            fetching,
            filter,
            sorting,
            paging,
            autoFetch,
            hasFetched,
            eternal
        } = this.state

        if((fetching || (hasFetched && !eternal)) && !force) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            fetchError: false,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetched ? (paging.offset + this.pagingDefaults().limit) : 0,
            limit: paging.limit
        }

        const { response, ok } = await get({
            path: '/equipment/by-type',
            params: {
                ...filter,
                ...nextPaging,
                orderBy: sorting.by,
                orderDirection: sorting.direction
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
            this.setState(({ types: previousTypes }) => {
                const previousTypesWithoutFetched = previousTypes.filter(({ id: addedId }) => {
                    return !response.items.find(({ id: itemId }) => addedId === itemId)
                })

                const types = [
                    ...previousTypesWithoutFetched,
                    ...response.items
                ]

                return {
                    types,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        limit: this.pagingDefaults().limit,
                        hasNextPage: response.items.length && types.length < response.total
                    },
                    hasFetched: true,
                    fetchError: false,
                    autoFetch: !!previousTypes.length && hasFetched,
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                hasFetched: true,
                fetchError: true,
                autoFetch: false,
                fetching: false,
                loading: false
            })
        }
    }

    add = async body => {
        const { ok, response: type } = await post({
            path: '/equipment-types',
            body
        })

        if(ok && type) {
            this.setState(({ types }) => ({
                types: [
                    type,
                    ...types
                ]
            }))
        }

        return { ok, response: type }
    }

    update = async (body, id) => {
        const { ok, response: type } = await patch({
            path: `/equipment-types/${id}`,
            body
        })

        if(ok && type) {
            this.setState(({ types }) => {
                const index = types.findIndex(type => type.id === id)
                this.flash.current = type

                return {
                    types: [
                        ...types.slice(0, index),
                        type,
                        ...types.slice(index + 1, types.length)
                    ]
                }
            })
        }

        return { ok, response: type }
    }

    remove = async (id, params = {}) => {
        const { ok } = await remove({
            path: `/equipment-types/${id}`,
            params,
            returnsData: false
        })

        if(ok) {
            this.setState(({ types, paging }) => ({
                types: types.filter(type => type.id !== id),
                paging: {
                    ...paging,
                    offset: paging.offset - 1,
                    limit: paging.limit + 1
                }
            }))
        }

        return { ok }
    }

    setFilter = (filter = {}, sorting = {}) => {
        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }))

        const nextFilter = {
            ...(this.props.filter ?? null),
            ...filter
        }

        sorting = {
            ...sortingDefaults(),
            ...sorting
        }

        this.setState(({ filter: previousFilter, sorting: previousSorting }) => {
            const filterChanged = !isEqual(
                withoutEmptyArrays(nextFilter),
                withoutEmptyArrays(previousFilter)
            )

            const sortingChanged = !isEqual(sorting, previousSorting)

            if(!filterChanged && !sortingChanged) {
                return null
            }

            const state = {
                types: [],
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }

            if(filterChanged) {
                state.filter = nextFilter
            }

            if(sortingChanged) {
                state.sorting = sorting
                !!this.sortCacheKey && local.set(this.sortCacheKey, sorting)
            }

            return state
        })
    }

    toggleSorting = field => {
        const sortingOptions = getSortingOptions()

        if(field in sortingOptions) {
            this.setState(({ sorting }) => {
                const toggled = {
                    by: field,
                    direction: (sorting.by === field) ?
                        (sorting.direction === 'asc' ? 'desc' : 'asc') :
                        sortingOptions[field]
                }

                !!this.sortCacheKey && local.set(this.sortCacheKey, toggled)

                return {
                    types: [],
                    sorting: toggled,
                    paging: this.pagingDefaults(),
                    hasFetched: false,
                    autoFetch: false
                }
            })
        }
    }

    onIntersect = () => {
        const {
            eternal,
            loading,
            paging,
            autoFetch
        } = this.state

        if(!eternal) {
            return
        }

        if(!loading && paging.hasNextPage && autoFetch) {
            this.fetchDebounced()
        }
    }

    clearFlash = () => void this.setState(({ types }) => {
        this.flash.current = null

        return {
            types: [...types]
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <EquipmentByTypeContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </EquipmentByTypeContext.Provider>
        )
    }
}

const getSortingOptions = () => ({
    type_name: 'asc',
    belongs_to: 'asc',
    return_date: 'asc'
})

const sortingDefaults = () => {
    const sortingOptions = getSortingOptions()

    return {
        by: Object.keys(sortingOptions)[0],
        direction: Object.values(sortingOptions)[0]
    }
}

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

export const useEquipmentByType = () => useContext(EquipmentByTypeContext)