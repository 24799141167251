import React, { Component, createContext, useContext, createRef } from 'react'
import { intersectionObserver } from 'utilities/dom'
import debounce from 'lodash.debounce'
import PubSub from 'pubsub-js'
import isEqual from 'react-fast-compare'
import { local } from 'utilities/storage'
import { size, reduce, withoutEmptyArrays } from 'utilities/object'
import { enrichEvent } from 'pages/meetings/utilities'
import { areGroupedFiltersApplied } from 'pages/meetings/components/events-filter'
import { get, post, remove } from 'api'

const MeetingEventsContext = createContext()
MeetingEventsContext.displayName = 'MeetingEvents'

export default class MeetingEventsProvider extends Component {
    constructor(props) {
        super(props)

        const { eternal = true } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        this.intersectionObserver = intersectionObserver(this.onIntersect)
        this.sortCacheKey = props?.sortCacheKey

        let sorting = sortingDefaults(props?.sorting)
        if(this.sortCacheKey) {
            const cachedSorting = local.get(this.sortCacheKey)
            if(cachedSorting) {
                sorting = cachedSorting
            }
        }

        this.pagingDefaults = pagingDefaults(props?.paging)

        this.flash = createRef()

        this.state = {
            events: [],
            total: 0,
            filter: props.filter ?? {},
            sorting,
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            flash: this.flash,
            clearFlash: this.clearFlash,

            createEvent: this.createEvent,
            removeEvent: this.removeEvent,

            createRoundEvents: this.createRoundEvents,

            fetch: this.fetch,
            setEventsFilter: this.setFilter,

            hasFetched: false,
            autoFetch: false,
            fetching: false,
            loading: false
        }

        PubSub.subscribe('meetingEvents.refresh', () => {
            this.setState({
                events: [],
                sorting: sortingDefaults(),
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }, this.fetchDebounced)
        })
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetchDebounced()
    }

    componentDidUpdate({ paging }, { filter, sorting }) {
        const pagingChanged = !isEqual(paging, this.props.paging)
        const filterChanged = !isEqual(filter, this.state.filter)
        const sortingChanged = !isEqual(sorting, this.state.sorting)

        const state = {}

        if(pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props.paging)
            state.paging = this.pagingDefaults()
        }

        this.setState(size(state) ? state : null, () => {
            if(filterChanged || sortingChanged) {
                this.fetchDebounced(true)
            }
        })
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver.destroy()
        PubSub.unsubscribe('meetingEvents.refresh')
    }

    fetch = async (force = false) => {
        const {
            fetching,
            filter,
            sorting,
            paging,
            autoFetch,
            hasFetched,
            eternal
        } = this.state

        if((fetching || (hasFetched && !eternal)) && !force) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetched ? (paging.offset + this.pagingDefaults().limit) : 0,
            limit: paging.limit
        }

        const { response, ok } = await get({
            path: `/performance/meetings`,
            params: {
                ...filter,
                ...nextPaging,
                orderBy: sorting.by,
                orderDirection: sorting.direction
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
            this.setState(({ events: previousEvents }) => {
                // const previousEventsWithoutFetched = previousEvents.filter(({ id: addedId }) => {
                //     return !response.items.find(({ id: itemId }) => addedId === itemId)
                // })

                const events = [
                    ...previousEvents,
                    ...response.items.map(enrichEvent)
                ]

                return {
                    events,
                    total: response.total,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        limit: this.pagingDefaults().limit,
                        hasNextPage: response.items.length && events.length < response.total
                    },
                    hasFetched: true,
                    autoFetch: hasFetched,
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                hasFetched: true,
                autoFetch: false,
                fetching: false,
                loading: false
            })
        }
    }

    createEvent = async body => {
        const { ok, response } = await post({
            path: '/performance/meetings',
            body
        })

        return { ok, response }
    }

    removeEvent = async id => {
        const { ok } = await remove({
            path: `/performance/meetings/${id}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ events, total, paging }) => ({
                events: events.filter(event => event.id !== id),
                total: total - 1,
                paging: {
                    ...paging,
                    offset: paging.offset - 1,
                    limit: paging.limit + 1
                }
            }))

            PubSub.publish('meetingRound.decreaseCount')
        }

        return { ok }
    }

    createRoundEvents = async (body, id) => {
        const { ok, response } = await post({
            path: `/performance/meeting-rounds/${id}/meetings`,
            body
        })

        if(ok && response) {
            this.setState(({ events: previousEvents }) => ({
                events: [
                    ...response.items,
                    ...previousEvents
                ]
            }))
        }

        return { ok, response }
    }

    setFilter = (filter = {}, sorting = {}) => {
        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }))

        sorting = {
            ...sortingDefaults(),
            ...sorting,
            // If 'concerns' or 'host' is set, sort by descending date
            ...(areGroupedFiltersApplied(filter) ? {
                by: 'date',
                direction: 'desc'
            } : null)
        }

        const nextFilter = {
            ...(this.props.filter ?? null),
            ...filter
        }

        this.setState(({ filter: previousFilter, sorting: previousSorting }) => {
            if(!!size(filter) && !!this.props.filter?.status?.length) {
                delete nextFilter.status
            }

            const filterChanged = !isEqual(
                withoutEmptyArrays(nextFilter),
                withoutEmptyArrays(previousFilter)
            )

            const sortingChanged = !isEqual(sorting, previousSorting)

            if(!filterChanged && !sortingChanged) {
                return null
            }

            const state = {
                events: [],
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }

            if(filterChanged) {
                state.filter = nextFilter
            }

            if(sortingChanged) {
                state.sorting = sorting
                !!this.sortCacheKey && local.set(this.sortCacheKey, sorting)
            }

            return state
        })
    }

    onIntersect = () => {
        const {
            eternal,
            loading,
            paging,
            autoFetch
        } = this.state

        if(!eternal) {
            return
        }

        if(!loading && paging.hasNextPage && autoFetch) {
            this.fetchDebounced()
        }
    }

    clearFlash = () => void this.setState(({ events }) => {
        this.flash.current = null

        return {
            events: [...events]
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <MeetingEventsContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </MeetingEventsContext.Provider>
        )
    }
}

const getSortingOptions = () => ({
    date: 'asc'
})

const sortingDefaults = (overrides = {}) => {
    const sortingOptions = getSortingOptions()

    return {
        by: Object.keys(sortingOptions)[0],
        direction: Object.values(sortingOptions)[0],
        ...overrides
    }
}

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

export const useMeetingEvents = () => useContext(MeetingEventsContext)