import React from 'react'
import { useIntl } from 'react-intl'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { compact } from 'utilities/array'
import { capitalize } from 'utilities/string'
import StatisticsGroup from 'components/statistics-group-modern'

const PreviewProcessTemplateMeta = ({ template }) => {
    const {
        formatMessage,
        formatDisplayName
    } = useIntl()

    if(!template?.language && !template?.legislation && !template?.providedBy) {
        return null
    }

    return (
        <StatisticsGroup
            items={compact([
                template.language && {
                    value: formatMessage({
                        id: 'noun_language',
                        defaultMessage: 'Language'
                    }),
                    content: capitalize(formatDisplayName(template.language, { type: 'language' }))
                },
                template.legislation && {
                    value: formatMessage({
                        id: 'noun_legislation',
                        defaultMessage: 'Legislation'
                    }),
                    content: `${getUnicodeFlagIcon(template.legislation)} ${formatDisplayName(template.legislation, { type: 'region' })}`
                },
                template.providedBy && {
                    value: formatMessage({
                        id: 'provided_by',
                        defaultMessage: 'Provided by'
                    }),
                    content: template.providedBy.name
                }
            ])}
            className="centered tighter rows compact" />
    )
}

export default PreviewProcessTemplateMeta