import styled from 'styled-components'
import { motion } from 'framer-motion'
import { belowTablet } from 'utilities/styled'
import { titleCss } from 'components/typography/heading'
import { Button as ButtonBase } from 'components/button'

export const ConfettiCanvas = styled.canvas`
    pointer-events: none;
    position: absolute;
    inset: 0;

    width: 100%;
    height: 100%;
`

export const Heading = styled(motion.h2).attrs({
    variants: {
        out: {
            opacity: 0
        },
        in: {
            opacity: 1,
            transition: {
                duration: .375,
            }
        }
    }
})`
    ${titleCss}
    font-size: 56px;
    line-height: 1.2;
    text-align: center;

    ${belowTablet`
        font-size: max(32px, 5vw);
    `}
`

export const Button = styled(motion(ButtonBase)).attrs({
    variants: {
        out: {
            opacity: 0,
            y: 32
        },
        in: {
            opacity: 1,
            y: 0,
            transition: {
                duration: .375,
            },
            delay: .25
        }
    }
})``