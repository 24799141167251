import React, { useRef, useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useDirty } from 'contexts/modal'
import { useHandbook } from 'contexts/handbook'
import { useAccess } from 'contexts/access'
import { useHandbookTopicTemplate } from 'contexts/handbook-topic-template'
import { useEntity } from 'contexts/entity'
import { useHistate } from 'hooks/histate'
import { reduce, size, omit, map } from 'utilities/object'
import { compact } from 'utilities/array'
import {
    Menu, MenuColumns, MenuColumn,
    CloseButton, Explanation,
    Content
} from '../s'
import { UsefulLinks, ArrayField } from './s'
import Status from 'pages/handbook/components/status'
import Tooltip from 'components/tooltip'
import { Plain, Ghost, Button } from 'components/button'
import ContextMenu, { getBoundActions } from 'widgets/context-menu'
import Form from 'components/form/controller'
import Message from 'components/message'
import SymbolField from 'components/form/field/symbol'
import StringField from 'components/form/field/string'
import EditorField from 'components/form/field/editor'
import Meta from './meta'
import ConnectedTemplate from './connected-template'
import { Scrollable as Modal } from 'modals/generic'
import Prompt from './prompt'
import { X, EyeOff as Unpublish } from 'styled-icons/feather'
import { blankSymbol } from 'pages/handbook'

const EditHandbookTopic = ({
    category: initialCategory = {},
    topic = {},
    index,
    actions,
    loading,
    openTemplate, setOpenTemplate,
    editFocus = null,
    dismiss,
    onDone,
    salt
}) => {
    const { formatMessage } = useIntl()
    const { locale } = useI18n()

    const [dirty, setDirty] = useDirty()
    const updateDirty = () => setDirty(map(fields, ({ changed }) => changed).some(Boolean))

    const templateRef = useRef()

    const {
        handbook,
        updateTopic,
        addTopic
    } = useHandbook()

    const { forceUpdated = null } = useEntity()

    const { template } = useHandbookTopicTemplate()

    const { checkFeature } = useAccess()
    const groupsAvailable = checkFeature('handbook-groups')

    const {
        symbol: initialSymbol = blankSymbol,
        title: initialTitle = '',
        content: initialContent = [],
        links: initialLinks = [],
        shares: initialShares = [{ type: 'organization' }],
        language: initialLanguage = locale,
        tags: initialTags = [],
        pinned: initialPinned = false
    } = topic ?? {}

    const fields = {
        symbol: useHistate(initialSymbol, updateDirty),
        title: useHistate(initialTitle, updateDirty),
        content: useHistate(initialContent, updateDirty),
        category: useHistate(initialCategory, updateDirty),
        links: useHistate(initialLinks, updateDirty),
        shares: useHistate(initialShares, updateDirty),
        language: useHistate(initialLanguage, updateDirty),
        tags: useHistate(initialTags, updateDirty),
        pinned: useHistate(initialPinned, updateDirty)
    }

    const [updating, setUpdating] = useState(null)
    const [prompting, setPrompting] = useState(null)

    const addOrUpdate = async (body = {}) => {
        setUpdating(body.status ?? 'update')

        const update = !!topic.id

        body = {
            ...reduce(
                fields,
                (accumulator, { changed, current }, key) => ({
                    ...accumulator,
                    ...(((update && changed) || !update) ? {
                        [fieldKeyToNameMap[key] ?? key]: current?.id ?? current
                    } : null)
                }),
                omit(body, ...Object.keys(fields))
            )
        }

        if(!size(body)) {
            return void dismiss()
        }

        let result

        if(update) {
            result = await updateTopic({ body, id: topic.id })
        } else {
            result = await addTopic({ body, index })
        }

        const { ok, response } = result

        setUpdating(null)

        if(ok) {
            // Reinitialize dirty state…
            Object.values(fields).forEach(({ reinitialize }) => reinitialize())

            // Notify any outside subscribers…
            onDone?.({
                ...response,
                notify: body.notify
            })

            // Dismiss the modal after publishing the topic, keep it open otherwise.
            if(response.status === 'published') {
                dismiss()
            }
        }

        return { ok, response }
    }

    const scrollTemplateIntoView = () => {
        if(templateRef?.current) {
            // Wait for the template to open
            global.setTimeout(() => void templateRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            }), 150)
        }
    }

    useEffect(() => {
        if(editFocus === 'template') {
            scrollTemplateIntoView()
        }
    }, [editFocus])

    const status = (!groupsAvailable && fields.shares.current.some(({ type }) => ['team', 'location'].includes(type))) ?
        'inaccessible' :
        (topic?.status ?? 'draft')

    const boundActions = getBoundActions(actions, topic)

    const hasNewerTemplate =
        !!topic?.updatedDate && !!topic?.templateDate && !!template?.updatedDate &&
        new Date(template.updatedDate) > new Date(topic?.templateDate) &&
        new Date(template?.updatedDate) > new Date(topic?.updatedDate)

    return (
        <>
            <Form
                layout="vertical"
                key={`${salt}:updated:${forceUpdated ?? 'now'}`}>
                {({ errors }) => {
                    const ready = !updating && !loading && !size(errors ?? {})
                    const draft = !topic.status || topic.status === 'draft'

                    const enableSave = ready && dirty
                    const enablePublish = ready && draft

                    return (
                        <>
                            <Menu>
                                <CloseButton onClick={dismiss}>
                                    <X size={24} />
                                </CloseButton>
                                <MenuColumns>
                                    {!!topic.status && (
                                        <MenuColumn>
                                            <Status
                                                status={compact([handbook?.status, status]).join('_')}
                                                tooltip={status === 'inaccessible'} />
                                            {(handbook.status === 'draft' && topic.status === 'published') && (
                                                <Tooltip
                                                    content={formatMessage({
                                                        id: 'handbooks_topic_tooltip_handbook_draft',
                                                        defaultMessage: 'This topic will become available once the handbook is published.'
                                                    })}
                                                    placement="bottom">
                                                    <Explanation>
                                                        <Unpublish size={14} />
                                                    </Explanation>
                                                </Tooltip>
                                            )}
                                        </MenuColumn>
                                    )}
                                    {(topic.status === 'published') && (
                                        <MenuColumn className="push">
                                            <Button
                                                className={`constructive${updating ? ' loading' : ''}`}
                                                disabled={!enableSave}
                                                onClick={() => {
                                                    if(handbook.status === 'published') {
                                                        setPrompting({
                                                            method: 'update',
                                                            id: topic.id
                                                        })
                                                    } else {
                                                        addOrUpdate()
                                                    }
                                                }}>
                                                <FormattedMessage
                                                    id="action_update"
                                                    defaultMessage="Update" />
                                            </Button>
                                        </MenuColumn>
                                    )}
                                    {(!topic.status || topic.status === 'draft') && (
                                        <>
                                            <MenuColumn className="push">
                                                <Plain
                                                    className={`constructive${updating === 'draft' ? ' loading' : ''}`}
                                                    disabled={!enableSave}
                                                    autoFocus={1}
                                                    onClick={() => addOrUpdate({ status: 'draft' })}>
                                                    <FormattedMessage
                                                        id="action_save"
                                                        defaultMessage="Save" />
                                                </Plain>
                                            </MenuColumn>
                                            <MenuColumn>
                                                <Tooltip
                                                    content={formatMessage({
                                                        id: 'handbooks_topic_tooltip_handbook_draft',
                                                        defaultMessage: 'This topic will become available once the handbook is published.'
                                                    })}
                                                    disabled={handbook.status !== 'draft'}>
                                                    <Button
                                                        className={`constructive${updating === 'published' ? ' loading' : ''}`}
                                                        disabled={!enablePublish}
                                                        onClick={() => {
                                                            if(handbook.status === 'published') {
                                                                setPrompting({
                                                                    method: 'publish',
                                                                    id: topic.id
                                                                })
                                                            } else {
                                                                addOrUpdate({ status: 'published' })
                                                            }
                                                        }}>
                                                        <FormattedMessage
                                                            id={(handbook.status === 'published') ?
                                                                'action_publish' :
                                                                'action_mark_ready'
                                                            }
                                                            defaultMessage={(handbook.status === 'published') ?
                                                                'Publish' :
                                                                'Mark as ready'
                                                            } />
                                                    </Button>
                                                </Tooltip>
                                            </MenuColumn>
                                        </>
                                    )}
                                    {!!boundActions?.length && (
                                        <MenuColumn>
                                            <ContextMenu
                                                prebound actions={boundActions}
                                                triggerProps={{ disabled: updating || loading }}
                                                salt={salt} />
                                        </MenuColumn>
                                    )}
                                </MenuColumns>
                            </Menu>
                            <Content>
                                {!!hasNewerTemplate && (
                                    <Message
                                        type="warning"
                                        message={formatMessage({
                                            id: 'handbooks_topic_message_template_updates',
                                            defaultMessage: 'The template this topic is connected to has been updated since this topic was last edited.'
                                        })}>
                                        <Ghost onClick={() => {
                                            if(templateRef?.current) {
                                                setOpenTemplate(true)
                                                scrollTemplateIntoView()
                                            }
                                        }}>
                                            <FormattedMessage
                                                id="action_see_changes"
                                                defaultMessage="See changes" />
                                        </Ghost>
                                    </Message>
                                )}
                                <SymbolField
                                    salt={salt}
                                    label={false}
                                    name="symbol"
                                    field={{
                                        value: fields.symbol.current,
                                        include: !topic?.id ?
                                            'always' :
                                            'touched',
                                        unsettable: false
                                    }}
                                    symbolProps={{ size: 56 }}
                                    onChange={({ symbol }) => fields.symbol.update(symbol)} />
                                <StringField
                                    salt={salt}
                                    label={false}
                                    name="title"
                                    field={{
                                        value: fields.title.current,
                                        include: !topic?.id ?
                                            'always' :
                                            'touched',
                                        unsettable: false,
                                        growable: true
                                    }}
                                    controlProps={{
                                        className: 'huge',
                                        maxLength: 100,
                                        autoFocus: !fields.title.current,
                                        placeholder: formatMessage({
                                            id: 'title_placeholder',
                                            defaultMessage: 'Add a title…'
                                        })
                                    }}
                                    onChange={({ title }) => fields.title.update(title)} />
                                <EditorField
                                    salt={salt}
                                    label={false}
                                    name="content"
                                    field={{ value: fields.content.current }}
                                    controlProps={{ autoFocus: !!topic?.id && !!fields.title.current && !editFocus }}
                                    editor={{ preset: 'maximal' }}
                                    onChange={({ content: doc }) => fields.content.update(doc.content)} />
                                <UsefulLinks
                                    links={fields.links.current}
                                    mode="edit"
                                    onChange={fields.links.update}
                                    shouldAutoFocus={false}
                                    salt={salt} />
                                <ArrayField
                                    salt={salt}
                                    label={formatMessage({
                                        id: 'noun_tags',
                                        defaultMessage: 'Tags'
                                    })}
                                    name="tags"
                                    field={{
                                        value: fields.tags.current,
                                        optional: formatMessage({
                                            id: 'handbooks_optional_tags',
                                            defaultMessage: 'Tags are used to categorize topics. Use them to make it easier for users to find what they are looking for.'
                                        })
                                    }}
                                    onChange={({ tags }) => fields.tags.update(tags)} />
                                <Meta
                                    topic={topic}
                                    fields={fields}
                                    salt={salt} />
                                <ConnectedTemplate
                                    topic={topic}
                                    open={openTemplate}
                                    onClose={() => setOpenTemplate(false)}
                                    fields={fields}
                                    salt={salt}
                                    ref={templateRef} />
                            </Content>
                        </>
                    )
                }}
            </Form>
            <Modal
                show={!!prompting}
                dismiss={() => setPrompting(null)}
                salt={`${salt}:prompt`}>
                <Prompt
                    {...prompting}
                    addOrUpdate={addOrUpdate}
                    dismiss={() => setPrompting(null)}
                    onDone={() => setPrompting(null)} />
            </Modal>
        </>
    )
}

const fieldKeyToNameMap = ({
    category: 'categoryId'
})

export default EditHandbookTopic
