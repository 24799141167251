import React, { Component, createContext, useContext, createRef } from 'react'
import { get, patch, put, post, remove } from 'api'
import { pruneBy, first, last } from 'utilities/array'
import { pick } from 'utilities/object'
import { isEqual } from 'lodash'

const ProcessTemplateContext = createContext()
ProcessTemplateContext.displayName = 'ProcessTemplate'

export default class ProcessTemplateProvider extends Component {
    constructor(props) {
        super(props)

        this.setId(props.id)

        this.flash = createRef()

        this.state = {
            template: null,
            type: props.type,
            deleted: false,

            fetchTemplate: this.fetch,
            updateTemplate: this.update,
            removeTemplate: this.remove,

            addTask: this.addTask,
            updateTask: this.updateTask,
            removeTask: this.removeTask,

            flash: this.flash,
            clearFlash: this.clearFlash
        }
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetch()
    }

    componentDidUpdate({ type, id }, { template }) {
        const typeChanged = type !== this.props.type
        const idChanged = id !== this.props.id
        const tasksChanged = !isEqual(template?.tasks, this.state.template?.tasks)

        const needsReplacing = typeChanged || idChanged

        if(needsReplacing) {
            this.setState(
                typeChanged ? pick(this.props, 'type') : null,
                this.replace
            )
        }

        if(!needsReplacing && tasksChanged) {
            this.setState(({ template }) => ({
                template: {
                    ...template,
                    stats: statify(template.tasks)
                }
            }))
        }
    }

    setId = id => this.id = id

    fetch = async () => {
        if(!this.id) {
            return
        }

        const { type } = this.state

        const { response: template, ok } = await get({
            path: `/${type}-templates/${this.id}`
        })

        if(ok) {
            const { tasks } = template
            const stats = statify(tasks)

            this.setId(template.id)
            this.setState({
                template: {
                    ...template,
                    stats
                }
            })
        }
    }

    update = async body => {
        const { type } = this.state

        const { ok, response: template } = await put({
            path: `/${type}-templates/${this.id}`,
            body
        })

        if(ok && template) {
            this.setState({ template })
        }

        return { ok, response: template }
    }

    remove = async id => {
        const { type } = this.state

        const { ok } = await remove({
            path: `/${type}-templates/${id ?? this.id}`,
            returnsData: false
        })

        !!ok && this.setState({ deleted: true })

        return { ok }
    }

    addTask = async ({ body }) => {
        const { type } = this.state

        const { response: task, ok } = await post({
            path: `/${type}-templates/${this.id}/tasks`,
            body
        })

        if(ok && task) {
            this.setState(({ template }) => {
                this.flash.current = task

                return {
                    template: {
                        ...template,
                        tasks: [
                            task,
                            ...template.tasks
                        ]
                    }
                }
            })
        }

        return { ok, response: task }
    }

    updateTask = async ({ body, id }) => {
        const { template, type } = this.state
        const taskToUpdate = template.tasks.find(task => task.id === id)

        const { response: task, ok } = await patch({
            path: `/${type}-templates/${this.id}/tasks/${id}`,
            body: {
                ...pick(taskToUpdate, 'assignmentType'),
                ...body
            }
        })

        if(ok && task) {
            this.setState(({ template }) => {
                const { tasks } = template
                const index = tasks.findIndex(task => task.id === id)

                return {
                    template: {
                        ...template,
                        tasks: [
                            ...tasks.slice(0, index),
                            task,
                            ...tasks.slice(index + 1, tasks.length)
                        ]
                    }
                }
            })
        }

        return { ok, response: task }
    }

    removeTask = async id => {
        const { type } = this.state

        const { ok } = await remove({
            path: `/${type}-templates/${this.id}/tasks/${id}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ template }) => ({
                template: {
                    ...template,
                    tasks: template.tasks.filter(task => task.id !== id)
                }
            }))
        }

        return { ok }
    }

    replace = (id = this.props.id) => {
        this.setId(id)

        this.setState({
            template: null,
            deleted: false
        }, this.fetch)
    }

    clearFlash = () => void this.setState(({ template }) => {
        this.flash.current = null

        return {
            template: {
                ...template,
                tasks: [...template.tasks]
            }
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <ProcessTemplateContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </ProcessTemplateContext.Provider>
        )
    }
}

export const useProcessTemplate = () => useContext(ProcessTemplateContext)

const statify = tasks => {
    if(!tasks?.length) {
        return {
            duration: 1,
            groups: 0,
            assignees: 0,
            unassignedTasks: 0,
            totalTasks: 0
        }
    }

    const unassignedTasks = tasks?.filter(({ assignedGroup, assignedTo }) => !assignedGroup && !assignedTo)

    const assignedGroups = tasks
        ?.map(({ assignedGroup }) => assignedGroup)
        .filter(Boolean)

    const assignedUsers = tasks
        ?.map(({ assignedTo }) => assignedTo)
        .filter(Boolean)

    const sortedTasks = tasks
        ?.filter(({ dueAtOffsetDays }) => typeof dueAtOffsetDays === 'number')
        ?.sort((one, two) => one.dueAtOffsetDays - two.dueAtOffsetDays)

    return {
        duration: sortedTasks?.length ?
            last(sortedTasks).dueAtOffsetDays - first(sortedTasks).dueAtOffsetDays + 1 :
            1,
        groups: pruneBy(assignedGroups).length,
        assignees: pruneBy(assignedUsers).length,
        unassignedTasks: unassignedTasks.length,
        totalTasks: tasks.length
    }
}