import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSurvey } from 'contexts/survey'
import { useModal } from 'contexts/modal'
import { Hero, Actions, CloseButton } from './s'
import Paragraph from 'components/typography/paragraph'

const SurveyRespondHero = ({ started, currentQuestionIndex }) => {
    const { dismiss } = useModal()

    const { getResponseRun } = useSurvey()
    const source = getResponseRun()

    return (
        <Hero>
            <Actions>
                <CloseButton onClick={dismiss} />
                {(source.type === 'custom' && started) && (
                    <Paragraph className="tabular-nums compact">
                        <FormattedMessage
                            id="current_of_total"
                            defaultMessage="{current} / {total}"
                            values={{
                                current: currentQuestionIndex + 1,
                                total: source.questions?.length
                            }} />
                    </Paragraph>
                )}
            </Actions>
        </Hero>
    )
}

export default SurveyRespondHero