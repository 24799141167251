import React, { Component, createContext, useContext } from 'react'
import { useOrganization } from 'contexts/organization'
import { get, patch } from 'api'
import { intersectionObserver } from 'utilities/dom'
import debounce from 'lodash.debounce'
import PubSub from 'pubsub-js'
import isEqual from 'react-fast-compare'
import { size, omit } from 'utilities/object'
import { followUpEnabledTypeNames, absenceTypeTofollowUpType } from 'pages/absence/utilities'

const AbsenceTypesContext = createContext()
AbsenceTypesContext.displayName = 'AbsenceTypes'

class AbsenceTypesProvider extends Component {
    constructor(props) {
        super(props)

        const { eternal = true } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })
        this.intersectionObserver = intersectionObserver(this.onIntersect)

        this.pagingDefaults = pagingDefaults(props?.paging)

        this.state = {
            types: props?.types ?? [],
            fixed: !!props?.types,
            paging: this.pagingDefaults(),
            includePolicies: props?.includePolicies ?? false,
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            fetchTypes: this.fetchDebounced,
            updateType: this.update,
            setUpTypes: this.setUp,
            setTypesFilter: this.setFilter,
            toggleSorting: this.toggleSorting,

            error: null,
            resetError: () => this.setError(null),

            hasFetched: false,
            autoFetch: false,
            fetching: false,
            loading: false
        }

        this.refreshSubscription = PubSub.subscribe('absenceTypes.refresh', () => {
            this.setState({
                types: [],
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }, this.fetchDebounced)
        })
    }

    componentDidMount() {
        const {
            fetchAccess = true,
            fetchOnMount = true
        } = this.props

        if(fetchAccess && fetchOnMount) {
            this.fetchDebounced()
        }
    }

    componentDidUpdate(props) {
        const pagingChanged = !isEqual(props.paging, this.props?.paging)
        const typesChanged = props.types !== this.props.types

        const { fetchAccess: previousFetchAccess = true } = props
        const { fetchAccess = true } = this.props
        const fetchAccessGained = !previousFetchAccess && fetchAccess

        const state = {}

        if(pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props?.paging)
            state.paging = this.pagingDefaults()
        }

        if(typesChanged) {
            state.types = this.props.types
            state.fixed = !!this.props.types
        }

        this.setState(size(state) ? state : null, () => {
            if(fetchAccessGained) {
                this.fetchDebounced()
            }
        })
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver.destroy()
        PubSub.unsubscribe(this.refreshSubscription)
    }

    fetch = async (force = false) => {
        const {
            fixed,
            fetching,
            paging,
            includePolicies,
            eternal,
            autoFetch,
            hasFetched
        } = this.state

        if((fixed || fetching || (hasFetched && !eternal)) && !force) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetched ? paging.offset + paging.limit : 0,
            limit: paging.limit
        }

        const { ok, response } = await get({
            path: '/absence/types',
            params: {
                ...nextPaging,
                includePolicies
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
            this.setState(({ types: previousTypes }) => {
                const types = [
                    ...previousTypes,
                    ...response.items
                ]

                return {
                    types,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        hasNextPage: response.items.length && types.length < response.total
                    },
                    hasFetched: true,
                    autoFetch: this.props.autoFetch || (!!previousTypes.length && hasFetched),
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                hasFetched: true,
                autoFetch: false,
                fetching: false,
                loading: false
            })
        }
    }

    update = async ({ body, typeId }) => {
        const { ok, response } = await patch({
            path: `/absence/types/${typeId}`,
            body
        })

        if(ok && response) {
            this.setState(({ types }) => {
                const index = types.findIndex(({ id }) => id === typeId)

                return {
                    types: [
                        ...types.slice(0, index),
                        response,
                        ...types.slice(index + 1, types.length)
                    ]
                }
            })
        }

        return { ok, response }
    }

    setUp = async body => {
        const { refreshStatuses } = this.props

        body = Object.values(body)

        body = body.map(type => {
            let followUp = null

            if(followUpEnabledTypeNames.includes(type.name)) {
                const useSupervisor = type.followUpResponsible === 'supervisor'

                followUp = type.useFollowUp ? {
                    enabled: true,
                    type: absenceTypeTofollowUpType(type.name),
                    useSupervisor,
                    fallback: useSupervisor?
                        type?.followUpSupervisor :
                        type?.followUpPerson
                } : null
            }

            type = omit(type, 'useFollowUp', 'followUpResponsible', 'followUpSupervisor', 'followUpPerson')

            return {
                ...type,
                followUp
            }
        })

        const { ok, response } = await patch({
            path: `/absence/types/setup`,
            body
        })

        if(response) {
            if(ok) {
                await refreshStatuses({ notify: false })
            } else {
                this.setError(response)
            }
        }

        return { ok, response }
    }

    setError = data => {
        let error = null

        if(data) {
            const {
                types,
                errorCode,
                errorMessage
            } = data

            error = {
                errorType: errorCode.replace('field:', '').replaceAll('-', '_'),
                errorMessage,
                types
            }
        }

        this.setState({ error })
    }

    onIntersect = () => {
        const {
            eternal,
            loading,
            paging,
            autoFetch
        } = this.state

        if(!eternal || loading || !autoFetch || !paging?.hasNextPage) {
            return
        }

        this.fetchDebounced()
    }

    render() {
        const { children = null } = this.props

        return (
            <AbsenceTypesContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </AbsenceTypesContext.Provider>
        )
    }
}

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

export default props => {
    const { refreshStatuses } = useOrganization()

    return (
        <AbsenceTypesProvider
            {...props}
            refreshStatuses={refreshStatuses} />
    )
}

export const useAbsenceTypes = () => useContext(AbsenceTypesContext)