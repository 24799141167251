import React, { Component, createContext, useContext } from 'react'
import { get } from 'api'
import isEqual from 'react-fast-compare'
import { size } from 'utilities/object'

const HandbookTemplateContext = createContext()
HandbookTemplateContext.displayName = 'HandbookTemplate'

export default class HandbookTemplateProvider extends Component {
    constructor(props) {
        super(props)

        this.setId(props.id ?? props.template?.id)

        this.state = {
            template: props.template ?? null,
            fixed: !!props.template,

            hasFetched: !!props.template,

            fetchTemplate: this.fetch
        }
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        !!fetchOnMount && this.fetch()
    }

    componentDidUpdate({ id, template }) {
        const idChanged = id !== this.props.id
        const templateChanged = !isEqual(template, this.props.template)

        const state = {}

        if(templateChanged && !!this.props.template) {
            if(!!this.props.template) {
                state.template = this.props.template
                state.fixed = true
            } else {
                state.template = null
                state.fixed = false
            }
        }

        this.setState(size(state) ? state : null, () => {
            idChanged && this.replace(this.props.id)
        })
    }

    setId = id => this.id = id

    fetch = async () => {
        if(!this.id || this.state.fixed) {
            return
        }

        const { response: template, ok } = await get({
            path: `/handbook-templates/${this.id}`
        })

        if(ok) {
            this.setId(template.id)

            this.setState({
                template: {
                    ...template,
                    type: 'template'
                },
                hasFetched: true
            })
        } else {
            this.setState({
                hasFetched: true
            })
        }
    }

    replace = id => {
        this.setId(id)
        this.setState({ template: null }, this.fetch)
    }

    render() {
        const { children = null } = this.props

        return (
            <HandbookTemplateContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </HandbookTemplateContext.Provider>
        )
    }
}

export const useHandbookTemplate = () => useContext(HandbookTemplateContext)