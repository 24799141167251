import React, { useRef, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useProcessTemplate } from 'contexts/process-template'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { getConcernsLabelTranslation, getReferenceDateLabelTranslation, getResponsibleLabelTranslation } from 'pages/processes/utilities'
import { size, pick } from 'utilities/object'
import { compact } from 'utilities/array'
import Form from 'components/form/controller'
import { ModalHeader } from 'modals/generic'
import { Fields } from './s'
import StringField from 'components/form/field/string'
import TextField from 'components/form/field/text'
import Links from 'pages/tasks/modals/task/edit/links'
import OneOfField from 'components/form/field/one-of'
import Caption from 'components/typography/caption'
import ShareField, { unoptionize } from 'components/form/field/share'
import PersonField from 'components/form/field/person'
import UpgradeCTA from 'components/upgrade-cta'
import OffsetField from './offset-field'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const EditTask = ({ task, assign, when, previousAssignment, updatePreviousAssignment, dismiss, modal, salt }) => {
    const { formatMessage } = useIntl()

    const {
        type,

        addTask,
        updateTask
    } = useProcessTemplate()

    const { checkFeature } = useAccess()
    const groupTasksAvailable = checkFeature('group-tasks')
    const groupTasksUpgradable = useUpgradable()({ feature: 'group-tasks' })

    const fixedRef = useRef()

    const {
        id,
        assignmentType: initialAssignmentType,
        title,
        description,
        links = [],
        assignedGroup,
        assignedTo,
        dueAtOffsetDays = (typeof when === 'number') ? when : null
    } = task ?? {}

    const mode = task?.id ? 'update' : 'create'

    const getAssignmentType = () => {
        if(initialAssignmentType) {
            return initialAssignmentType
        }

        if(mode === 'create' && previousAssignment?.type) {
            return previousAssignment.type
        }

        return 'dynamic'
    }

    const getPrimaryAssignmentValue = () => {
        if(assignedGroup) {
            return assignedGroup
        }

        if(initialAssignmentType === 'fixed' && assignedTo) {
            return { ...assignedTo, type: 'user' }
        }

        if(mode === 'create') {
            if(previousAssignment?.type === 'fixed') {
                if(previousAssignment?.assignedGroup) {
                    return previousAssignment.assignedGroup
                }

                if(previousAssignment?.assignedTo) {
                    return { ...previousAssignment.assignedTo, type: 'user' }
                }
            }
        }

        return null
    }

    const getSecondaryAssignmentValue = () => {
        if(assignedGroup && assignedTo) {
            return assignedTo
        }

        if(mode === 'create') {
            if(previousAssignment?.assignedGroup && previousAssignment?.assignedTo) {
                return previousAssignment.assignedTo
            }
        }

        return null
    }

    const getActorValue = () => {
        if(initialAssignmentType === 'dynamic') {
            return assignedTo
        }

        if(previousAssignment?.type === 'dynamic' && previousAssignment?.assignedTo) {
            return previousAssignment.assignedTo
        }

        return 'concerns'
    }

    const [assignmentType, setAssignmentType] = useState(getAssignmentType())

    const [primaryAssignment, setPrimaryAssignment] = useState({
        value: getPrimaryAssignmentValue(),
        changed: false
    })

    const [secondaryAssignment, setSecondaryAssignment] = useState({
        value: getSecondaryAssignmentValue(),
        changed: false
    })

    const [taskLinks, setTaskLinks] = useState({
        value: links,
        changed: false
    })

    const [actor, setActor] = useState({
        value: getActorValue(),
        changed: false
    })

    const [constructing, setConstructing] = useState(false)

    const include = (mode === 'update') ?
        'touched' :
        'always'

    const primaryAssignmentIsGroup = ['team', 'location'].includes(primaryAssignment.value?.type)

    const addOrUpdate = async (body, { resetTouched }) => {
        setConstructing(true)

        delete body.primaryAssignment
        delete body.secondaryAssignment

        body.assignmentType = assignmentType
        const previousAssignment = { type: assignmentType }

        if(assignmentType === 'dynamic') {
            if(actor.value && (mode === 'create' || actor.changed)) {
                body.assignedTo = actor.value
                previousAssignment.assignedTo = actor.value
            }
        } else if(assignmentType === 'fixed') {
            if(
                mode === 'create' ||
                primaryAssignment.changed ||
                secondaryAssignment.changed
            ) {
                if(groupTasksAvailable) {
                    const field = primaryAssignmentIsGroup ?
                        'assignedGroup' :
                        'assignedTo'

                    if(primaryAssignment.changed || (mode === 'create')) {
                        body[field] = primaryAssignment.value?.id ?? null
                        previousAssignment[field] = primaryAssignment.value
                    }

                    if(secondaryAssignment.changed || (mode === 'create')) {
                        if(primaryAssignmentIsGroup) {
                            body.assignedTo = secondaryAssignment.value?.id ?? null
                            previousAssignment.assignedTo = secondaryAssignment.value
                        } else {
                            body.assignedGroup = null
                            previousAssignment.assignedGroup = null
                        }
                    }
                } else if(primaryAssignment.changed) {
                    body.assignedTo = primaryAssignment.value?.id ?? null
                    previousAssignment.assignedTo = primaryAssignment.value
                }
            }
        }

        body.links = taskLinks.value

        const addOrUpdateTask = id ?
            updateTask :
            addTask

        const { ok } = await addOrUpdateTask({ body, id })

        setConstructing(false)

        if(ok) {
            if(!id) {
                updatePreviousAssignment(previousAssignment)
            }

            resetTouched()
            dismiss?.()
        }
    }

    const heading = formatMessage({
        id: id ?
            'task_action_update' :
            'task_action_add',
        defaultMessage: id ?
            'Update task' :
            'Add task'
    })

    return (
        <Form
            layout="vertical"
            onSubmit={addOrUpdate}>
            {({ touched, errors, trigger }) => (
                <>
                    <ModalHeader
                        heading={heading}
                        dismiss={dismiss} />
                    <Fields>
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_title',
                                defaultMessage: 'Title'
                            })}
                            name="title"
                            field={{
                                value: title,
                                required: true,
                                include
                            }}
                            controlProps={{
                                autoFocus: !assign,
                                maxLength: 255
                            }} />
                        <TextField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_description',
                                defaultMessage: 'Description'
                            })}
                            name="description"
                            field={{
                                value: description,
                                include
                            }}
                            controlProps={{ maxLength: 2040 }} />
                        <Links
                            taskLinks={taskLinks}
                            setTaskLinks={setTaskLinks}
                            salt={salt} />
                        <OneOfField
                            salt={salt}
                            label={formatMessage({
                                id: 'task_assign_to_title',
                                defaultMessage: 'Assign to'
                            })}
                            name="assignmentType"
                            field={{
                                value: ((assignmentType === 'fixed') ?
                                    'fixed' :
                                    actor.value) ?? null,
                                include: 'always',
                                required: true,
                                options: compact([
                                    {
                                        value: 'concerns',
                                        label: formatMessage(getConcernsLabelTranslation(type)),
                                        content: (
                                            <Caption className="compact">
                                                <FormattedMessage {...getConcernsCaptionTranslation(type)} />
                                            </Caption>
                                        )
                                    },
                                    {
                                        value: 'supervisor',
                                        label: formatMessage({
                                            id: 'task_assign_to_supervisor',
                                            defaultMessage: 'Supervisor'
                                        }),
                                        content: (
                                            <Caption className="compact">
                                                <FormattedMessage {...getSupervisorCaptionTranslation(type)} />
                                            </Caption>
                                        )
                                    },
                                    (type === 'onboarding') && {
                                        value: 'buddy',
                                        label: formatMessage({
                                            id: 'task_assign_to_buddy_onboarding',
                                            defaultMessage: 'Onboarding buddy'
                                        }),
                                        content: (
                                            <Caption className="compact">
                                                <FormattedMessage
                                                    id="task_assign_to_buddy_onboarding_caption"
                                                    defaultMessage="The task will be assigned to the new hire’s onboarding buddy when the template is used" />
                                            </Caption>
                                        )
                                    },
                                    {
                                        value: 'responsible',
                                        label: formatMessage(getResponsibleLabelTranslation(type)),
                                        content: (
                                            <Caption className="compact">
                                                <FormattedMessage {...getResponsibleCaptionTranslation(type)} />
                                            </Caption>
                                        )
                                    },
                                    {
                                        value: 'fixed',
                                        label: formatMessage({
                                            id: (!groupTasksAvailable && !groupTasksUpgradable) ?
                                                'organization_unit_person' :
                                                'organization_unit_person_or_team_or_location',
                                            defaultMessage: (!groupTasksAvailable && !groupTasksUpgradable) ?
                                                'A person…' :
                                                'A person, team or location…'
                                        }),
                                        controlRef: fixedRef,
                                        upgradable: groupTasksUpgradable,
                                        content: (
                                            <>
                                                <ShareField
                                                    salt={salt}
                                                    label={false}
                                                    name="primaryAssignment"
                                                    field={{
                                                        value: compact([primaryAssignment.value]),
                                                        include: 'always'
                                                    }}
                                                    picker={{
                                                        types: [
                                                            'user',
                                                            ...(groupTasksAvailable ? ['team', 'location'] : [])
                                                        ],
                                                        single: true
                                                    }}
                                                    onChange={({ primaryAssignment }) => {
                                                        primaryAssignment = !!primaryAssignment?.[0] ?
                                                            unoptionize(primaryAssignment[0]) :
                                                            null

                                                        if(!primaryAssignment && secondaryAssignment.value) {
                                                            primaryAssignment = { ...secondaryAssignment.value, type: 'user' }
                                                        }

                                                        setPrimaryAssignment({
                                                            value: primaryAssignment,
                                                            changed: true
                                                        })

                                                        setSecondaryAssignment({
                                                            value: null,
                                                            changed: true
                                                        })
                                                    }}
                                                    ref={fixedRef}
                                                    key={`${salt}:${modal?.showing}:primaryAssignment:${primaryAssignment.value?.id ?? 'empty'}`} />
                                                {(groupTasksAvailable && primaryAssignmentIsGroup) && (
                                                    <PersonField
                                                        salt={salt}
                                                        label={formatMessage({
                                                            id: 'group_person_action_assign_to',
                                                            defaultMessage: 'Assign to a person in {name}'
                                                        }, primaryAssignment.value)}
                                                        name="secondaryAssignment"
                                                        field={{ value: secondaryAssignment.value }}
                                                        picker={{
                                                            mode: 'group',
                                                            ...pick(primaryAssignment.value, 'type', 'id')
                                                        }}
                                                        onChange={({ secondaryAssignment }) => setSecondaryAssignment({
                                                            value: secondaryAssignment,
                                                            changed: true
                                                        })}
                                                        key={`${salt}:secondaryAssignment:${secondaryAssignment.value?.id ?? 'empty'}`} />
                                                )}
                                                {groupTasksUpgradable && (
                                                    <UpgradeCTA
                                                        feature="group-tasks"
                                                        useUpgradeIcon
                                                        useFeatureOrModuleIcon
                                                        useFeatureTitle
                                                        useFeatureDescription
                                                        className="compact"
                                                        salt="group-tasks" />
                                                )}
                                            </>
                                        )
                                    }
                                ])
                            }}
                            controlProps={{ autoFocus: assign }}
                            onChange={({ assignmentType }) => {
                                if(assignmentType === 'fixed') {
                                    setAssignmentType('fixed')
                                }

                                if(assignmentType !== 'fixed') {
                                    setAssignmentType('dynamic')

                                    setActor({
                                        value: assignmentType,
                                        changed: true
                                    })
                                }
                            }} />
                        <OffsetField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_due_date',
                                defaultMessage: 'Due date'
                            })}
                            name="dueAtOffsetDays"
                            field={{
                                include: 'always',
                                value: dueAtOffsetDays
                            }}
                            valueDisplayFormatters={{
                                negative: values => formatMessage(
                                    getOffsetNegativeValueTranslation(type),
                                    { ...values, misty: t => t }
                                ),
                                neutral: () => formatMessage(getReferenceDateLabelTranslation(type)),
                                positive: values => formatMessage(
                                    getOffsetPositiveValueTranslation(type),
                                    { ...values, misty: t => t }
                                )
                            }}
                            picker={{
                                outer: false,
                                heading: formatMessage({
                                    id: 'noun_due_date',
                                    defaultMessage: 'Due date'
                                }),
                                optionLabelFormatters: {
                                    negative: () => formatMessage(
                                        getOffsetNegativeLabelTranslation(type),
                                        { accent: chunks => <strong>{chunks}</strong> }
                                    ),
                                    neutral: () => formatMessage(getReferenceDateLabelTranslation(type)),
                                    positive: () => formatMessage(
                                        getOffsetPositiveLabelTranslation(type),
                                        { accent: chunks => <strong>{chunks}</strong> }
                                    )
                                }
                            }} />
                    </Fields>
                    <Actions>
                        <Plain
                            onClick={dismiss}
                            className="neutral">
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <ButtonSubmit
                            className={`constructive${constructing ? ' loading' : ''}`}
                            disabled={(
                                !touched.length &&
                                !primaryAssignment.changed &&
                                !secondaryAssignment.changed &&
                                !taskLinks.changed
                            ) || (
                                !!size(errors) ||
                                constructing
                            )}
                            ref={trigger}>
                            <FormattedMessage
                                id="action_save"
                                defaultMessage="Save" />
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

const getConcernsCaptionTranslation = type => ({
    onboarding: {
        id: 'task_assign_to_new_caption',
        defaultMessage: 'The task will be assigned to the new hire when the template is used'
    },
    offboarding: {
        id: 'task_assign_to_departing_employee_caption',
        defaultMessage: 'The task will be assigned to the departing employee when the template is used'
    },
    process: {
        id: 'task_assign_to_regarding_unit_caption',
        defaultMessage: 'The task will be assigned to the regarding employee when the template is used'
    }
})[type]

const getSupervisorCaptionTranslation = type => ({
    onboarding: {
        id: 'task_assign_to_supervisor_onboarding_caption',
        defaultMessage: 'The task will be assigned to the new hire’s supervisor when the template is used'
    },
    offboarding: {
        id: 'task_assign_to_supervisor_offboarding_caption',
        defaultMessage: 'The task will be assigned to the departing employee’s supervisor when the template is used'
    },
    process: {
        id: 'task_assign_to_supervisor_process_caption',
        defaultMessage: 'The task will be assigned to the regarding employee’s supervisor when the template is used'
    }
})[type]

const getResponsibleCaptionTranslation = type => ({
    onboarding: {
        id: 'task_assign_to_responsible_onboarding_caption',
        defaultMessage: 'The task will be assigned to whoever starts an onboarding using the template'
    },
    offboarding: {
        id: 'task_assign_to_responsible_offboarding_caption',
        defaultMessage: 'The task will be assigned to whoever starts an offboarding using the template'
    },
    process: {
        id: 'task_assign_to_responsible_process_caption',
        defaultMessage: 'The task will be assigned to whoever starts a process using the template'
    }
})[type]

const getOffsetNegativeValueTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_template_heading_first_workday_before_count',
        defaultMessage: '{count, plural, =0 {} =1 {1 day before <misty>first work day</misty>} other {{count} days before <misty>first work day</misty>}}'
    },
    offboarding: {
        id: 'employee_offboarding_template_heading_last_workday_before_count',
        defaultMessage: '{count, plural, =0 {} =1 {1 day before <misty>last work day</misty>} other {{count} days before <misty>last work day</misty>}}'
    },
    process: {
        id: 'processes_template_heading_reference_date_before_count',
        defaultMessage: '{count, plural, =0 {} =1 {1 day before <misty>reference date</misty>} other {{count} days before <misty>reference date</misty>}}'
    }
})[type]

const getOffsetPositiveValueTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_template_heading_first_workday_after_count',
        defaultMessage: '{count, plural, =0 {} =1 {1 day after <misty>first work day</misty>} other {{count} days after <misty>first work day</misty>}}'
    },
    offboarding: {
        id: 'employee_offboarding_template_heading_last_workday_after_count',
        defaultMessage: '{count, plural, =0 {} =1 {1 day after <misty>last work day</misty>} other {{count} days after <misty>last work day</misty>}}'
    },
    process: {
        id: 'processes_template_heading_reference_date_after_count',
        defaultMessage: '{count, plural, =0 {} =1 {1 day after <misty>reference date</misty>} other {{count} days after <misty>reference date</misty>}}'
    }
})[type]

export const getOffsetNegativeLabelTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_label_days_before_first_workday',
        defaultMessage: 'days <accent>before</accent> first work day'
    },
    offboarding: {
        id: 'employee_offboarding_label_days_before_last_workday',
        defaultMessage: 'days <accent>before</accent> last work day'
    },
    process: {
        id: 'processes_label_days_before_reference_date',
        defaultMessage: 'days <accent>before</accent> reference date'
    }
})[type]

export const getOffsetPositiveLabelTranslation = type => ({
    onboarding: {
        id: 'employee_onboarding_label_days_after_first_workday',
        defaultMessage: 'days <accent>after</accent> first work day'
    },
    offboarding: {
        id: 'employee_offboarding_label_days_after_last_workday',
        defaultMessage: 'days <accent>after</accent> last work day'
    },
    process: {
        id: 'processes_label_days_after_reference_date',
        defaultMessage: 'days <accent>after</accent> reference date'
    }
})[type]

export default EditTask