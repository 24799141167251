import React, { Component, createContext, useContext } from 'react'
import { useAbsenceEntries } from 'contexts/absence-entries'
import { useAbsenceEntriesByTime } from 'contexts/absence-entries-by-time'
import { enrichEntry } from 'pages/absence/utilities'
import { get, post } from 'api'
import PubSub from 'pubsub-js'

const AbsenceEntryContext = createContext()
AbsenceEntryContext.displayName = 'AbsenceEntry'

class AbsenceEntryProvider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            entry: null,
            deleted: false,

            fetchEntry: this.fetch,
            updateEntryReview: this.updateReview
        }
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetch()
    }

    fetch = async (id = this.props.id) => {
        if(!id) {
            return
        }

        const { response, ok } = await get({ path: `/absence/entries/${id}` })
        const entry = enrichEntry(response)

        !!ok && this.setState({ entry })
    }

    updateReview = async body => {
        const { id } = this.props

        const { ok, response } = await post({
            path: `/absence/entries/${id}/status`,
            body
        })

        const enrichedEntry = enrichEntry({
            ...response,
            ...body
        })

        if(ok && response) {
            this.setState(({ entry }) => ({
                entry: {
                    ...entry,
                    ...enrichedEntry
                }
            }))

            PubSub.publish('absenceEntries.refresh')

            !!this.props?.entries && this.props.entries.updateEntryLocally(body, id)
            !!this.props?.entriesByTime && this.props.entriesByTime.updateEntryLocally(body, id)
        }

        return { ok, response }
    }

    render() {
        const { children = null } = this.props

        return (
            <AbsenceEntryContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </AbsenceEntryContext.Provider>
        )
    }
}

export default props => {
    const entries = useAbsenceEntries()
    const entriesByTime = useAbsenceEntriesByTime()

    return (
        <AbsenceEntryProvider
            {...props}
            entries={entries}
            entriesByTime={entriesByTime} />
    )
}

export const useAbsenceEntry = () => useContext(AbsenceEntryContext)
