import styled from 'styled-components'
import { fromTablet, belowTablet } from 'utilities/styled'
import FormBase from 'components/form/controller'
import { Grid } from 'components/grid'
import { H3 } from 'components/typography/heading'
import ActionsBase from 'components/form/actions'

export const Form = styled(FormBase)`
    display: grid;
    grid-template-rows: subgrid;
    grid-row: header / content-end;
    grid-column: 1 / -1;

    transition: .2s ease;
    transition-delay: .2s;
    transition-property: opacity, transform;

    &:not(.running) {
        opacity: 0;

        ${fromTablet`
            transform: translateY(32px);
        `}

        ${belowTablet`
            transform: translateX(32px);
        `}
    }
`

export const Header = styled.div`
    position: sticky;
    top: 0;
    grid-row: header;

    margin-inline: auto;
    max-width: var(--huma-global-content-tight-width);
    width: 100%;
    padding-inline: 32px;
`

export const SymbolAndTitle = styled(Grid)`
    --hanging-symbol: 64px;

    grid-template-columns: 40px 1fr;
    column-gap: 24px;
    width: calc(100% + var(--hanging-symbol));
    position: relative;
    left: calc(var(--hanging-symbol) * -1);

    ${belowTablet`
        display: none;
    `}
`

export const QuestionsLayout = styled.div`
    display: grid;

    height: 100%;

    &:not(.submitted) {
        grid-template-rows: 1fr max-content;
    }

    &.submitted {
        align-content: center;
        justify-items: center;
    }
`

export const Questions = styled.div`
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    scroll-timeline: --survey-scroll block;

    display: flex;
    flex-direction: column;

    height: 100%;

    ${belowTablet`
        overflow-y: hidden;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-timeline: --survey-scroll inline;

        flex-direction: row;
    `}
`

export const Question = styled.div`
    scroll-snap-align: start;

    flex: 0 0 100%;

    margin-inline: auto;
    width: 100%;
    padding: 32px;

    ${fromTablet`
        max-width: var(--huma-global-content-tight-width);
    `}

    ${belowTablet`
        overflow-y: auto;
    `}
`

export const Field = styled.div`
    margin-block-start: 40px;
`


export const QuestionTitleWrapper = styled.div`
    display: flex;

    ${fromTablet`
        align-items: end;
        min-height: 160px;
    `}
`

export const QuestionTitle = styled(H3)`
    line-height: 1.4;
    margin-block: 2px 0;

    @supports (text-wrap: stable) {
        text-wrap: stable;
    }

    @supports (text-wrap: pretty) {
        text-wrap: pretty;
    }
`

export const Actions = styled(ActionsBase)`
    position: relative;

    border-top: 1px solid var(--huma-color-border-default);
    padding: 16px 32px;
`