import React, { memo } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useProcess } from 'contexts/process'
import { useOrganization } from 'contexts/organization'
import { getEmojiByType } from 'pages/processes/utilities'
import { Title, Emoji, Columns, Misty, PlainSmall } from './s'
import StandaloneTimePicker from 'components/form/field/time/standalone'
import { safeFormat } from 'utilities/date-time'

const TaskGroupTitle = ({ dueAt, placeholder = false, when, tasks, salt }) => {
    const { formatMessage } = useIntl()

    const {
        type,
        updateTask
    } = useProcess()

    const { organization } = useOrganization()
    const { dateLocale: locale } = useI18n()

    const bulkUpdateTaskDueAts = dueAt => {
        tasks.map(({ id }) => updateTask({ body: { dueAt }, id }))
    }

    if((!placeholder && when !== 'undecided' && !dueAt) || !organization) {
        return null
    }

    const getTitleTranslation = titleTranslationGetter(type, {
        misty: chunks => <Misty className="compact">{chunks}</Misty>,
        organization: organization.name
    })

    const dueAtFormatted = safeFormat(dueAt, 'PPPP', { locale })

    if(placeholder) {
        return (
            <>
                {(when === 'on') && (
                    <Title className="on">
                        <Emoji>
                            {getEmojiByType(type)}
                        </Emoji>
                        {dueAtFormatted}
                        <Misty className="compact">{' · '}<FormattedMessage {...getTitleTranslation('on')} /></Misty>
                    </Title>
                )}
                {(when !== 'on') && (
                    <Title>
                        <FormattedMessage {...getTitleTranslation(when)} />
                    </Title>
                )}
            </>
        )
    }

    return (
        <Title {...(when === 'on' ? { className: 'on' } : null)}>
            {(when === 'on') && (
                <Emoji>
                    {getEmojiByType(type)}
                </Emoji>
            )}
            <Columns>
                <span>
                    {dueAtFormatted}
                    {(when === 'undecided') && (
                        <FormattedMessage
                            id="employee_onboarding_no_due_date"
                            defaultMessage="No due date" />
                    )}
                    {(when === 'on') && <Misty className="compact">{' · '}<FormattedMessage {...getTitleTranslation('on')} /></Misty>}
                </span>
                {(when !== 'on') && (
                    <span>
                        <StandaloneTimePicker
                            time={dueAt ? new Date(dueAt) : new Date()}
                            trigger={(
                                <PlainSmall className="constructive">
                                    <FormattedMessage
                                        id="action_edit"
                                        defaultMessage="Edit" />
                                </PlainSmall>
                            )}
                            onChange={bulkUpdateTaskDueAts}
                            message={{
                                type: 'info',
                                message: formatMessage({
                                    id: 'tasks_info_edit_due_date_count',
                                    defaultMessage: '{count, plural, =0 {} =1 {Editing due date for <strong>one task<\/strong>} other {Editing due date for <strong>{count} tasks<\/strong>}}'
                                }, { count: tasks?.length ?? 0 })
                            }}
                            salt={`${salt}:change-time`} />
                    </span>
                )}
            </Columns>
        </Title>
    )
}

export const titleTranslationGetter = (type, values) => when => {
    let translation

    if(type === 'onboarding') {
        if(when === 'before') {
            translation = {
                id: 'employee_onboarding_template_heading_first_workday_before',
                defaultMessage: 'Before <misty>first work day</misty>',
            }
        }

        if(when === 'on') {
            translation = {
                id: 'employee_onboarding_template_heading_first_workday',
                defaultMessage: 'First day at {organization}'
            }
        }

        if(when === 'after') {
            translation = {
                id: 'employee_onboarding_template_heading_first_workday_after',
                defaultMessage: 'After <misty>first work day</misty>'
            }
        }
    }

    if(type === 'offboarding') {
        if(when === 'before') {
            translation = {
                id: 'employee_offboarding_template_heading_last_workday_before',
                defaultMessage: 'Before <misty>last work day</misty>'
            }
        }

        if(when === 'on') {
            translation = {
                id: 'employee_offboarding_template_heading_last_workday',
                defaultMessage: 'Last day at {organization}'
            }
        }

        if(when === 'after') {
            translation = {
                id: 'employee_offboarding_template_heading_last_workday_after',
                defaultMessage: 'After <misty>last work day</misty>'
            }
        }
    }

    if(type === 'process') {
        if(when === 'before') {
            translation = {
                id: 'processes_template_heading_reference_date_before',
                defaultMessage: 'Before <misty>the reference date</misty>'
            }
        }

        if(when === 'on') {
            translation = {
                id: 'process_reference_date',
                defaultMessage: 'Reference date'
            }
        }

        if(when === 'after') {
            translation = {
                id: 'processes_template_heading_reference_date_after',
                defaultMessage: 'After <misty>the reference date</misty>'
            }
        }
    }

    return translation ? {
        ...translation,
        values
    } : null
}

export default memo(TaskGroupTitle)