import React, { useState, useEffect } from 'react'
import { Layout } from './s'
import Hero from './hero'
import Intro from './intro'
import Content from 'pages/surveys/components/questions/respond'

const CustomSurveyRespondContent = ({ answerable, onDone, salt }) => {
    const [started, setStarted] = useState(false)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [reachedQuestionIndex, setReachedQuestionIndex] = useState(0)
    const [visitedQuestionIds, setVisitedQuestionIds] = useState([])

    useEffect(() => () => {
        setStarted(false)
        setCurrentQuestionIndex(0)
        setReachedQuestionIndex(0)
        setVisitedQuestionIds([])
    }, [])

    return (
        <Layout>
            <Hero
                started={started}
                currentQuestionIndex={currentQuestionIndex} />
            <Intro
                started={started}
                start={() => setStarted(true)} />
            <Content
                started={started}
                currentQuestionIndex={currentQuestionIndex}
                setCurrentQuestionIndex={setCurrentQuestionIndex}
                reachedQuestionIndex={reachedQuestionIndex}
                setReachedQuestionIndex={setReachedQuestionIndex}
                visitedQuestionIds={visitedQuestionIds}
                setVisitedQuestionIds={setVisitedQuestionIds}
                answerable={answerable}
                onDone={onDone}
                salt={salt} />
        </Layout>
    )
}

export default CustomSurveyRespondContent