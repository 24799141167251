import React, { Component, createContext, useContext } from 'react'
import isEqual from 'react-fast-compare'
import { get } from 'api'
import { local } from 'utilities/storage'
import { reduce, withoutEmptyArrays, pick } from 'utilities/object'
import { getFullName } from 'utilities/person'
import { normalize } from 'utilities/string'
import { xor } from 'utilities/operator'

const RolesByUsersContext = createContext()
RolesByUsersContext.displayName = 'RolesByUsers'

class RolesByUsersProvider extends Component {
    constructor(props) {
        super(props)

        this.sortCacheKey = props?.sortCacheKey

        let sorting = sortingDefaults()
        if(this.sortCacheKey) {
            const cachedSorting = local.get(this.sortCacheKey)
            if(cachedSorting) {
                sorting = cachedSorting
            }
        }

        this.state = {
            users: props.users ?? [],
            filtered: props.users ?? [],
            total: 0,

            updateRolesCount: this.updateRolesCount,

            filter: props.filter ?? {},
            sorting,

            setUsersFilter: this.setFilter,
            // toggleSorting: this.toggleSorting,

            fetching: false,
            hasFetched: false
        }
    }

    componentDidMount() {
        const {
            fetchAccess = true,
            fetchOnMount = true
        } = this.props

        if(fetchAccess && fetchOnMount) {
            this.fetch()
        }
    }

    componentDidUpdate(prevProps, { sorting }) {
        const sortingChanged = !isEqual(sorting, this.state.sorting)

        if(sortingChanged) {
            this.fetch()
        }
    }

    fetch = async () => {
        // const { sorting } = this.state

        this.setState({ fetching: true })

        const { ok, response } = await get({
            path: '/roles/users',
            // params: {
            //     orderBy: sorting.by,
            //     orderDirection: sorting.direction
            // }
        })

        let state = { fetching: false }

        if(ok && response) {
            state = {
                ...state,
                users: response.items,
                filtered: this.filter(response.items),
                total: response.total,
                hasFetched: true
            }
        }

        this.setState(state)
    }

    updateRolesCount = (userId, count) => {
        this.setState(({ users }) => ({
            users: users.map(user => {
                if(user.user.id === userId) {
                    user.count = count
                }

                return user
            }),
            filtered: this.filter(users.map(user => {
                if(user.user.id === userId) {
                    user.count = count
                }

                return user
            })),
            total: users.reduce((total, user) => total + user.count, 0)
        }))
    }

    setFilter = (filter = {}) => {
        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }))

        this.setState(({ users, filtered: previousFiltered, filter: previousFilter }) => {
            const filterChanged = !isEqual(
                withoutEmptyArrays(filter),
                withoutEmptyArrays(previousFilter)
            )

            const filtered = this.filter(users, filter)

            let state = {}

            if(!isEqual(filtered, previousFiltered)) {
                state.filtered = filtered
            }

            if(filterChanged) {
                state.filter = filter
            }

            return state
        })
    }

    filter = (items = this.state.users, filter = this.state.filter) => {
        const search = normalize(filter?.search ?? '').toLowerCase()

        return items
            .map(item => ({
                ...item,
                name: normalize(getFullName(item.user)).toLowerCase()
            }))
            .filter(({ name }) => {
                if(!search) {
                    return true
                }

                return name.includes(search)
            })
            .sort(({ name: one }, { name: two }) => {
                const oneExact = one === search
                const twoExact = two === search

                if(xor(oneExact, twoExact)) {
                    return 5 * (oneExact ? -1 : 1)
                }

                const oneParts = one.split(' ')
                const twoParts = two.split(' ')

                const onePartiallyExact = oneParts.some(part => part === search)
                const twoPartiallyExact = twoParts.some(part => part === search)

                if(xor(onePartiallyExact, twoPartiallyExact)) {
                    return 4 * (oneExact ? -1 : 1)
                }

                const oneStartsWith = one.startsWith(search)
                const twoStartsWith = two.startsWith(search)

                if(xor(oneStartsWith, twoStartsWith)) {
                    return 3 * (oneStartsWith ? -1 : 1)
                }

                const onePartiallyStartsWith = oneParts.some(part => part.startsWith(search))
                const twoPartiallyStartsWith = twoParts.some(part => part.startsWith(search))

                if(xor(onePartiallyStartsWith, twoPartiallyStartsWith)) {
                    return 2 * (oneStartsWith ? -1 : 1)
                }

                const oneIncludes = one.includes(search)
                const twoIncludes = two.includes(search)

                if(xor(oneIncludes, twoIncludes)) {
                    return oneIncludes ? -1 : 1
                }

                return one.localeCompare(two)
            })
            .map(({ name, ...item }) => item) // eslint-disable-line no-unused-vars
    }

    // toggleSorting = field => {
    //     const sortingOptions = getSortingOptions()

    //     if(field in sortingOptions) {
    //         this.setState(({ sorting }) => {
    //             const toggled = {
    //                 by: field,
    //                 direction: sorting.by === field ?
    //                     sorting.direction === 'asc' ? 'desc' : 'asc' :
    //                     sorting.direction
    //             }

    //             !!this.sortCacheKey && local.set(this.sortCacheKey, this.state.sorting)

    //             return {
    //                 users: [],
    //                 sorting: toggled,
    //                 hasFetched: false
    //             }
    //         })
    //     }
    // }

    render() {
        const { children = null } = this.props

        return (
            <RolesByUsersContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </RolesByUsersContext.Provider>
        )
    }
}

const getSortingOptions = () => ({
    name: 'asc',
    roles: 'asc'
})

const sortingDefaults = (sortByDefault = 'name') => {
    const sortingOptions = pick(getSortingOptions(), sortByDefault)

    return {
        by: Object.keys(sortingOptions)[0],
        direction: Object.values(sortingOptions)[0]
    }
}

export const useRolesByUsers = () => useContext(RolesByUsersContext)

export default RolesByUsersProvider