import React from 'react'
import { modalify, Wrapper, Veil, Modal } from 'utilities/modal'
import { ScrollableContainer } from './s'
import Content from './content'

const OffsetPicker = ({ className, salt, ...props }) => {
    const {
        show = false,
        outer = true,
        dismiss,
        ...picker
    } = props

    salt = `modal:offset-picker:${salt}`
    const animate = show ? 'in' : 'out'

    return modalify(
        <Wrapper>
            {!!show && (
                <>
                    {!!outer && <Veil animate={animate} />}
                    <Modal
                        dismiss={dismiss}
                        key={salt}>
                        <ScrollableContainer
                            {...(className ? { className } : null)}
                            animate={animate}>
                            <Content
                                {...picker}
                                dismiss={dismiss}
                                salt={salt} />
                        </ScrollableContainer>
                    </Modal>
                </>
            )}
        </Wrapper>
    )
}

export default OffsetPicker
