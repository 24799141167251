import React from 'react'
import { useTheme, ThemeProvider } from 'styled-components'
import { Groups } from './s'
import Group from './group'

const PreviewProcessTemplateTaskGroups = ({ template, salt }) => {
    const theme = useTheme()

    const { tasks } = template

    const {
        groups,
        undecided,
        before,
        on,
        after
    } = groupTasks(tasks)

    const groupProps = { type: template.type }

    return (
        <ThemeProvider theme={{
            ...theme,
            mode: 'onboarding:template'
        }}>
            <Groups>
                {!!undecided && groups
                    .filter(({ when }) => when === 'undecided')
                    .map(group => {
                        const undecidedSalt = `${salt}:task-group:undecided`

                        return (
                            <Group
                                {...group}
                                {...groupProps}
                                salt={undecidedSalt}
                                key={undecidedSalt} />
                        )
                    })
                }
                {!before && (
                    <Group
                        placeholder={true}
                        when="before"
                        {...groupProps}
                        salt={`${salt}:task-group:placeholder:before`} />
                )}
                {!!before && groups
                    .filter(({ when }) => when === 'before')
                    .map(group => {
                        const beforeSalt = `${salt}:task-group:before:${group.dueAtOffsetDays}`

                        return (
                            <Group
                                {...group}
                                {...groupProps}
                                salt={beforeSalt}
                                key={beforeSalt} />
                        )
                    })
                }
                {!on && (
                    <Group
                        placeholder={true}
                        when="on"
                        {...groupProps}
                        salt={`${salt}:task-group:placeholder:on`} />
                )}
                {!!on && groups
                    .filter(({ when }) => when === 'on')
                    .map(group => {
                        const onSalt = `${salt}:task-group:on`

                        return (
                            <Group
                                {...group}
                                {...groupProps}
                                salt={onSalt}
                                key={onSalt} />
                        )
                    })
                }
                {!after && (
                    <Group
                        placeholder={true}
                        when="after"
                        {...groupProps}
                        salt={`${salt}:task-group:placeholder:after`} />
                )}
                {!!after && groups
                    .filter(({ when }) => when === 'after')
                    .map((group, index) => {
                        const afterSalt = `${salt}:task-group:after:${group.dueAtOffsetDays}:${index}`

                        return (
                            <Group
                                {...group}
                                {...groupProps}
                                salt={afterSalt}
                                key={afterSalt} />
                        )
                    })
                }
            </Groups>
        </ThemeProvider>
    )
}

export default PreviewProcessTemplateTaskGroups

const groupTasks = tasks => {
    let groups = tasks
        .filter(({ dueAtOffsetDays }) => typeof dueAtOffsetDays === 'number')
        .reduce((accumulator, task) => {
            const existingGroup = accumulator.find(group => task.dueAtOffsetDays === group.dueAtOffsetDays)
            if(existingGroup) {
                existingGroup.tasks.push(task)
                return accumulator
            }

            return [
                ...accumulator,
                {
                    dueAtOffsetDays: task.dueAtOffsetDays,
                    tasks: [task],
                    when: task.dueAtOffsetDays === 0 ?
                        'on' :
                        task.dueAtOffsetDays < 0 ?
                            'before' :
                            'after'
                }
            ]
        }, [])
        .sort(({ dueAtOffsetDays: one }, { dueAtOffsetDays: two }) => one - two)

    const tasksWithNoDueAtOffsetDays = tasks.filter(({ dueAtOffsetDays }) => !dueAtOffsetDays && dueAtOffsetDays !== 0)
    if(tasksWithNoDueAtOffsetDays.length) {
        groups = [
            {
                dueAtOffsetDays: null,
                tasks: tasksWithNoDueAtOffsetDays,
                when: 'undecided'
            },
            ...groups
        ]
    }

    return {
        groups,
        undecided: !!tasksWithNoDueAtOffsetDays.length,
        before: !!groups.find(({ when }) => when === 'before'),
        on: !!groups.find(({ when }) => when === 'on'),
        after: !!groups.find(({ when }) => when === 'after')
    }
}