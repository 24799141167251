import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useProcess } from 'contexts/process'
import { Placeholder, Content, Tasks } from './s'
import DrawablePanel from 'components/drawable-panel'
import Title from './title'
import Task from 'lists/tasks/task'
import { Plain } from 'components/button'
import { Plus } from 'styled-icons/feather'
import { pick } from 'utilities/object'

const TaskGroup = ({ placeholder = false, when, dueAt, addTask, drawable, tasks, actions, salt }) => {
    const {
        process,
        type,
        toggleTaskCompleted,

        flash,
        clearFlash
    } = useProcess()

    const placeholderTranslation = placeholder ?
        getPlaceholderTranslation(type, when) :
        null

    return (
        <DrawablePanel
            {...drawable}
            heading={<Title {...{ dueAt, placeholder, when, tasks, salt }} />}
            salt={salt}>
            <Content>
                {!!placeholderTranslation && (
                    <Placeholder>
                        <FormattedMessage {...placeholderTranslation} />
                    </Placeholder>
                )}
                {!placeholderTranslation && (
                    <Tasks>
                        {tasks?.map(task => (
                            <Task
                                task={{
                                    ...task,
                                    parent: {
                                        ...pick(process, 'user'),
                                        type
                                    }
                                }}
                                actions={actions}
                                toggleTaskCompleted={toggleTaskCompleted}
                                mode={`${type}:${!!process?.id ? 'started' : 'draft'}`}
                                flash={flash?.current === task ? clearFlash : null}
                                key={`${salt}:task:${task.id}`} />
                        ))}
                    </Tasks>
                )}
                <Plain
                    className="constructive"
                    icon={<Plus size={24} />}
                    onClick={() => addTask({ dueAt })}>
                    <FormattedMessage
                        id="task_action_add"
                        defaultMessage="Add task" />
                </Plain>
            </Content>
        </DrawablePanel>
    )
}

export const getPlaceholderTranslation = (type, when) => {
    if(type === 'onboarding') {
        if(when === 'before') {
            return {
                id: 'employee_onboarding_template_placeholder_first_workday_before',
                defaultMessage: '💡 Quick tips:\n\n• Plan some social events with the new hire\n• Prepare new hire for their first week\n• Write a Huma article, let your company get to know their new coworker\n• Set up accounts for new hire\n• Order equipment'
            }
        }

        if(when === 'on') {
            return {
                id: 'employee_onboarding_template_placeholder_first_workday_on',
                defaultMessage: '💡 Quick tips:\n\n• Plan lunch with the new hire\n• Take a tour of the office'
            }
        }

        if(when === 'after') {
            return {
                id: 'employee_onboarding_template_placeholder_first_workday_after',
                defaultMessage: '💡 Quick tips:\n\n• Plan follow-up meetings'
            }
        }
    }

    if(type === 'offboarding') {
        if(when === 'before') {
            return {
                id: 'employee_offboarding_template_placeholder_last_workday_before',
                defaultMessage: '💡 Quick tips:\n\n• Inform your colleagues about the departure\n• Prepare the paperwork – i.e. letter of termination or resignation\n• Initiate a knowledge transfer\n• Carry out an exit interview\n• Recover company assets\n• Redirect calls and emails'
            }
        }

        if(when === 'on') {
            return {
                id: 'employee_offboarding_template_placeholder_last_workday_on',
                defaultMessage: '💡 Quick tips:\n\n• Celebrate the next stop on their journey by scheduling a farewell lunch with the team\n• Consider giving them a card or a parting gift\n• Extend an invitation to your employee alumni network'
            }
        }

        if(when === 'after') {
            return {
                id: 'employee_offboarding_template_placeholder_last_workday_after',
                defaultMessage: '💡 Quick tips:\n\n• Close accounts and remove the employee from upcoming meetings\n• Write and send a letter of recommendation'
            }
        }
    }

    if(type === 'process') {
        if(when === 'before') {
            return {
                id: 'processes_template_placeholder_reference_date_before',
                defaultMessage: '💡 Reach out to everyone involved, and review any specific requirements or prerequisites needed to start off on the right foot'
            }
        }

        if(when === 'on') {
            return {
                id: 'processes_template_placeholder_reference_date_on',
                defaultMessage: '💡 Ensure that all the people involved are on board, and the necessary resources are in place, for the process to run smoothly'
            }
        }

        if(when === 'after') {
            return {
                id: 'processes_template_placeholder_reference_date_after',
                defaultMessage: '💡 Revisit goals and summarize findings to keep everyone informed and aligned moving forward'
            }
        }
    }

    return null
}

export default memo(TaskGroup)