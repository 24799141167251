import React, { useState, Fragment } from 'react'
import { useIntl } from 'react-intl'
import Page from 'contexts/page'
import ProcessesProvider, { useProcesses } from 'contexts/processes'
import PersonProvider from 'contexts/person'
import { usePossum } from 'hooks/possum'
import { getShortName } from 'utilities/person'
import { getOnboardingOnboardingUrl, getOffboardingOffboardingUrl, getProcessesProcessUrl } from 'utilities/url'
import { getDeleteActionPromptQuestionTranslation, getDeleteActionPromptMessageTranslation } from 'pages/processes/pages/process/header'
import { HeroActions } from './s'
import { Eye as View, Archive, Trash2 as Delete } from 'styled-icons/feather'
import Filter, { areFiltersApplied } from 'pages/processes/components/processes-filter'
import AddProcess from './add'
import { ButtonArrow } from 'components/button'
import Processes from 'pages/processes/lists/processes'
import { Scrollable as Modal } from 'modals/generic'
import ArchiveProcess from 'pages/processes/modals/archive-process'
import DeactivateAccount from 'pages/people/pages/profile/hero/deactivate'

const ProcessesPane = ({ access, salt }) => {
    const { formatMessage } = useIntl()

    const {
        processes,
        type,
        hasFetched,
        filter = {},

        removeProcess
    } = useProcesses()

    const [archiving, setArchiving] = useState(null)
    const [deactivating, setDeactivating] = useState(null)

    const possessify = usePossum()

    const getUrl = {
        onboarding: getOnboardingOnboardingUrl,
        offboarding: getOffboardingOffboardingUrl,
        process: getProcessesProcessUrl
    }[type]

    const viewAction = ({ id }) => ({
        salt: `${salt}:process:view`,
        icon: <View size={24} />,
        label: formatMessage({
            id: 'action_view',
            defaultMessage: 'View'
        }),
        effect: 'neutral',
        element: 'link',
        link: {
            to: getUrl({ id })
        }
    })

    const archiveAction = process => ({
        salt: `${salt}:process:archive`,
        icon: <Archive size={24} />,
        label: formatMessage({
            id: 'action_archive',
            defaultMessage: 'Archive'
        }),
        effect: 'neutral',
        onClick: () => setArchiving(process)
    })

    const removeAction = ({ id }) => ({
        salt: `${salt}:process:delete`,
        icon: <Delete size={24} />,
        label: formatMessage({
            id: 'action_delete',
            defaultMessage: 'Delete'
        }),
        effect: 'destructive',
        onClick: () => removeProcess(id),
        prompt: {
            question: formatMessage(getDeleteActionPromptQuestionTranslation(type)),
            message: formatMessage(getDeleteActionPromptMessageTranslation(type)),
            confirmText: formatMessage({
                id: 'action_delete',
                defaultMessage: 'Delete'
            })
        }
    })

    const removeAndDeactivateAction = ({ /*access: processAccess, */id, concerns }) => {
        // Is it a relevant action?
        if(type !== 'offboarding' || concerns?.status?.active === false || concerns?.type !== 'user') {
            return null
        }

        // Does the user have the required access?
        if(!access.usersManage) {
            return null
        }

        // Does the user have the required access?
        // if(
        //     !access.usersManage && // Gives access to both user deactivation and offboarding deletion
        //     (!access.unitProcessesManage || !processAccess.unit) // Gives the same access as above, combined
        // ) {
        //     return null
        // }

        return {
            salt: `${salt}:offboarding:delete-and-deactivate`,
            icon: <Delete size={24} />,
            label: formatMessage({
                id: 'employee_offboarding_action_delete_and_deactivate_user',
                defaultMessage: 'Delete offboarding and deactivate {pname} user account'
            }, { pname: possessify(getShortName(concerns)) }),
            effect: 'destructive',
            onClick: () => setDeactivating({
                processId: id,
                userId: concerns.id
            })
        }
    }

    const actions = {
        view: viewAction,
        archive: archiveAction,
        delete: removeAction,
        deleteAndDeactivate: removeAndDeactivateAction
    }

    const filtersApplied = areFiltersApplied(filter)

    return (
        <>
            <HeroActions>
                <Filter salt={salt} />
                {(access.usersManage || access.unitProcessesManage) && (
                    <Fragment>
                        <AddProcess />
                        {(!processes?.length && hasFetched && !filtersApplied) && <ButtonArrow className="attention-arrow" />}
                    </Fragment>
                )}
            </HeroActions>
            <Processes
                header={(!!processes?.length && hasFetched)}
                actions={actions}
                salt={salt} />
            <Modal
                show={!!archiving}
                dismiss={() => setArchiving(null)}
                salt={salt}>
                <ArchiveProcess
                    process={archiving}
                    dismiss={() => setArchiving(null)} />
            </Modal>
            {((type === 'offboarding') && (
                access.usersManage || // Gives access to both user deactivation and offboarding deletion
                (access.unitProcessesManage && access.unitUsersManage) // Gives the same access as above, combined
            )) && (
                <PersonProvider id={deactivating?.userId}>
                    <Modal
                        show={!!deactivating}
                        dismiss={() => setDeactivating(null)}
                        salt={salt}>
                        <DeactivateAccount
                            onBeforeDeactivate={() => removeProcess(deactivating?.processId)}
                            onAfterDeactivate={() => setDeactivating(null)}
                            cancel={() => setDeactivating(null)}
                            salt={`${salt}:offboarding:delete-and-deactivate`} />
                    </Modal>
                </PersonProvider>
            )}
        </>
    )
}

export default ({ title, type, upgradable, module, ...props }) => (
    <Page
        view={`${type}_people`}
        title={title}
        upgrade={{
            enabled: upgradable,
            tutorial: {
                which: module,
                upgrade: { module }
            }
        }}>
        <ProcessesProvider
            type={type}
            filter={{ archived: false }}
            sortCacheKey={`list:${type}:processes:active:sort`}>
            <ProcessesPane {...props} />
        </ProcessesProvider>
    </Page>
)