import React, { forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { Wrapper, PreviewButton, LanguageLegislation, Meta } from './s'
import ItemMeta from 'components/item-meta'
import Checkbox from 'components/form/input/checkbox'
import { Simple } from 'components/button'
import { fallbackSymbol } from 'pages/processes'

const ProcessTemplateItem = forwardRef(({ template, index, multiple, locked, picked, toggle, preview, salt, ...props }, ref) => {
    const {
        name,
        type,
        symbol,
        language,
        legislation,
        providedByHuma,
        providedBy
    } = template ?? {}

    const isLocked = !!locked.find(({ id }) => id === template.id)
    const isPicked = !!picked.find(({ id }) => id === template.id)

    const disabled = isLocked
    const checked = disabled || isPicked

    return (
        <Wrapper
            {...props}
            ref={ref}>
            <ItemMeta
                symbolProps={{
                    symbol: symbol ?? fallbackSymbol[type],
                    className: 'hover-effect'
                }}
                name={name}
                as="label"
                htmlFor={`${salt}:${template.id}:toggle`}
                meta={preview ?
                    {
                        text: (
                            <PreviewButton
                                onClick={preview}
                                className="constructive small">
                                <FormattedMessage
                                    id="action_preview"
                                    defaultMessage="Preview" />
                            </PreviewButton>
                        )
                    }
                    : null
                } />
            {(language || legislation) && (
                <LanguageLegislation
                    language={language}
                    legislation={legislation} />
            )}
            {(!!providedByHuma || providedBy) && (
                <Meta
                    type="provided"
                    by={!!providedByHuma ? 'Huma' : providedBy.name}
                    link={false}
                    className="compact small" />
            )}
            {!!multiple && (
                <Checkbox
                    id={`${salt}:${template.id}:toggle`}
                    defaultChecked={checked}
                    autoFocus={index === 0}
                    locked={isLocked}
                    disabled={disabled}
                    onChange={() => toggle(template)} />
            )}
            {!multiple && (
                <Simple
                    className="constructive"
                    disabled={checked}
                    onClick={() => toggle(template)}>
                    <FormattedMessage
                        id={isPicked ?
                            'noun_picked' :
                            'action_pick'
                        }
                        defaultMessage={isPicked ?
                            'Picked' :
                            'Pick'
                        } />
                </Simple>
            )}
        </Wrapper>
    )
})

export default ProcessTemplateItem