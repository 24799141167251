import { useEffect } from 'react'
import { useI18n } from 'contexts/i18n'
import { useLocation, useSearchParams } from 'react-router-dom'
import { local, session } from 'utilities/storage'
import { omit, size } from 'utilities/object'

const SearchParamHandler = () => {
    const location = useLocation()
    const [urlSearchParams, setUrlSearchParams] = useSearchParams()
    const { locale, setLocale } = useI18n()

    const searchParams = Object.fromEntries(urlSearchParams)

    useEffect(() => {
        if(!location.state?.urlSearchParamsConsumed && !!size(searchParams)) {
            // Native
            if(searchParams.native) {
                session.set('native', searchParams.native)
            }

            // Theme
            if(['light', 'dark'].includes(searchParams.theme)) {
                local.set('theme', searchParams.theme)
            }

            // Locale
            let overrideLocale = searchParams.locale
            if(searchParams.state) {
                overrideLocale = JSON.parse(searchParams.state).locale
            }

            if(searchParams.arrived) {
                overrideLocale = JSON.parse(searchParams.arrived).locale
            }

            if(!!overrideLocale && overrideLocale !== locale) {
                setLocale({ locale: overrideLocale })
            }

            setUrlSearchParams(omit(searchParams, 'locale', 'theme'), {
                state: { urlSearchParamsConsumed: true },
                replace: true
            })
        }
    }, [location, JSON.stringify(searchParams), locale])

    return null
}

export default SearchParamHandler