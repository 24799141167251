import React from 'react'
import { useSurvey } from 'contexts/survey'
import { modalify, Wrapper as ModalWrapper, Veil, Modal } from 'utilities/modal'
import { cls } from 'utilities/dom'
import { Container } from './s'
import CustomSurveyRespond from './custom'
import EnpsSurveyRespond from './enps'

const Respond = ({ show, dismiss, defaults, answerable, onDone, salt }) => {
    const { getResponseRun } = useSurvey()
    const source = getResponseRun()

    const animate = show ? 'in' : 'out'

    const RespondFlow = (source?.type === 'enps') ?
        EnpsSurveyRespond :
        CustomSurveyRespond

    return modalify(
        <ModalWrapper>
            {!!show && (
                <>
                    <Veil animate={animate} />
                    <Modal dismiss={dismiss}>
                        <Container
                            className={cls(['large', source.preview && 'preview'])}
                            animate={animate}>
                            <RespondFlow
                                defaults={defaults}
                                answerable={answerable}
                                onDone={onDone}
                                salt={salt} />
                        </Container>
                    </Modal>
                </>
            )}
        </ModalWrapper>
    )
}

export default Respond