import React, { useRef, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useModal } from 'contexts/modal'
import { useTimeout } from 'hooks/timeout'
import confetti from 'canvas-confetti'
import { ConfettiCanvas, Heading, Button } from './s'

const Summary = ({ answerable = false }) => {
    const { dismiss } = useModal()
    const canvas = useRef()

    const [showChildren, setShowChildren] = useState(false)
    useTimeout(() => setShowChildren(true), 1000)

    useEffect(() => {
        let showTimeout
        let confettiTimeout

        confettiTimeout = global.setTimeout(() => {
            canvas.current.confetti = canvas.current.confetti || confetti.create(canvas.current, { resize: true })

            const fire = (particleRatio, opts) => canvas.current.confetti({
                origin: { y: 0.7 },
                ...opts,
                particleCount: Math.floor(200 * particleRatio)
            })

            fire(.25, {
                spread: 26,
                startVelocity: 55
            })

            fire(.2, { spread: 60 })

            fire(.35, {
                spread: 100,
                decay: .91,
                scalar: .8
            })
            fire(.1, {
                spread: 120,
                startVelocity: 25,
                decay: .92,
                scalar: 1.2
            })

            fire(0.1, {
                spread: 120,
                startVelocity: 45
            })
        }, 500)

        return () => {
            !!showTimeout && global.clearTimeout(showTimeout)
            !!confettiTimeout && global.clearTimeout(confettiTimeout)
        }
    }, [])

    return (
        <>
            <ConfettiCanvas ref={canvas} />
            <Heading
                initial="out"
                animate={showChildren ? 'in' : 'out'}
                className="preserve-line-breaks">
                <FormattedMessage
                    id={!!answerable ?
                        'survey_response_submitted_thanks'
                        : 'survey_response_preview_submitted_thanks'
                    }
                    defaultMessage={!!answerable ?
                        'Thanks for\n­your response!'
                        : 'This was\n­just a preview.'
                    } />
            </Heading>
            <Button
                className="constructive"
                initial="out"
                animate={showChildren ? 'in' : 'out'}
                onClick={dismiss}>
                <FormattedMessage
                    id="action_close"
                    defaultMessage="Close" />
            </Button>
        </>
    )
}

export default Summary