import { post } from 'api'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useAccess } from 'contexts/access'
import { useAuth } from 'contexts/auth'
import { useOrganization } from 'contexts/organization'
import { useConfiguration } from 'contexts/configuration'
import { useMe } from 'contexts/me'
import { useTheme } from 'contexts/theme'
import { useUpgradable } from 'hooks/upgradable'
import { sendAppSignal } from 'hooks/app-signal'
import { usePossum } from 'hooks/possum'
import { useWhistleblowerPortalUrl } from 'pages/whistleblowing/utilities'
import paths from 'app/paths'
import { capitalize } from 'utilities/string'
import { reduce } from 'utilities/object'
import { prune } from 'utilities/array'
import { getFullName, getShortName } from 'utilities/person'
import {
    getPeopleProfileUrl, getPeopleProfileAbsenceUrl, getPeopleProfileMeetingsUrl, getPeopleProfileDocumentsUrl, getPeopleProfileCompetenceUrl, getPeopleProfileEquipmentUrl, getPeopleProfileTasksUrl,
    getDeviationCaseNewUrl,
    getUrlFromPath
} from 'utilities/url'
import { categoryToI18nMap as deviationCategoryToI18nMap } from 'pages/deviation/constants'
import { typeToI18nMap as absenceTypeToI18nMap } from 'pages/absence/constants'
import integrations from 'pages/integrations/pages/integration/data'
import {
    GitBranch,
    Sun as LightTheme,
    Moon as DarkTheme
} from 'styled-icons/feather'

export const useAddEntityCommands = () => {
    const { check } = useAccess()

    return search => {
        if(!search) {
            return {}
        }

        return {
            'add-entity:user': {
                category: 'add-person',
                label: {
                    id: 'command_bar_action_add_user',
                    defaultMessage: 'Add <strong>{name}</strong> as user'
                },
                ignoreRecent: true,
                element: 'link',
                link: {
                    to: paths.people.base,
                    state: {
                        intent: 'add-single-person',
                        rawInput: search
                    }
                },
                requirements: [check('users:manage')]
            },
            'add-entity:team': {
                id: 'team',
                category: 'add-team',
                label: {
                    id: 'command_bar_action_add_team',
                    defaultMessage: 'Add <strong>{name}</strong> as a team'
                },
                ignoreRecent: true,
                element: 'link',
                link: {
                    to: paths.people.teams,
                    state: {
                        intent: 'add-team',
                        group: { name: capitalize(search) }
                    }
                },
                requirements: [check('groups:manage') || check('teams:manage')]
            },
            'add-entity:location': {
                id: 'location',
                category: 'add-location',
                label: {
                    id: 'command_bar_action_add_location',
                    defaultMessage: 'Add <strong>{name}</strong> as a location'
                },
                ignoreRecent: true,
                element: 'link',
                link: {
                    to: paths.people.locations,
                    state: {
                        intent: 'add-location',
                        group: { name: capitalize(search) }
                    }
                },
                requirements: [check('groups:manage') || check('locations:manage')]
            }
        }
    }
}

export const useFixedCommands = () => {
    const { formatMessage } = useIntl()

    const {
        name: theme,
        setTheme
    } = useTheme()

    const {
        locale: currentLocale,
        locales,

        setLocale
    } = useI18n()

    const localLanguageNames = new Intl.DisplayNames([currentLocale], { type: 'language' })
    const englishLanguageNames = new Intl.DisplayNames(['en'], { type: 'language' })

    const whistleblowerPortalUrl = useWhistleblowerPortalUrl()
    const whistleblowerPortalReportUrl = useWhistleblowerPortalUrl({ subPath: '/cases/new' })

    const {
        check,
        checkModule,
        checkFeature
    } = useAccess()

    const checkUpgradable = useUpgradable()
    const { onSignOut } = useAuth()
    const { statuses = [] } = useOrganization()
    const { configuration } = useConfiguration()
    const { me } = useMe()

    const checkAccessOrUpgradable = module => checkModule(module) || checkUpgradable({ module })

    const enabledWhistleblowing = checkModule('whistleblowing')
    const isWhistleblowingSetUp = statuses?.hasSetUpWhistleblowing
    const whistleblowingAccess = enabledWhistleblowing && ['organization:manage', 'whistleblowing:manage'].some(check)

    const dev = process.env.NODE_ENV === 'development'

    return search => {
        const commands = {
            // Navigation
            'navigate:profile': {
                category: 'person',
                label: {
                    id: 'action_navigate_user_me',
                    defaultMessage: 'Go to your profile'
                },
                matches: ['profile', 'me', 'mine', getFullName(me)],
                element: 'link',
                link: {
                    to: getPeopleProfileUrl({ id: 'me' })
                }
            },
            'navigate:dashboard': {
                category: 'dashboard',
                label: {
                    id: 'noun_dashboard',
                    defaultMessage: 'Dashboard'
                },
                matches: ['dashboard'],
                element: 'link',
                link: { to: paths.root },
                requirements: [checkModule('dashboard')]
            },
            'navigate:people': {
                category: 'people',
                label: {
                    id: 'noun_people',
                    defaultMessage: 'People'
                },
                matches: ['people'],
                element: 'link',
                link: { to: paths.people.base },
                requirements: [checkAccessOrUpgradable('people')]
            },
            'navigate:people:teams': {
                category: 'teams',
                label: {
                    id: 'people_label_all_teams',
                    defaultMessage: 'All teams'
                },
                matches: ['all teams'],
                element: 'link',
                link: { to: paths.people.teams },
                requirements: [checkAccessOrUpgradable('people')]
            },
            'navigate:people:locations': {
                category: 'locations',
                label: {
                    id: 'people_label_all_locations',
                    defaultMessage: 'All locations'
                },
                matches: ['all locations'],
                element: 'link',
                link: { to: paths.people.locations },
                requirements: [checkAccessOrUpgradable('people')]
            },
            'navigate:people:org-chart': {
                category: 'subordinate',
                label: {
                    id: 'noun_org_chart',
                    defaultMessage: 'Organizational chart'
                },
                matches: ['organizational chart'],
                element: 'link',
                link: { to: paths.people.organizationalChart },
                requirements: [checkAccessOrUpgradable('people'), checkFeature('organization-chart')]
            },
            'navigate:salary': {
                category: 'salary',
                label: {
                    id: 'noun_salary',
                    defaultMessage: 'Salary'
                },
                matches: ['salary'],
                element: 'link',
                link: { to: paths.salary.base },
                requirements: [checkAccessOrUpgradable('salary'), !!me?.salary]
            },
            'navigate:salary:overview': {
                category: 'salary',
                label: {
                    id: 'noun_salary_overview',
                    defaultMessage: 'Salary overview'
                },
                matches: ['salary overview'],
                element: 'link',
                link: { to: paths.salary.overview },
                requirements: [checkAccessOrUpgradable('salary'), (check('salary:manage') || check('organization:manage'))]
            },
            'navigate:salary:revisions': {
                category: 'salary-revision',
                label: {
                    id: 'noun_salary_revisions',
                    defaultMessage: 'Salary revisions'
                },
                matches: ['salary revisions'],
                element: 'link',
                link: { to: paths.salary.revisions },
                requirements: [checkAccessOrUpgradable('salary'), (check('salary:manage') || check('organization:manage'))]
            },
            'navigate:handbook': {
                category: 'handbook',
                label: {
                    id: 'noun_handbook',
                    defaultMessage: 'Handbook'
                },
                matches: ['handbook'],
                element: 'link',
                link: { to: paths.handbook.base },
                requirements: [checkAccessOrUpgradable('handbook')]
            },
            'navigate:absence': {
                category: 'absence',
                label: {
                    id: 'noun_absence',
                    defaultMessage: 'Absence'
                },
                matches: ['absence'],
                element: 'link',
                link: { to: paths.absence.base },
                requirements: [checkAccessOrUpgradable('absence'), (!!statuses?.hasSetUpAbsenceTypes || !!statuses?.hasSetUpWorkSchedule)]
            },
            'navigate:absence:calendar': {
                category: 'calendar',
                label: {
                    id: 'noun_absence_calendar',
                    defaultMessage: 'Absence calendar'
                },
                matches: ['absence calendar'],
                element: 'link',
                link: { to: paths.absence.calendar },
                requirements: [checkAccessOrUpgradable('absence'), (!!statuses?.hasSetUpAbsenceTypes || !!statuses?.hasSetUpWorkSchedule)]
            },
            'navigate:absence:all': {
                category: 'absence',
                label: {
                    id: 'absence_section_organization',
                    defaultMessage: 'All absence'
                },
                matches: ['all absence'],
                element: 'link',
                link: { to: paths.absence.all },
                requirements: [checkAccessOrUpgradable('absence'), (!!statuses?.hasSetUpAbsenceTypes || !!statuses?.hasSetUpWorkSchedule), (check('absence:manage') || check('user:absence:manage') || check('user:absence:manage'))]
            },
            'navigate:absence:settings': {
                category: 'settings',
                label: {
                    id: 'noun_absence_settings',
                    defaultMessage: 'Absence settings'
                },
                matches: ['absence settings'],
                element: 'link',
                link: { to: paths.absence.settings },
                requirements: [checkAccessOrUpgradable('absence'), (!!statuses?.hasSetUpAbsenceTypes || !!statuses?.hasSetUpWorkSchedule), check('absence:manage')]
            },
            'navigate:absence:requests': {
                category: 'absence',
                label: {
                    id: 'absence_heading_requests',
                    defaultMessage: 'Absence requests'
                },
                matches: ['absence requests'],
                element: 'link',
                link: { to: paths.absence.calendar, state: { intent: 'show-absence-panel' } },
                requirements: [checkAccessOrUpgradable('absence'), !!statuses?.absence, (check('absence:manage') || check('user:absence:manage'))]
            },
            'navigate:meetings': {
                category: 'meetings',
                label: {
                    id: 'noun_meetings',
                    defaultMessage: 'Meetings'
                },
                matches: ['meetings'],
                element: 'link',
                link: { to: paths.meetings.base },
                requirements: [checkAccessOrUpgradable('performance')]
            },
            'navigate:meetings:rounds': {
                category: 'meetings',
                label: {
                    id: 'noun_meeting_rounds',
                    defaultMessage: 'Meeting rounds'
                },
                matches: ['meeting rounds'],
                element: 'link',
                link: { to: paths.meetings.rounds.base },
                requirements: [checkAccessOrUpgradable('performance'), checkFeature('meeting-rounds'), check('performance:manage')]
            },
            'navigate:news': {
                category: 'news',
                label: {
                    id: 'noun_news',
                    defaultMessage: 'News'
                },
                matches: ['news'],
                element: 'link',
                link: { to: paths.news.base },
                requirements: [checkAccessOrUpgradable('news')]
            },
            'navigate:news:drafts': {
                category: 'news',
                label: {
                    id: 'articles_section_drafts',
                    defaultMessage: 'Drafts'
                },
                matches: ['news drafts'],
                element: 'link',
                link: { to: paths.news.drafts.base },
                requirements: [checkAccessOrUpgradable('news'), check('articles:manage')]
            },
            'navigate:onboarding': {
                category: 'onboarding',
                label: {
                    id: 'noun_onboarding',
                    defaultMessage: 'Onboarding'
                },
                matches: ['onboarding'],
                element: 'link',
                link: { to: paths.onboarding.base },
                requirements: [checkAccessOrUpgradable('onboarding'), (check('users:manage') || check('unit:employment-lifecycles:manage'))]
            },
            'navigate:onboarding:templates': {
                category: 'onboarding',
                label: {
                    id: 'employee_onboarding_noun_templates',
                    defaultMessage: 'Onboarding templates'
                },
                matches: ['onboarding templates'],
                element: 'link',
                link: {
                    to: (!check('users:manage') && !check('unit:employment-lifecycles:manage')) ?
                        paths.onboarding.base
                        : paths.onboarding.templates
                },
                requirements: [checkAccessOrUpgradable('onboarding'), check('employment-lifecycles:templates:manage')]
            },
            'navigate:offboarding': {
                category: 'offboarding',
                label: {
                    id: 'noun_offboarding',
                    defaultMessage: 'Offboarding'
                },
                matches: ['offboarding'],
                element: 'link',
                link: { to: paths.offboarding.base },
                requirements: [checkAccessOrUpgradable('offboarding'), (check('users:manage') || check('unit:employment-lifecycles:manage'))]
            },
            'navigate:offboarding:templates': {
                category: 'offboarding',
                label: {
                    id: 'employee_offboarding_noun_templates',
                    defaultMessage: 'Offboarding templates'
                },
                matches: ['offboarding templates'],
                element: 'link',
                link: {
                    to: (!check('users:manage') && !check('unit:employment-lifecycles:manage')) ?
                        paths.offboarding.base
                        : paths.offboarding.templates
                },
                requirements: [checkAccessOrUpgradable('offboarding'), check('employment-lifecycles:templates:manage')]
            },
            'navigate:processes': {
                category: 'processes',
                label: {
                    id: 'noun_processes',
                    defaultMessage: 'Processes'
                },
                matches: ['process', 'processes'],
                element: 'link',
                link: { to: paths.processes.base },
                requirements: [checkAccessOrUpgradable('processes'), (check('users:manage') || check('unit:processes:manage'))]
            },
            'navigate:processes:templates': {
                category: 'processes',
                label: {
                    id: 'processes_noun_templates',
                    defaultMessage: 'Process templates'
                },
                matches: ['process templates'],
                element: 'link',
                link: {
                    to: (!check('users:manage') && !check('unit:processes:manage')) ?
                        paths.processes.base
                        : paths.processes.templates
                },
                requirements: [checkAccessOrUpgradable('processes'), check('processes:templates:manage')]
            },
            'navigate:documents': {
                category: 'documents',
                label: {
                    id: 'noun_documents',
                    defaultMessage: 'Documents'
                },
                matches: ['documents'],
                element: 'link',
                link: { to: paths.documents.base },
                requirements: [checkAccessOrUpgradable('documents')]
            },
            'navigate:documents:templates': {
                category: 'smart-document-templates',
                label: {
                    id: 'documents_smart_templates_full_noun',
                    defaultMessage: 'Smart document templates'
                },
                matches: ['document templates', 'smart templates'],
                element: 'link',
                link: { to: paths.documents.templates },
                requirements: [checkAccessOrUpgradable('smart-document-templates'), check('smart-document-templates:manage')]
            },
            'navigate:competence': {
                category: 'competence',
                label: {
                    id: 'competence_section_your_competence',
                    defaultMessage: 'Your competence'
                },
                matches: ['your competence'],
                element: 'link',
                link: { to: paths.competence.base },
                requirements: [checkAccessOrUpgradable('competence')]
            },
            'navigate:competence:all': {
                category: 'competence',
                label: {
                    id: 'competence_section_organization',
                    defaultMessage: 'All competences'
                },
                matches: ['all competence'],
                element: 'link',
                link: { to: paths.competence.types },
                requirements: [checkAccessOrUpgradable('competence')]
            },
            'navigate:competence:gap': {
                category: 'competence-gap',
                label: {
                    id: 'competence_section_gap_analysis',
                    defaultMessage: 'Gap analysis'
                },
                matches: ['competence gap', 'gap analysis'],
                element: 'link',
                link: { to: paths.competence.gap },
                requirements: [checkAccessOrUpgradable('competence'), check('competence:manage')]
            },
            'navigate:competence:profiles': {
                category: 'competence-profiles',
                label: {
                    id: 'feature_competence_profiles',
                    defaultMessage: 'Competence profiles'
                },
                matches: ['competence profiles'],
                element: 'link',
                link: { to: paths.competence.profiles },
                requirements: [checkAccessOrUpgradable('competence'), check('competence:manage')]
            },
            'navigate:equipment': {
                category: 'equipment',
                label: {
                    id: 'equipment_section_your_equipment',
                    defaultMessage: 'Your equipment'
                },
                matches: ['my equipment'],
                element: 'link',
                link: { to: paths.equipment.base },
                requirements: [checkAccessOrUpgradable('equipment')]
            },
            'navigate:equipment:all': {
                category: 'equipment',
                label: {
                    id: 'equipment_section_organization',
                    defaultMessage: 'All equipment'
                },
                matches: ['all equipment'],
                element: 'link',
                link: { to: paths.equipment.types },
                requirements: [checkAccessOrUpgradable('equipment'), check('equipment:manage')]
            },
            'navigate:tasks': {
                category: 'tasks',
                label: {
                    id: 'noun_tasks',
                    defaultMessage: 'Tasks'
                },
                matches: ['tasks'],
                element: 'link',
                link: { to: paths.tasks.base },
                requirements: [checkAccessOrUpgradable('tasks')]
            },
            'navigate:tasks:others': {
                category: 'tasks',
                label: {
                    id: 'noun_tasks_delegated',
                    defaultMessage: 'Tasks assigned to others'
                },
                matches: ['others tasks'],
                element: 'link',
                link: { to: paths.tasks.delegated },
                requirements: [checkAccessOrUpgradable('tasks')]
            },
            'navigate:deviation': {
                category: 'deviation',
                label: {
                    id: 'noun_deviation',
                    defaultMessage: 'Deviation'
                },
                matches: ['deviation'],
                element: 'link',
                link: { to: paths.deviation.base },
                requirements: [checkAccessOrUpgradable('deviation'), !!statuses?.hasSetUpDeviation]
            },
            'navigate:deviation:settings': {
                category: 'settings',
                label: {
                    id: 'noun_deviation_settings',
                    defaultMessage: 'Deviation settings'
                },
                matches: ['deviation settings'],
                element: 'link',
                link: { to: paths.deviation.settings },
                requirements: [checkAccessOrUpgradable('deviation'), !!statuses?.hasSetUpDeviation, (check('organization:manage') || check('deviation:manage'))]
            },
            'navigate:insights': {
                category: 'insights',
                label: {
                    id: 'noun_insights',
                    defaultMessage: 'Insights'
                },
                matches: ['insights'],
                element: 'link',
                link: { to: paths.insights },
                requirements: [checkAccessOrUpgradable('insights'), (check('insights:manage') || check('unit:insights:manage'))]
            },
            'navigate:surveys': {
                category: 'surveys',
                label: {
                    id: 'noun_surveys',
                    defaultMessage: 'Surveys'
                },
                matches: ['surveys', 'enps', 'employee satisfaction', 'questionnaire', 'form'],
                element: 'link',
                link: { to: paths.surveys.base },
                requirements: [checkAccessOrUpgradable('surveys'), (check('surveys:read') || check('surveys:write'))]
            },
            'navigate:whistleblowing:portal': {
                category: 'whistleblowing',
                label: {
                    id: 'noun_whistleblower_portal',
                    defaultMessage: 'Whistleblowing Portal'
                },
                matches: ['whistleblowing'],
                element: 'a',
                link: {
                    href: whistleblowerPortalUrl,
                    target: '_blank'
                },
                external: true,
                requirements: [enabledWhistleblowing, !!isWhistleblowingSetUp]
            },
            'navigate:whistleblowing:case': {
                category: 'whistleblowing',
                label: {
                    id: 'whistleblowing_action_report',
                    defaultMessage: 'Report a whistleblowing incident'
                },
                matches: ['report whistleblowing case'],
                element: 'a',
                link: {
                    href: whistleblowerPortalReportUrl,
                    target: '_blank'
                },
                external: true,
                requirements: [enabledWhistleblowing, !!isWhistleblowingSetUp]
            },
            'navigate:whistleblowing': {
                category: 'whistleblowing',
                label: {
                    id: 'noun_whistleblowing',
                    defaultMessage: 'Whistleblowing'
                },
                matches: ['whistleblowing'],
                element: 'link',
                link: { to: paths.whistleblowing.base },
                requirements: [enabledWhistleblowing, !!isWhistleblowingSetUp, !!whistleblowingAccess]
            },
            'navigate:activity-log': {
                category: 'activity-log',
                label: {
                    id: 'noun_activity_log',
                    defaultMessage: 'Activity log'
                },
                matches: ['activity log'],
                element: 'link',
                link: { to: paths.activityLog },
                requirements: [!!configuration?.audit, check('organization:manage')]
            },
            'navigate:integrations': {
                category: 'integrations',
                label: {
                    id: 'noun_integrations',
                    defaultMessage: 'Integrations'
                },
                matches: ['integration'],
                element: 'link',
                link: { to: paths.integrations.base },
                requirements: [checkAccessOrUpgradable('integrations'), check('organization:manage')]
            },
            ...reduce(integrations, (accumulator, integration) => {
                const { id, name, icon } = integration()

                return {
                    ...accumulator,
                    [`navigate:integrations:${id}`]: {
                        category: 'integrations',
                        label: name,
                        matches: [name, 'integration'],
                        element: 'link',
                        link: { to: getUrlFromPath('path:integrations.integration.base')({ type: id }) },
                        icon,
                        requirements: [checkAccessOrUpgradable('integrations'), check('organization:manage')]
                    }
                }
            }),
            'navigate:settings': {
                category: 'settings',
                label: {
                    id: 'noun_settings_system',
                    defaultMessage: 'System settings'
                },
                matches: ['system settings'],
                element: 'link',
                link: { to: paths.settings.base },
                requirements: [
                    'users:manage',
                    'users:custom-fields:manage',
                    'roles:manage',
                    'organization:manage'
                ].map(check)
            },
            'navigate:settings:subscription': {
                category: 'subscription',
                label: {
                    id: 'settings_pane_subscription',
                    defaultMessage: 'Subscription'
                },
                matches: ['subscription'],
                element: 'link',
                link: { to: paths.settings.subscription.base },
                requirements: [check('organization:manage')]
            },
            'navigate:modules': {
                category: 'modules',
                label: {
                    id: 'settings_pane_modules',
                    defaultMessage: 'Manage modules'
                },
                matches: ['modules'],
                element: 'link',
                link: { to: paths.settings.modules },
                requirements: [check('organization:manage')]
            },
            'navigate:settings:custom-profile-fields': {
                category: 'custom-user-fields',
                label: {
                    id: 'profile_custom_fields_heading',
                    defaultMessage: 'Custom profile fields'
                },
                matches: ['custom profile fields'],
                element: 'link',
                link: { to: paths.settings.customprofilefields },
                requirements: [checkFeature('custom-user-fields'), check('users:custom-fields:manage')]
            },
            'navigate:settings:job-titles': {
                category: 'content-assistant',
                label: {
                    id: 'noun_jobtitles',
                    defaultMessage: 'Job titles'
                },
                matches: ['job titles'],
                className: 'ai',
                element: 'link',
                link: { to: paths.settings.userprofile },
                requirements: [(
                    check('users:manage') ||
                    checkUpgradable({ feature: 'content-assistant' })
                )]
            },
            'navigate:settings:roles': {
                category: 'accessroles',
                label: {
                    id: 'settings_pane_roles',
                    defaultMessage: 'Roles'
                },
                matches: ['roles', 'grants', 'role grants'],
                element: 'link',
                link: { to: paths.settings.roles.base },
                requirements: [check('roles:manage')]
            },
            'navigate:settings:user-roles': {
                category: 'accessroles',
                label: {
                    id: 'roles_user_heading',
                    defaultMessage: 'User roles'
                },
                matches: ['user roles'],
                element: 'link',
                link: { to: paths.settings.roles.user.base },
                requirements: [check('roles:manage')]
            },
            'navigate:settings:system-roles': {
                category: 'accessroles',
                label: {
                    id: 'roles_organization_heading',
                    defaultMessage: 'System roles'
                },
                matches: ['system roles'],
                element: 'link',
                link: { to: paths.settings.roles.system.base },
                requirements: [check('roles:manage')]
            },
            'navigate:settings:domain-verifications': {
                category: 'people-domain-signup',
                label: {
                    id: 'settings_pane_domainverification',
                    defaultMessage: 'Domain verification'
                },
                matches: ['domain verification'],
                element: 'link',
                link: { to: paths.settings.domainverification },
                requirements: [(
                    (
                        checkFeature('people-domain-signup') &&
                        check('organization:manage')
                    ) ||
                    checkUpgradable({ feature: 'people-domain-signup' })
                )]
            },
            // Actions
            'action:addUser': {
                category: 'add-person',
                label: {
                    id: 'person_action_add',
                    defaultMessage: 'Add person'
                },
                matches: ['add user'],
                element: 'link',
                link: {
                    to: paths.people.base,
                    state: { intent: 'add-single-person' }
                },
                requirements: [check('users:manage')]
            },
            'action:addTeam': {
                category: 'add-team',
                label: {
                    id: 'team_action_add',
                    defaultMessage: 'Add team'
                },
                matches: ['add team'],
                element: 'link',
                link: {
                    to: paths.people.teams,
                    state: { intent: 'add-team' }
                },
                requirements: [check('groups:manage') || check('teams:manage')]
            },
            'action:addLocation': {
                category: 'add-location',
                label: {
                    id: 'location_action_add',
                    defaultMessage: 'Add location'
                },
                matches: ['add location'],
                element: 'link',
                link: {
                    to: paths.people.locations,
                    state: { intent: 'add-location' }
                },
                requirements: [check('groups:manage') || check('locations:manage')]
            },
            'action:addSalaryRevision': {
                category: 'salary-revision',
                label: {
                    id: 'salary_revision_action_add',
                    defaultMessage: 'New salary revision'
                },
                matches: ['add salary revision', 'new salary revision'],
                element: 'link',
                link: {
                    to: paths.salary.revisions,
                    state: { intent: 'create-salary-revision' }
                },
                requirements: [checkAccessOrUpgradable('salary'), check('salary:manage')]
            },
            'action:addHandbookCategory': {
                category: 'handbook',
                label: {
                    id: 'handbooks_action_add_category',
                    defaultMessage: 'Add handbook category'
                },
                matches: ['create handbook category', 'add handbook category'],
                element: 'link',
                link: {
                    to: paths.handbook.base,
                    state: { intent: 'handbook:add-category' }
                },
                requirements: [checkAccessOrUpgradable('handbook'), check('handbook:manage')]
            },
            'action:addHandbookFromTemplate': {
                category: 'handbook',
                label: {
                    id: 'handbooks_category_action_add_from_template',
                    defaultMessage: 'Add handbook category from template'
                },
                matches: ['add handbook from template'],
                element: 'link',
                link: {
                    to: paths.handbook.base,
                    state: { intent: 'handbook:add-from-template' }
                },
                requirements: [checkAccessOrUpgradable('handbook'), check('handbook:manage')]
            },
            'action:registerAbsence': {
                category: 'absence',
                label: {
                    id: 'absence_action_register',
                    defaultMessage: 'Register absence'
                },
                matches: Object.entries(absenceTypeToI18nMap).map(([key, id]) => ({
                    variant: 'type',
                    text: formatMessage({ id }),
                    id: key
                })),
                element: 'link',
                link: {
                    to: paths.absence.base,
                    state: { intent: 'register-absence' }
                },
                displayMatch: true,
                requirements: [checkAccessOrUpgradable('absence'), (!!statuses?.hasSetUpAbsenceTypes || !!statuses?.hasSetUpWorkSchedule)]
            },
            'action:createMeeting': {
                category: 'meeting',
                label: {
                    id: 'meeting_action_add',
                    defaultMessage: 'New meeting'
                },
                matches: ['create meeting'],
                element: 'link',
                link: {
                    to: paths.meetings.base,
                    state: { intent: 'create-event' }
                },
                requirements: [checkAccessOrUpgradable('performance')]
            },
            'action:createMeetingRound': {
                category: 'meeting-rounds',
                label: {
                    id: 'meeting_round_action_create',
                    defaultMessage: 'Create meeting round'
                },
                matches: ['create meeting round'],
                element: 'link',
                link: {
                    to: paths.meetings.rounds.base,
                    state: { intent: 'create-meeting-round' }
                },
                requirements: [
                    checkModule('performance'),
                    (
                        (checkFeature('meeting-rounds') && check('performance:manage')) ||
                        checkUpgradable({ feature: 'meeting-rounds' })
                    )
                ]
            },
            'action:addArticle': {
                category: 'article',
                label: {
                    id: 'news_action_create',
                    defaultMessage: 'New article'
                },
                matches: ['create article', 'add article', 'new article', 'write article', 'news article'],
                element: 'link',
                link: {
                    to: paths.news.base,
                    state: { intent: 'add-article' }
                },
                requirements: [checkAccessOrUpgradable('news'), check('articles:manage'), !checkUpgradable({ module: 'news' })]
            },
            'action:uploadDocument': {
                category: 'document',
                label: {
                    id: 'document_action_upload',
                    defaultMessage: 'Upload document'
                },
                matches: ['upload document', 'add document', 'new document'],
                element: 'link',
                link: {
                    to: paths.documents.base,
                    state: { intent: 'upload' }
                },
                requirements: [checkAccessOrUpgradable('documents')]
            },
            'action:addSmartDocument': {
                category: 'smart-documents',
                label: {
                    id: 'documents_smart_document_action_add',
                    defaultMessage: 'Create a smart document'
                },
                matches: ['create smart document', 'add smart document', 'new smart document'],
                element: 'link',
                link: {
                    to: paths.documents.base,
                    state: { intent: 'add' }
                },
                requirements: [checkAccessOrUpgradable('smart-documents'), checkFeature('smart-documents')]
            },
            'action:newSmartDocumentTemplate': {
                category: 'smart-document-templates',
                label: {
                    id: 'documents_smart_templates_action_add',
                    defaultMessage: 'Add smart document template'
                },
                matches: ['create smart document template', 'new smart document template', 'add smart document template'],
                element: 'link',
                link: {
                    to: paths.documents.templates,
                    state: { intent: 'add' }
                },
                requirements: [checkAccessOrUpgradable('smart-document-templates'), check('smart-document-templates:manage')]
            },
            'action:addCompetence': {
                category: 'competence',
                label: {
                    id: 'competence_action_add',
                    defaultMessage: 'Add competence'
                },
                matches: ['add competence'],
                element: 'link',
                link: {
                    to: paths.competence.base,
                    state: { intent: 'add-competence' }
                },
                requirements: [checkAccessOrUpgradable('competence'), check('competence:manage')]
            },
            'action:addEquipment': {
                category: 'equipment',
                label: {
                    id: 'equipment_action_add',
                    defaultMessage: 'Add equipment'
                },
                matches: ['add equipment'],
                element: 'link',
                link: {
                    to: paths.equipment.base,
                    state: { intent: 'add-equipment' }
                },
                requirements: [checkAccessOrUpgradable('equipment'), check('equipment:manage')]
            },
            'action:addTask': {
                category: 'task',
                label: {
                    id: 'task_action_add',
                    defaultMessage: 'Add task'
                },
                matches: ['create task', 'add task'],
                element: 'link',
                link: {
                    to: paths.tasks.base,
                    state: { intent: 'add-task' }
                },
                requirements: [checkAccessOrUpgradable('tasks')]
            },
            'action:reportDeviation': {
                category: 'deviation',
                label: {
                    id: 'deviation_action_report',
                    defaultMessage: 'Report a deviation'
                },
                matches: Object.entries(deviationCategoryToI18nMap)
                    .filter(([key]) => key !== 'other')
                    .map(([key, id]) => ({
                        variant: 'category',
                        text: formatMessage({ id }),
                        id: key
                    })),
                displayMatch: true,
                element: 'link',
                link: {
                    to: getDeviationCaseNewUrl(),
                    state: { intent: 'report-deviation' }
                },
                requirements: [checkAccessOrUpgradable('deviation'), !!statuses?.hasSetUpDeviation]
            },
            'action:help': {
                category: 'help',
                label: {
                    id: 'help_action_show',
                    defaultMessage: 'Show help'
                },
                matches: ['help'],
                onClick: () => sendAppSignal('help.show')
            },
            'action:accountSettings': {
                category: 'settings',
                label: {
                    id: 'noun_settings_account',
                    defaultMessage: 'User account settings'
                },
                matches: ['user account settings'],
                onClick: () => sendAppSignal('account-settings.show')
            },
            'action:theme:light': {
                category: 'settings',
                icon: LightTheme,
                label: {
                    id: 'settings_theme_action_light',
                    defaultMessage: 'Switch to light theme'
                },
                matches: ['light', 'bright', 'sun', 'day', 'mode', 'theme'],
                onClick: () => setTheme('light'),
                requirements: [theme !== 'light']
            },
            'action:theme:dark': {
                category: 'settings',
                icon: DarkTheme,
                label: {
                    id: 'settings_theme_action_dark',
                    defaultMessage: 'Switch to dark theme'
                },
                matches: ['dark', 'moon', 'night', 'mode', 'theme'],
                onClick: () => setTheme('dark'),
                requirements: [theme !== 'dark']
            },
            'action:changeLanguage': {
                category: 'settings',
                label: {
                    id: 'account_settings_language_alert_title',
                    defaultMessage: 'Change language'
                },
                matches: ['change language'],
                onClick: () => sendAppSignal('account-settings.show')
            },
            ...reduce(locales, (accumulator, { flag, name }, locale) => {
                if(locale === currentLocale) {
                    return accumulator
                }

                const localName = localLanguageNames.of(locale)
                const englishName = englishLanguageNames.of(locale)

                const language = prune([
                    flag,
                    name,
                    (name.toLowerCase() !== localName.toLowerCase()) && `(${localName})`
                ]).join(' ')

                return {
                    ...accumulator,
                    [`action:changeLanguage:${locale}`]: {
                        category: 'settings',
                        label: {
                            id: 'action_use_huma_in_language',
                            defaultMessage: 'Use Huma in {language}',
                            values: { language }
                        },
                        matches: ['change language', name, localName, englishName].map(match => match.toLowerCase()),
                        onClick: () => setLocale({ locale }, { store: true })
                    }
                }
            }),
            'action:changeWeekStartsOn': {
                category: 'settings',
                label: {
                    id: 'locale_week_starts_on',
                    defaultMessage: 'Week starts on'
                },
                matches: ['week starts on'],
                onClick: () => sendAppSignal('account-settings.show')
            },
            'action:notificationSettings': {
                category: 'notificationSettings',
                label: {
                    id: 'noun_settings_notifications',
                    defaultMessage: 'Notification settings'
                },
                matches: ['notification settings'],
                element: 'link',
                link: { to: paths.people.profile.settings.notifications }
            },
            'action:dashboard:configure': {
                category: 'configureDashboard',
                label: {
                    id: 'action_configure_dashboard',
                    defaultMessage: 'Configure dashboard'
                },
                matches: ['configure dashboard'],
                onClick: () => sendAppSignal('dashboard:configure.toggle', true),
                requirements: [checkModule('dashboard')]
            },
            'action:logout': {
                category: 'logOut',
                label: {
                    id: 'action_logout',
                    defaultMessage: 'Log out'
                },
                matches: ['log out', 'logout'],
                onClick: () => {
                    post({
                        path: '/auth/oauth/revoke',
                        returnsData: false
                    })

                    onSignOut({ userInitiated: true })
                }
            },
            ...((dev && !!global?.huma?.storage?.local) ? {
                'action:setReviewApi': {
                    category: 'settings',
                    label: {
                        id: 'developer_use_review_api',
                        defaultMessage: 'Use review API (huma-api-pr-{name}.herokuapp.com)'
                    },
                    matches: [/^([0-9]{4})$/],
                    ignoreLabelMatch: true,
                    onClick: () => global.huma.storage.local.set('review-api', search),
                    icon: GitBranch
                },
                'action:setTestApi': {
                    category: 'settings',
                    label: {
                        id: 'developer_use_test_api',
                        defaultMessage: 'Use test API (test.api.humahr.com)'
                    },
                    matches: ['use test api', 'test api', 'api', 'test'],
                    ignoreLabelMatch: true,
                    onClick: () => global.huma.storage.local.set('review-api', 'test'),
                    icon: GitBranch
                },
                'action:revertReviewApi': {
                    category: 'settings',
                    label: {
                        id: 'developer_revert_review_api',
                        defaultMessage: 'Revert review API (dev.api.humahr.com)'
                    },
                    matches: ['revert review api', 'review api', 'api', 'dev'],
                    onClick: () => global.huma.storage.local.remove('review-api'),
                    icon: GitBranch
                }
            } : null)
        }

        return reduce(commands, (accumulator, { matches, ...command }, key) => ({
            ...accumulator,
            [key]: {
                ...command,
                matches: prune(matches)
            }
        }))
    }
}

export const useProfileCommands = () => {
    const { formatMessage } = useIntl()

    const {
        check,
        checkModule
    } = useAccess()

    const checkUpgradable = useUpgradable()

    const { statuses = [] } = useOrganization()

    const checkAccessOrUpgradable = module => checkModule(module) || checkUpgradable({ module })

    const possessify = usePossum()

    return [
        user => ({
            id: 'profile:absence',
            category: 'absence',
            label: formatMessage({
                id: 'absence_action_navigate_user',
                defaultMessage: 'Go to {pname} absence'
            }, { pname: possessify(getShortName(user)) }),
            keywords: [formatMessage({
                id: 'noun_absence',
                defaultMessage: 'Absence'
            })],
            element: 'link',
            link: { to: getPeopleProfileAbsenceUrl(user) },
            requirements: [checkAccessOrUpgradable('absence'), (!!statuses?.hasSetUpAbsenceTypes || !!statuses?.hasSetUpWorkSchedule)]
        }),
        user => ({
            id: 'profile:meetings',
            category: 'meetings',
            label: formatMessage({
                id: 'meeting_action_navigate_user',
                defaultMessage: 'Go to {pname} meetings'
            }, { pname: possessify(getShortName(user)) }),
            keywords: [
                formatMessage({
                    id: 'noun_meeting',
                    defaultMessage: 'Meeting'
                }),
                formatMessage({
                    id: 'noun_meetings',
                    defaultMessage: 'Meetings'
                })
            ],
            element: 'link',
            link: { to: getPeopleProfileMeetingsUrl(user) },
            requirements: [checkAccessOrUpgradable('performance')]
        }),
        user => ({
            id: 'profile:documents',
            category: 'documents',
            label: formatMessage({
                id: 'documents_action_navigate_user',
                defaultMessage: 'Go to {pname} documents'
            }, { pname: possessify(getShortName(user)) }),
            keywords: [
                formatMessage({
                    id: 'noun_document',
                    defaultMessage: 'Document'
                }),
                formatMessage({
                    id: 'noun_documents',
                    defaultMessage: 'Documents'
                })
            ],
            element: 'link',
            link: { to: getPeopleProfileDocumentsUrl(user) },
            requirements: [checkAccessOrUpgradable('documents')]
        }),
        user => ({
            id: 'profile:competence',
            category: 'competence',
            label: formatMessage({
                id: 'competence_action_navigate_user',
                defaultMessage: 'Go to {pname} competence'
            }, { pname: possessify(getShortName(user)) }),
            keywords: [formatMessage({
                id: 'noun_competence',
                defaultMessage: 'Competence'
            })],
            element: 'link',
            link: { to: getPeopleProfileCompetenceUrl(user) },
            requirements: [checkAccessOrUpgradable('competence')]
        }),
        user => ({
            id: 'profile:equipment',
            category: 'equipment',
            label: formatMessage({
                id: 'equipment_action_navigate_user',
                defaultMessage: 'Go to {pname} equipment'
            }, { pname: possessify(getShortName(user)) }),
            keywords: [formatMessage({
                id: 'noun_equipment',
                defaultMessage: 'Equipment'
            })],
            element: 'link',
            link: { to: getPeopleProfileEquipmentUrl(user) },
            requirements: [checkAccessOrUpgradable('equipment'), check('equipment:manage')]
        }),
        user => ({
            id: 'profile:tasks',
            category: 'tasks',
            label: formatMessage({
                id: 'tasks_action_navigate_user',
                defaultMessage: 'Go to {pname} tasks'
            }, { pname: possessify(getShortName(user)) }),
            keywords: [
                formatMessage({
                    id: 'noun_task',
                    defaultMessage: 'Task'
                }),
                formatMessage({
                    id: 'noun_tasks',
                    defaultMessage: 'Tasks'
                })
            ],
            element: 'link',
            link: { to: getPeopleProfileTasksUrl(user) },
            requirements: [checkAccessOrUpgradable('tasks')]
        })
    ]
}

export const useGroupCommands = () => {
    const { formatMessage } = useIntl()

    const {
        check,
        checkModule
    } = useAccess()
    const checkUpgradable = useUpgradable()
    const { statuses = [] } = useOrganization()
    const { me } = useMe()

    const checkAccessOrUpgradable = module => checkModule(module) || checkUpgradable({ module })

    const possessify = usePossum()

    return [
        group => {
            const { type } = group
            const { name } = group[type]

            return {
                id: `${type}:absence`,
                category: 'absence',
                label: formatMessage({
                    id: 'absence_action_navigate_user',
                    defaultMessage: 'Go to {pname} absence'
                }, { pname: possessify(name) }),
                keywords: [formatMessage({
                    id: 'noun_absence',
                    defaultMessage: 'Absence'
                })],
                element: 'link',
                link: { to: getUrlFromPath(`path:people.${type}.absence`)(group[type]) },
                requirements: [checkAccessOrUpgradable('absence'), (!!statuses?.hasSetUpAbsenceTypes || !!statuses?.hasSetUpWorkSchedule)]
            }
        },
        group => {
            const { type } = group
            const { id, name } = group[type]

            const myGroups = [
                ...me?.teams?.value ?? [],
                ...me?.locations?.value ?? []
            ]

            const isMember = myGroups.some(group => group.id === id)

            return {
                id: `${type}:news`,
                category: 'news',
                label: formatMessage({
                    id: 'news_action_navigate_entity',
                    defaultMessage: 'Go to {pname} news'
                }, { pname: possessify(name) }),
                keywords: [formatMessage({
                    id: 'noun_news',
                    defaultMessage: 'News'
                })],
                element: 'link',
                link: { to: getUrlFromPath(`path:people.${type}.news`)(group[type]) },
                requirements: [checkAccessOrUpgradable('news'), (isMember || check('articles:manage'))]
            }
        },
        group => {
            const { type } = group
            const { name } = group[type]

            return {
                id: `${type}:competence`,
                category: 'competence',
                label: formatMessage({
                    id: 'competence_action_navigate_user',
                    defaultMessage: 'Go to {pname} competence'
                }, { pname: possessify(name) }),
                keywords: [formatMessage({
                    id: 'noun_competence',
                    defaultMessage: 'Competence'
                })],
                element: 'link',
                link: { to: getUrlFromPath(`path:people.${type}.competence`)(group[type]) },
                requirements: [checkAccessOrUpgradable('competence')]
            }
        },
        group => {
            const { type } = group
            const { name } = group[type]

            return {
                id: `${type}:gap`,
                category: 'competenceGap',
                label: formatMessage({
                    id: 'competence_gap_action_navigate_entity',
                    defaultMessage: 'Go to {pname} gap analysis'
                }, { pname: possessify(name) }),
                keywords: [formatMessage({
                    id: 'noun_competenceGap',
                    defaultMessage: 'Gap analysis'
                })],
                element: 'link',
                link: { to: getUrlFromPath(`path:people.${type}.gap`)(group[type]) },
                requirements: [checkAccessOrUpgradable('competence'), check('competence:manage')]
            }
        },
        group => {
            const { type } = group
            const { name } = group[type]

            return {
                id: `${type}:equipment`,
                category: 'equipment',
                label: formatMessage({
                    id: 'equipment_action_navigate_user',
                    defaultMessage: 'Go to {pname} equipment'
                }, { pname: possessify(name) }),
                keywords: [formatMessage({
                    id: 'noun_equipment',
                    defaultMessage: 'Equipment'
                })],
                element: 'link',
                link: { to: getUrlFromPath(`path:people.${type}.equipment`)(group[type]) },
                requirements: [checkAccessOrUpgradable('equipment'), check('equipment:manage')]
            }
        },
        group => {
            const { type } = group
            const { name } = group[type]

            return {
                id: `${type}:insights`,
                category: 'insights',
                label: formatMessage({
                    id: 'insights_action_navigate_entity',
                    defaultMessage: 'Go to {pname} insights'
                }, { pname: possessify(name) }),
                keywords: [formatMessage({
                    id: 'noun_insights',
                    defaultMessage: 'Insights'
                })],
                element: 'link',
                link: { to: getUrlFromPath(`path:people.${type}.insights`)(group[type]) },
                requirements: [checkAccessOrUpgradable('insights'), check('insights:manage')] // TODO: Support for unit:insights:manage when/if backend supports it
            }
        }
    ]
}