import React, { forwardRef, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useEntity } from 'contexts/entity'
import { useHandbookTopicTemplate } from 'contexts/handbook-topic-template'
import { pick } from 'utilities/object'
import { Content } from './s'
import {
    Symbol,
    EditorContent,
    Label,
    MetaWrapper, Meta
} from 'pages/handbook/modals/topic/view/s'
import DrawablePanel from 'components/drawable-panel'
import { H1 as Title } from 'components/typography/heading'
import TiptapOutput from 'components/tiptap/output'
import UsefulLinks from 'components/useful-links'
import { Tags } from 'components/tags'
import Divider from 'components/divider'
import RelativeTime from 'components/time-relative'
import { InlineMessage } from 'components/message'
import { Plain } from 'components/button'
import { Scrollable as Modal } from 'modals/generic'
import ReplacePrompt from './replace-prompt'
import { Clock } from 'styled-icons/feather'
import { blankSymbol } from 'pages/handbook'

const ConnectedTemplate = forwardRef(({ topic, fields, salt, ...props }, forwardedRef) => {
    const { formatMessage } = useIntl()

    const { updateTopic } = useEntity()
    const { template } = useHandbookTopicTemplate()

    const [showingWarning, setShowingWarning] = useState(false)
    const [updating, setUpdating] = useState(false)

    const {
        symbol = blankSymbol,
        title = '',
        content = [],
        links = [],
        tags = []
    } = template ?? {}

    if(!template) {
        return null
    }

    const hasUpdates =
        !!topic.updatedDate && !!template.updatedDate &&
        new Date(template.updatedDate) > new Date(topic.updatedDate)

    const replaceTopic = (values = []) => {
        setUpdating(true)

        for(const field of values) {
            fields[field]?.update?.(template[field])
        }

        updateTopic({
            ...pick(template, ...values),
            id: topic.id
        }, true)

        setUpdating(false)
    }

    const dateFormat = formatMessage({
        id: 'date_fns_format_full_friendly',
        defaultMessage: `PPP 'at' p`
    })

    return (
        <div ref={forwardedRef}>
            <DrawablePanel
                {...props}
                defer={true}
                heading={formatMessage({
                    id: 'handbooks_topic_connected_template',
                    defaultMessage: 'Connected template'
                })}>
                <Content>
                    <Symbol
                        symbol={symbol}
                        size={56} />
                    <Title>{title}</Title>
                    <TiptapOutput
                        content={content}
                        element={EditorContent}
                        dependencies={[topic.updatedDate]} />
                    <UsefulLinks links={links} />
                    {!!tags?.length && (
                        <>
                            <Label>
                                <FormattedMessage
                                    id="noun_tags"
                                    defaultMessage="Tags" />
                            </Label>
                            <Tags tags={tags.map(tag => ({
                                id: tag,
                                name: tag
                            }))} />
                        </>
                    )}
                    <Divider $size={24} />
                    <MetaWrapper>
                        {template.updatedDate && (
                            <Meta>
                                <Clock size={16} />
                                <RelativeTime
                                    date={template.updatedDate}
                                    format={dateFormat}
                                    short={false} />
                            </Meta>
                        )}
                    </MetaWrapper>
                </Content>
                {hasUpdates && (
                    <InlineMessage
                        type="warning"
                        message={formatMessage({
                            id: 'handbooks_topic_message_template_updates',
                            defaultMessage: 'The template this topic is connected to has been updated since this topic was last edited.'
                        })} />
                )}
                <Plain
                    className={`constructive${updating ? ' loading' : ''}`}
                    onClick={() => setShowingWarning(true)}
                    disabled={updating}>
                    <FormattedMessage
                        id="handbooks_topic_action_select_replace_options"
                        defaultMessage="Select what to replace…" />
                </Plain>
            </DrawablePanel>
            <Modal
                show={showingWarning}
                dismiss={() => setShowingWarning(false)}
                salt={salt}>
                <ReplacePrompt
                    dismiss={() => setShowingWarning(false)}
                    updating={updating}
                    replaceTopic={replaceTopic}
                    salt={salt} />
            </Modal>
        </div>
    )
})

export default ConnectedTemplate
