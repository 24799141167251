import React, { memo } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useProcessTemplate } from 'contexts/process-template'
import { useOrganization } from 'contexts/organization'
import { getEmojiByType, getReferenceDateLabelTranslation } from 'pages/processes/utilities'
import { titleTranslationGetter } from 'pages/processes/pages/process/task-groups/group/title'
import { getOffsetNegativeLabelTranslation, getOffsetPositiveLabelTranslation } from 'pages/processes/pages/template/edit-task'
import { Title, Emoji, Columns, Misty, PlainSmall } from './s'
import StandaloneDueDatePicker from './standalone'

const TaskGroupTitle = ({ dueAtOffsetDays: dueAt, placeholder, when, tasks, salt }) => {
    const { formatMessage } = useIntl()

    const {
        type,
        updateTask
    } = useProcessTemplate()

    const { organization } = useOrganization()

    const bulkUpdateTaskDueAtOffsetDays = async dueAtOffsetDays => {
        if(dueAt === dueAtOffsetDays) {
            return
        }

        await Promise.all(tasks.map(({ id }) => updateTask({ body: { dueAtOffsetDays }, id })))
    }

    if((!placeholder && when !== 'undecided' && !dueAt && dueAt !== 0) || !organization) {
        return null
    }

    const getTitleTranslation = titleTranslationGetter(type, {
        misty: chunks => <Misty className="compact">{chunks}</Misty>,
        organization: organization.name
    })

    if(placeholder) {
        return (
            <>
                {(when === 'on') && (
                    <Title className="on">
                        <Emoji>
                            {getEmojiByType(type)}
                        </Emoji>
                        <FormattedMessage {...getTitleTranslation('on')} />
                    </Title>
                )}
                {(when !== 'on') && (
                    <Title>
                        <FormattedMessage {...getTitleTranslation(when)} />
                    </Title>
                )}
            </>
        )
    }

    return (
        <Title {...(when === 'on' ? { className: 'on' } : null)}>
            {(when === 'on') && (
                <Emoji>
                    {getEmojiByType(type)}
                </Emoji>
            )}
            <Columns>
                <span>
                    {(when === 'undecided') && (
                        <FormattedMessage
                            id="employee_onboarding_no_due_date"
                            defaultMessage="No due date" />
                    )}
                    {(when === 'on') && <Misty className="compact">{' · '}<FormattedMessage {...getTitleTranslation('on')} /></Misty>}
                    {['before', 'after'].includes(when) &&
                        <FormattedMessage
                            {...getTitleOffsetTranslation(type, when)}
                            values={{
                                misty: chunks => <Misty className="compact">{chunks}</Misty>,
                                count: Math.abs(dueAt)
                            }} />
                    }
                </span>
                <span>
                    <StandaloneDueDatePicker
                        trigger={(
                            <PlainSmall className="constructive">
                                <FormattedMessage
                                    id="action_edit"
                                    defaultMessage="Edit" />
                            </PlainSmall>
                        )}
                        onChange={bulkUpdateTaskDueAtOffsetDays}
                        offset={dueAt}
                        message={{
                            type: 'info',
                            message: formatMessage({
                                id: 'tasks_info_edit_due_date_count',
                                defaultMessage: '{count, plural, =0 {} =1 {Editing due date for <strong>one task<\/strong>} other {Editing due date for <strong>{count} tasks<\/strong>}}'
                            }, { count: tasks.length })
                        }}
                        optionLabelFormatters={{
                            negative: () => formatMessage(
                                getOffsetNegativeLabelTranslation(type),
                                { accent: chunks => <strong>{chunks}</strong> }
                            ),
                            neutral: () => formatMessage(getReferenceDateLabelTranslation(type)),
                            positive: () => formatMessage(
                                getOffsetPositiveLabelTranslation(type),
                                { accent: chunks => <strong>{chunks}</strong> }
                            )
                        }}
                        salt={`${salt}:change-time`} />
                </span>
            </Columns>
        </Title>
    )
}

export const getTitleOffsetTranslation = (type, when) => {
    if(type === 'onboarding') {
        if(when === 'before') {
            return {
                id: 'employee_onboarding_template_heading_first_workday_before_count',
                defaultMessage: '{count, plural, =0 {} =1 {1 day before <misty>first work day</misty>} other {{count} days before <misty>first work day</misty>}}'
            }
        }

        if(when === 'after') {
            return {
                id: 'employee_onboarding_template_heading_first_workday_after_count',
                defaultMessage: '{count, plural, =0 {} =1 {1 day after <misty>first work day</misty>} other {{count} days after <misty>first work day</misty>}}'
            }
        }
    }

    if(type === 'offboarding') {
        if(when === 'before') {
            return {
                id: 'employee_offboarding_template_heading_last_workday_before_count',
                defaultMessage: '{count, plural, =0 {} =1 {1 day before <misty>last work day</misty>} other {{count} days before <misty>last work day</misty>}}'
            }
        }

        if(when === 'after') {
            return {
                id: 'employee_offboarding_template_heading_last_workday_after_count',
                defaultMessage: '{count, plural, =0 {} =1 {1 day after <misty>last work day</misty>} other {{count} days after <misty>last work day</misty>}}'
            }
        }
    }

    if(type === 'process') {
        if(when === 'before') {
            return {
                id: 'processes_template_heading_reference_date_before_count',
                defaultMessage: '{count, plural, =0 {} =1 {1 day before <misty>the reference date</misty>} other {{count} days before <misty>the reference date</misty>}}'
            }
        }

        if(when === 'after') {
            return {
                id: 'processes_template_heading_reference_date_after_count',
                defaultMessage: '{count, plural, =0 {} =1 {1 day after <misty>the reference date</misty>} other {{count} days after <misty>the reference date</misty>}}'
            }
        }
    }
}

export default memo(TaskGroupTitle)