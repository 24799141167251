import React, { Component, createContext, createRef, useContext } from 'react'
import { usePerson } from 'contexts/person'
import { get, post, patch, remove } from 'api'
import { compact } from 'utilities/array'
import { subDays } from 'date-fns'
import { isofy } from 'utilities/date-time'

const SalariesContext = createContext()
SalariesContext.displayName = 'Salaries'

class SalariesProvider extends Component {
    constructor(props) {
        super(props)

        this.flash = createRef()

        this.state = {
            salaries: compact([props.personContext.person?.salary?.value]),
            total: 0,
            ...this.getAccess(),

            flash: this.flash,
            clearFlash: this.clearFlash,

            fetchSalaries: this.fetch,
            addSalary: this.add,
            updateSalary: this.update,
            removeSalary: this.remove,

            error: null,
            resetError: () => this.setError(null),

            fetching: false,
            hasFetched: false
        }
    }

    componentDidUpdate(props, { viewable, editable }) {
        const access = this.getAccess()

        const viewableChanged = access?.viewable !== viewable
        const editableChanged = access?.editable !== editable

        if(viewableChanged || editableChanged) {
            this.setState(access)
        }
    }

    fetch = async () => {
        const { hasFetched } = this.state

        if(hasFetched) {
            return
        }

        this.setState({ fetching: true })

        const { ok, response } = await get({ path: `/users/${this.props.userId}/salaries` })

        if(ok && response) {
            this.setState({
                salaries: response.items,
                total: response.total,
                fetching: false,
                hasFetched: true
            })
        } else {
            this.setState({
                fetching: false,
                hasFetched: true
            })
        }
    }

    updateCurrent = async () => {
        const { response } = await get({ path: `/users/${this.props.userId}/salaries/current` })

        this.props.personContext.updatePersonLocally(response ?? {}, 'salary')
    }

    add = async body => {
        const { ok, response: salary } = await post({
            path: `/users/${this.props.userId}/salaries`,
            body
        })

        !ok && this.setError(salary)

        if(ok && salary) {
            this.flash.current = salary

            this.setState(({ salaries: previousSalaries }) => {
                const salaries = cleanUpSalaries(salary, previousSalaries)

                return {
                    salaries,
                    total: salaries.length
                }
            })

            this.updateCurrent()
        }

        return { ok, response: salary }
    }

    update = async (body, salaryId) => {
        const { ok, response: salary } = await patch({
            path: `/users/${this.props.userId}/salaries/${salaryId}`,
            body
        })

        !ok && this.setError(salary)

        if(ok && salary) {
            this.flash.current = salary

            this.setState(({ salaries: previousSalaries }) => {
                const updatedSalaries = previousSalaries.map(previousSalary => {
                    if(previousSalary.id === salaryId) {
                        return salary
                    }

                    return previousSalary
                })

                const salaries = cleanUpSalaries(salary, updatedSalaries)

                return { salaries }
            })

            this.updateCurrent()
        }

        return { ok, response: salary }
    }

    remove = async salaryId => {
        const { ok } = await remove({
            path: `/users/${this.props.userId}/salaries/${salaryId}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ salaries: previousSalaries }) => {
                const salaries = previousSalaries.filter(({ id }) => id !== salaryId)

                return {
                    salaries,
                    total: salaries.length
                }
            })

            this.updateCurrent()
        }

        return { ok }
    }

    setError = data => {
        let error = null

        if(data) {
            const {
                values,
                fields,
                errorCode,
                errorMessage
            } = data

            error = {
                errorType: errorCode.replace('field:', '').replaceAll('-', '_'),
                errorMessage,
                records: values,
                fields
            }
        }

        this.setState({ error })
    }

    getAccess = () => {
        const { viewable, editable } = this.props.personContext.person?.salaries ?? {}

        return {
            viewable,
            editable
        }
    }

    clearFlash = () => this.setState(({ salaries }) => {
        this.flash.current = null

        return {
            salaries: [...salaries]
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <SalariesContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </SalariesContext.Provider>
        )
    }
}

const sortByDate = (a, b) => {
    const aDate = new Date(a.fromDate)
    const bDate = new Date(b.fromDate)

    return aDate > bDate ? -1 : 1
}

const cleanUpSalaries = (newSalary, salaries) => {
    const newSalaryFromDate = new Date(newSalary.fromDate)
    const newSalaryToDate = newSalary.toDate ? new Date(newSalary.toDate) : Infinity

    // Set previously current salary to not current if the new salary is current
    if(!!newSalary?.current) {
        salaries = salaries.map(salary => ({
            ...salary,
            current: false
        }))
    }

    // Update existing salaries based on the new salary's date
    const newSalaries = salaries.map(salary => {
        const salaryFromDate = new Date(salary.fromDate)
        const salaryToDate = salary.toDate ? new Date(salary.toDate) : Infinity

        // Check if the existing salary overlaps with the new salary
        if(salaryFromDate <= newSalaryToDate && salaryToDate >= newSalaryFromDate) {
            if(salaryFromDate < newSalaryFromDate) {
                return {
                    ...salary,
                    toDate: isofy(subDays(newSalaryFromDate, 1))
                }
            } else {
                // This means that the existing salary is completely covered by the new salary
                return null
            }
        }

        return salary
    }).filter(Boolean)

    // Append the new salary …
    newSalaries.push(newSalary)

    // … and sort
    return newSalaries.sort(sortByDate)
}

export const useSalaries = () => useContext(SalariesContext)

export default props => {
    const personContext = usePerson()

    return (
        <SalariesProvider
            {...props}
            personContext={personContext} />
    )
}