import styled from 'styled-components'
import { fromTablet, belowTablet } from 'utilities/styled'
import { Grid } from 'components/grid'
import { H3 } from 'components/typography/heading'
import Paragraph from 'components/typography/paragraph'
import ShortcutBase from 'components/tiptap/help/shortcut'

export const Wrapper = styled.div`
    scroll-snap-align: start;

    flex: 0 0 100%;

    margin-inline: auto;
    width: 100%;
    padding: 32px;

    ${fromTablet`
        max-width: var(--huma-global-content-tight-width);
    `}

    ${belowTablet`
        overflow-y: auto;
    `}
`

export const TitleWrapper = styled.div`
    display: flex;

    ${fromTablet`
        align-items: end;

        min-height: 160px;
    `}
`

export const OrdinalAndTitle = styled(Grid)`
    margin-block-end: 8px;

    ${fromTablet`
        --__huma-component-survey-question-ordinal-hanging: 40px;
        --__huma-component-survey-question-ordinal-gap: 24px;

        grid-template-columns: var(--__huma-component-survey-question-ordinal-hanging) 1fr;
        column-gap: var(--__huma-component-survey-question-ordinal-gap);

        margin-inline-start: calc(
            (-1 * var(--__huma-component-survey-question-ordinal-gap)) +
            (-1 * var(--__huma-component-survey-question-ordinal-hanging))
        );
    `}
`

export const Ordinal = styled.p`
    display: grid;
    place-items: center;
    width: 32px;
    aspect-ratio: 1;
    color: var(--huma-color-foreground-constructive-bold);
    background-color: var(--huma-color-surface-constructive-minimal);
    border-radius: 50%;
    margin-inline: auto;
    transition: all .1s ease-in-out;

    &.error {
        color: var(--huma-color-foreground-destructive-bold);
        background-color: var(--huma-color-surface-destructive-minimal);
    }

    &.inactive {
        color: var(--huma-color-foreground-disabled);
        background-color: var(--huma-color-surface-disabled);
    }

    ${belowTablet`
        display: none;
    `}
`

export const Title = styled(H3)`
    line-height: 1.4;
    margin-block: 2px 0;

    @supports (text-wrap: stable) {
        text-wrap: stable;
    }

    @supports (text-wrap: pretty) {
        text-wrap: pretty;
    }
`

export const Field = styled.div`
    margin-block-start: 40px;
`

export const ShortcutParagraph = styled(Paragraph)`
    margin-block-start: 8px;
`

export const Shortcut = styled(ShortcutBase)`
    display: inline-grid;

    line-height: 1;
`

export const Footer = styled(Grid)`
    grid-template-columns: 1fr;
    grid-auto-flow: column;
    grid-auto-columns: min-content;
    padding-block-start: 24px;

    &.only-reset {
        justify-items: end;
    }
`