import { useIntl } from 'react-intl'
import Slugify from 'modules/@sindresorhus/slugify'
import { getRandomInteger } from 'utilities/number'

const cipher = ['⍟', '⍣', '⍒', '⍥', '⍦', '⍝', '⍚', '⍤', '⍕', '⍨', '⍖', '⍝', '+', '*', '@', '#']

export const capitalize = string => (typeof string === 'string') ?
    `${string.charAt(0).toUpperCase()}${string.slice(1)}` :
    ''

export const lowerize = string => (typeof string === 'string') ?
    `${string.charAt(0).toLowerCase()}${string.slice(1)}` :
    ''

export const sentenceCase = string => {
    const output = string.replace(/([A-Z])/g, ' $1')

    return output.charAt(0).toUpperCase() + output.slice(1)
}

export const scramble = (length = null) => {
    if(!length) {
        length = getRandomInteger(8, 24)
    }

    return new Array(length)
        .fill()
        .map(() => cipher[getRandomInteger(0, cipher.length - 1)])
        .join('')
}

export const prettifyUrl = url => url
    .replace(/(^\w+:|^)\/\//, '')
    .replace(/\/$/, '')

export const shorten = (string, maxChars, overflow = '…') => {
    if(!maxChars) {
        return string
    }

    if(string?.length > maxChars) {
        return `${string.substr(0, maxChars)}${overflow}`
    }

    return string
}

export const slugify = (text, options = {}) => Slugify(text, {
    decamelize: false,
    customReplacements: [
        ['\'', ''],
        ['‘', ''],
        ['’', ''],
        ['"', ''],
        ['“', ''],
        ['”', ''],
        ['‹', ''],
        ['«', ''],
        ['›', ''],
        ['»', '']
    ],
    ...options
})

export const normalize = (string, options = {}) => {
    if(typeof string !== 'string') {
        return string
    }

    const { emoji = true } = options ?? {}

    string = string
        .split('')
        .map(letter => normalizedProperLetterMap?.[letter] ?? letter)
        .join('')
        .normalize('NFKD')
        .replace(/[\u0300-\u036f]/g, '')

    if(emoji) {
        string = string.replace(/[^\x1F-\x7F]+/g, '') // eslint-disable-line no-control-regex
    }

    return string.trim()
}

export const camelize = string => {
    if(typeof string !== 'string') {
        return string
    }

    return normalize(string)
        .split(/[\s-]/g)
        .map((word, index) => (index === 0) ?
            word.toLowerCase() :
            capitalize(word)
        )
        .join('')
}

const uncamelize = (join = ' ') => string => {
    if(typeof string !== 'string') {
        return string
    }

    return string.replace(
        /[A-Z]+(?![a-z])|[A-Z]/g,
        ($, ofs) => `${ofs ? join : ''}${$.toLowerCase()}`
    )
}

// Only for camelCase to kebab-case
export const titleize = string => capitalize(uncamelize()(string))

// Only for camelCase to kebab-case
export const kebabize = uncamelize('-')

const normalizedProperLetterMap = {
    'Æ': 'A',
    'Ð': 'D',
    'Ø': 'O',
    'æ': 'a',
    'ð': 'd',
    'ø': 'o',
    'Đ': 'D',
    'đ': 'd',
    'Ħ': 'H',
    'ħ': 'h',
    'ı': 'i',
    'Ŀ': 'L',
    'ŀ': 'l',
    'Ł': 'l',
    'ł': 'l',
    'ŉ': 'n',
    'Œ': 'O',
    'œ': 'o',
    'Ŧ': 'T',
    'ŧ': 't',
    'ƒ': 'f',
    'Ǽ': 'A',
    'ǽ': 'a',
    'Ǿ': 'O',
    'ǿ': 'o',
    'Þ': 'TH',
    'þ': 'th',
    'Ɛ̧': 'E',
    'ɛ̧': 'e',
    'Ɨ̧': 'I',
    'ɨ̧': 'i'
}

export const useQuote = () => {
    const { formatMessage } = useIntl()

    return text => [
        formatMessage({
            id: 'quotation_level_1_start',
            defaultMessage: '“'
        }),
        text,
        formatMessage({
            id: 'quotation_level_1_end',
            defaultMessage: '”'
        })
    ].join('')
}

export const useLocalizedAlphabet = () => {
    const { locale } = useIntl()

    return 'abcdefghijklmnopqrstuvwxyz'
        .split('')
        .sort((one, two) => one.localeCompare(two, locale, { sensitivity: 'base' }))
}