import React from 'react'
import { useIntl } from 'react-intl'
import { compact } from 'utilities/array'
import StatisticsGroup from 'components/statistics-group-modern'

const PreviewProcessTemplateStatistics = ({ template }) => {
    const { formatMessage } = useIntl()

    if(!template?.taskCount) {
        return null
    }

    return (
        <StatisticsGroup
            items={compact([
                {
                    value: template.taskCount,
                    content: formatMessage({
                        id: 'employee_onboarding_template_statistic_tasks_total',
                        defaultMessage: '{total, plural, =0 {tasks in total} =1 {task in total} other {tasks in total}}'
                    }, { total: template.taskCount })
                },
                template.timeSpanDays && {
                    value: template.timeSpanDays,
                    content: formatMessage({
                        id: 'employee_onboarding_template_statistic_days_total',
                        defaultMessage: '{total, plural, =0 {days in total} =1 {day in total} other {days in total}}'
                    }, { total: template.timeSpanDays })
                }
            ])}
            className="centered tighter compact" />
    )
}

export default PreviewProcessTemplateStatistics