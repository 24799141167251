export const surveyLabels = {
    draft: {
        id: 'survey_status_draft',
        defaultMessage: 'Draft'
    },
    published: {
        id: 'survey_status_published',
        defaultMessage: 'Published'
    },
    active: {
        id: 'survey_status_active',
        defaultMessage: 'Active'
    },
    paused: {
        id: 'survey_status_paused',
        defaultMessage: 'Paused'
    },
    completed: {
        id: 'survey_status_completed',
        defaultMessage: 'Completed'
    }
}

export const surveyClassNames = {
    draft: 'neutral',
    published: 'active',
    active: 'active',
    paused: 'passive',
    completed: 'positive'
}

export const surveyFrequencyShortcutValues = [
    {
        value: 1,
        unit: 'years'
    },
    {
        value: 6,
        unit: 'months'
    },
    {
        value: 3,
        unit: 'months'
    },
    {
        value: 1,
        unit: 'months'
    },
    {
        value: 2,
        unit: 'weeks'
    },
    {
        value: 1,
        unit: 'weeks'
    }
]