import React, { Component, createContext, useState, useEffect, useContext } from 'react'
import { get } from 'api'
import { useEnvironment } from 'contexts/environment'
import { useSegment } from 'contexts/segment'
import { useI18n } from 'contexts/i18n'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import paths from 'app/paths'
import PubSub from 'pubsub-js'
import debounce from 'lodash.debounce'
import { getChannel } from 'utilities/broadcaster'
import { size, omit, pick, compact as compactObject } from 'utilities/object'
import { compact } from 'utilities/array'
import { getIntegrationUrl } from 'utilities/url'
import { getData, anonymize } from 'utilities/jwt'
import { local, session } from 'utilities/storage'
import { requestAccess, storeToken } from 'utilities/auth'
import { identify as hubspotIdentify } from 'utilities/hubspot'
import { createLogger } from 'utilities/rapid7-insight-ops'
import { httpOrHttpsPattern } from 'utilities/regex'

const authLogger = createLogger('log', 'AUDIT:')

const AuthContext = createContext()
AuthContext.displayName = 'Auth'

class AuthProvider extends Component {
    constructor(props) {
        super(props)

        const { hostname } = global.location

        let key = hostname.split('.')[0]
        let keyPrefilled = true
        const previouslyUsedKey = local.get('login:key')

        if(key === 'localhost' || hostname.endsWith('.herokuapp.com')) {
            key = previouslyUsedKey ?? ''
            keyPrefilled = !!previouslyUsedKey
        }

        if(key === 'my') {
            key = ''
            keyPrefilled = false
        }

        this.state = {
            status: !!props.authorized ? 'authorized' : null,
            defaultPlanCode: null,
            registrationEnabled: true,
            native: this.getNative(),

            ...this.getInitialReferralState(),

            key,
            keyPrefilled,
            previouslyUsedKey,

            setReferralState: this.setReferralState,

            onSignIn: this.onSignIn,
            onSignOut: this.onSignOut
        }

        this.onSignOutDebounced = debounce(this.onSignOut, 1000, { leading: true, trailing: false })
        PubSub.subscribe('auth.signout', (_, ...args) => this.onSignOutDebounced(...args))

        this.syncer = getChannel('auth')
    }

    componentDidMount() {
        const {
            status,
            native,
            key,
            integration
        } = this.state

        if(status === 'authorized') {
            if(integration) {
                this.getIntegrationRedirecter()()
            }

            return
        }

        this.getSignupConfig()

        const { location: { pathname } } = this.props

        if(pathname === '/') {
            if(!!native.initial || !!native.session || key) {
                return void this.props.navigate(paths.root, { replace: true })
            }
        }

        this.syncer.onmessage = ({ data }) => void this.setState(data)
    }

    componentWillUnmount() {
        PubSub.unsubscribe('auth')
        this.syncer.close()
    }

    onSignIn = ({ accessToken, refresh_token, key, name, locale, signup = false }) => {
        const allow = [
            'web',
            'android',
            'android-new',
            'ios'
        ]

        const {
            referrer,
            integration,
            native: { platform }
        } = this.state

        if(!allow.includes(platform)) {
            return
        }

        const {
            protocol,
            host,
            hostname
        } = global.location

        const tokens = {
            accessToken,
            refresh_token
        }

        if(platform === 'android') {
            global.location = queryString.stringifyUrl({
                url: `${protocol}//app.${host.split('.').slice(1).join('.')}${paths.relinquish}`,
                query: tokens
            })
        }

        if(platform === 'android-new') {
            const { stage } = this.props.environment.environment

            const scheme = compact([
                'com',
                'huma',
                'app',
                (stage !== 'prod') && stage
            ]).join('.')

            global.location = queryString.stringifyUrl({
                url: `${scheme}://${paths.relinquish}`,
                query: tokens
            })
        }

        if(platform === 'ios') {
            global.location = queryString.stringifyUrl({
                url: `humauth://${paths.relinquish}`,
                query: tokens
            })
        }

        if(platform !== 'web') {
            return
        }

        if(key && hostname !== 'localhost' && !hostname.endsWith('.herokuapp.com')) {
            const path = signup ?
                paths.signup :
                paths.root

            global.location = queryString.stringifyUrl({
                url: `${protocol}//${key}.${host.split('.').slice(1).join('.')}${path}`,
                query: {
                    arrived: JSON.stringify({
                        ...tokens,
                        ...(signup ? { locale } : { referrer }),
                        ...(integration ? { integration } : null)
                    })
                }
            })

            return
        }

        const {
            organizationId,
            sub: userId,
            email,
            given_name: given,
            family_name: family
        } = getData(accessToken) ?? {}

        if(signup) {
            if(this.props.environment.integrations?.enableHubspotContactRegistration) {
                hubspotIdentify({
                    id: organizationId,
                    email,
                    huma_webclient_organization_name: name,
                    huma_webclient_signup_givenname: given,
                    huma_webclient_signup_familyname: family,
                    huma_webclient_signup_subdomain: host.split('.')[0],
                    huma_webclient_signup_locale: locale
                })
            }

            this.props.segment.track('Registered', { category: 'account' })
        }

        local.set('ids', {
            organization: organizationId,
            user: userId
        })

        authLogger({
            source: 'web',
            event: `auth-${signup ? 'signup' : 'login'}`,
            orgId: organizationId,
            userId,
            newAccessToken: anonymize(accessToken),
            newRefreshToken: anonymize(refresh_token)
        })

        storeToken('accessToken', accessToken)
        storeToken('refresh_token', refresh_token)

        this.props.i18n.fetchSettings()

        let redirect = () => this.props.navigate(paths.root, { replace: true })

        if(integration) {
            redirect = this.getIntegrationRedirecter()
        } else if(referrer && ![paths.login, paths.signup].includes(referrer) && !signup) {
            redirect = () => this.props.navigate(referrer, { replace: true })
        }

        const state = { status: 'authorized' }

        this.setState(state, () => {
            redirect()
            this.syncer.postMessage(state)
        })
    }

    onSignOut = async (options = {}) => {
        this.syncer.postMessage({ status: null })

        if(this.state.status !== 'authorized') {
            return
        }

        const {
            path = paths.root,
            userInitiated = false
        } = options

        this.props.segment.reset()

        const ids = local.remove('ids')
        const accessToken = anonymize(local.remove('accessToken'))
        const refreshToken = anonymize(local.remove('refresh_token'))

        authLogger({
            source: 'web',
            event: 'auth-logout',
            orgId: ids?.organization,
            userId: ids?.user,
            accessToken,
            refreshToken,
            userInitiated
        })

        local.remove('account')
        local.prune()

        session.flush()

        this.props.i18n.fetchSettings()

        // Fetching the environment unmounts this AuthProvider,
        // and mounts it anew upon a successful request.
        // In other words: there’s no need to explicitly reset its
        // state – in fact, it just causes errors and complaints.
        await this.props.environment.fetch(true)

        if(httpOrHttpsPattern.test(path)) {
            global.location = path
        } else {
            this.props.navigate(path, { replace: true })
        }
    }

    getSignupConfig = async () => {
        const { response, ok } = await get({ path: '/config/signup' })

        if(ok && response) {
            this.setState(response)
        }
    }

    getNative() {
        const {
            native: initial = null,
            state = '{}'
        } = queryString.parse(global.location.search)

        const native = {
            initial,
            redirect: JSON.parse(state)?.native ?? null,
            session: session.get('native')
        }

        const [platform] = compact([...Object.values(native), 'web'])

        if(!native.session && platform !== 'web') {
            session.set('native', platform)
        }

        return {
            ...native,
            platform
        }
    }

    getInitialReferralState = () => {
        const deny = [
            'email-verification',
            'survey-response',
            'accessToken'
        ]

        const {
            pathname: url,
            search
        } = this.props.location ?? {}

        let query = {}
        let integration = null

        if(!!search) {
            query = omit(queryString.parse(search), ...deny)

            const state = query.state ?
                JSON.parse(query.state) :
                null

            const arrived = query.arrived ?
                JSON.parse(query.arrived) :
                null

            if(state?.integration) {
                integration = state.integration
            } else if(arrived?.integration) {
                integration = arrived.integration
            } else {
                const tripletexQueryParams = pick(query, ...tripletexQueryParamNames)
                if(size(tripletexQueryParams) === tripletexQueryParamNames.length) {
                    integration = {
                        type: 'tripletex',
                        ...compactObject(tripletexQueryParams)
                    }

                    query = omit(query, ...tripletexQueryParamNames)
                }
            }
        }

        // Clean up the current URL immediately
        this.props.navigate(
            queryString.stringifyUrl({
                url: global.location.pathname,
                query
            }),
            { replace: true }
        )

        return compactObject({
            referrer: queryString.stringifyUrl({ url, query }),
            integration
        })
    }

    setReferralState = (referralState, callback) => void this.setState({
        referrer: null,
        integration: null,
        ...compactObject(omit(referralState, 'accessToken'))
    }, callback?.())

    getIntegrationRedirecter = (integration = this.state.integration) => {
        const url = queryString.stringifyUrl({
            url: getIntegrationUrl(integration),
            query: {
                intent: 'connect',
                ...omit(integration, 'type')
            }
        })

        // Clean up the current URL immediately
        this.props.navigate(global.location.pathname, { replace: true })

        return () => void this.props.navigate(url, { replace: true })
    }

    render() {
        const { children = null } = this.props

        return (
            <AuthContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </AuthContext.Provider>
        )
    }
}

export const tripletexQueryParamNames = ['tripletex', 'companyId', 'companyName', 'orgNumber', 'token']

export default props => {
    const environment = useEnvironment()
    const segment = useSegment()
    const i18n = useI18n()
    const location = useLocation()
    const navigate = useNavigate()

    const [authorized, setAuthorized] = useState(null)
    const [hasChecked, setHasChecked] = useState(false)

    useEffect(() => {
        (async () => {
            const { ok: tokensOk, response: tokens } = await requestAccess({ bounce: false })
            setAuthorized(tokensOk && !!tokens?.accessToken)
            setHasChecked(true)
        })()
    }, [])

    if(!hasChecked) {
        return null
    }

    return (
        <AuthProvider
            {...props}
            environment={environment}
            segment={segment}
            i18n={i18n}
            location={location}
            navigate={navigate}
            authorized={authorized} />
    )
}

export const useAuth = () => useContext(AuthContext)