import React, { Component, createContext, useContext, createRef } from 'react'
import { get, post, patch, remove } from 'api'
import { intersectionObserver } from 'utilities/dom'
import { local } from 'utilities/storage'
import debounce from 'lodash.debounce'
import PubSub from 'pubsub-js'
import { v4 as uuid } from 'uuid'
import isEqual from 'react-fast-compare'
import { size, reduce, withoutEmptyArrays } from 'utilities/object'

const CompetenceRecordsContext = createContext()
CompetenceRecordsContext.displayName = 'CompetenceRecords'

export default class CompetenceRecordsProvider extends Component {
    constructor(props) {
        super(props)

        !!props?.types && this.setTypes(props.types, false)

        const { eternal = true } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        this.intersectionObserver = intersectionObserver(this.onIntersect)
        this.sortCacheKey = props?.sortCacheKey

        let sorting = sortingDefaults()
        if(this.sortCacheKey) {
            const cachedSorting = local.get(this.sortCacheKey)
            if(cachedSorting) {
                sorting = cachedSorting
            }
        }

        this.pagingDefaults = pagingDefaults(props?.paging)

        this.flash = createRef()

        this.uuid = uuid()

        this.state = {
            records: [],
            filter: props?.filter ?? {},
            sorting,
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            flash: this.flash,
            clearFlash: this.clearFlash,

            fetch: this.fetch,
            fetchRecord: this.fetchRecord,
            addRecord: this.add,
            updateRecord: this.update,
            updateRecordLocally: this.updateLocally,
            pushRecord: this.push,
            removeRecord: this.remove,
            setRecordsFilter: this.setFilter,
            toggleSorting: this.toggleSorting,
            setTypes: this.setTypes,

            hasFetched: false,
            fetchError: false,
            autoFetch: false,
            fetching: false,
            loading: false
        }

        this.subscription = PubSub.subscribe('competenceRecords.refresh', (_, uuid) => {
            if(this.uuid !== uuid) {
                this.setState({
                    records: [],
                    sorting: sortingDefaults(),
                    paging: this.pagingDefaults(),
                    hasFetched: false,
                    autoFetch: false
                }, this.fetchDebounced)
            }
        })
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetchDebounced()
    }

    componentDidUpdate({ paging }, { filter, sorting }) {
        const pagingChanged = !isEqual(paging, this.props.paging)
        const filterChanged = !isEqual(filter, this.state.filter)
        const sortingChanged = !isEqual(sorting, this.state.sorting)

        const state = {}

        if(pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props.paging)
            state.paging = this.pagingDefaults()
        }

        this.setState(size(state) ? state : null, () => {
            if(filterChanged || sortingChanged) {
                this.fetchDebounced(true)
            }
        })
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver.destroy()
        PubSub.unsubscribe(this.subscription)
    }

    fetch = async (force = false) => {
        const {
            fetching,
            filter,
            sorting,
            paging,
            autoFetch,
            hasFetched,
            eternal
        } = this.state

        const { fetchExhaustively = false } = this.props

        if((fetching || (hasFetched && !eternal)) && !force) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            fetchError: false,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetched ? (paging.offset + this.pagingDefaults().limit) : 0,
            limit: paging.limit
        }

        const { response, ok } = fetchExhaustively ?
            await this.fetchExhaustively() :
            await get({
                path: '/competence',
                params: {
                    ...filter,
                    ...nextPaging,
                    ...(this.types ? { types: this.types } : null),
                    orderBy: sorting.by,
                    orderDirection: sorting.direction
                },
                signal: this.fetchController.signal
            })

        if(ok && response) {
            this.setState(({ records: previousRecords }) => {
                const previousRecordsWithoutFetched = previousRecords.filter(({ id: addedId }) => {
                    return !response.items.find(({ id: itemId }) => addedId === itemId)
                })

                const records = [
                    ...previousRecordsWithoutFetched,
                    ...response.items
                ]

                return {
                    records,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        limit: this.pagingDefaults().limit,
                        hasNextPage: response.items.length && records.length < response.total
                    },
                    hasFetched: true,
                    fetchError: false,
                    autoFetch: hasFetched,
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                hasFetched: true,
                fetchError: true,
                autoFetch: false,
                fetching: false,
                loading: false
            })
        }
    }

    fetchExhaustively = async (records = []) => {
        const { filter, sorting } = this.state

        const { response, ok } = await get({
            path: '/competence',
            params: {
                ...filter,
                ...(this.types ? { types: this.types } : null),
                offset: records.length,
                orderBy: sorting.by,
                orderDirection: sorting.direction
            }
        })

        if(ok && response?.items?.length) {
            records.push(...response.items)

            if(records.length < response.total) {
                return await this.fetchExhaustively(records)
            }
        }

        return {
            ok,
            response: {
                ...response,
                items: records
            }
        }
    }

    fetchRecord = async id => await get({ path: `/competence/${id}` })

    add = async body => {
        const { ok, response: newRecords } = await post({
            path: '/competence/bulk',
            body
        })

        if(ok && newRecords) {
            this.setState(({ records }) => ({
                records: [
                    ...newRecords,
                    ...records
                ]
            }))
        }

        return { ok, response: newRecords }
    }

    update = async (body, id) => {
        const { ok, response: record } = await patch({
            path: `/competence/${id}`,
            body
        })

        if(ok && record) {
            this.setState(({ records }) => {
                const index = records.findIndex(record => record.id === id)

                return {
                    records: [
                        ...records.slice(0, index),
                        record,
                        ...records.slice(index + 1, records.length)
                    ]
                }
            })
        }

        return { ok, response: record }
    }

    updateLocally = (body, recordId) => void this.setState(({ records }) => {
        const index = records.findIndex(({ id }) => id === recordId)

        const record = {
            ...records[index],
            ...body
        }

        this.flash.current = record

        return {
            records: [
                ...records.slice(0, index),
                record,
                ...records.slice(index + 1, records.length)
            ]
        }
    })

    push = record => void this.setState(({ records }) => ({
        records: [
            ...records,
            record
        ]
    }))

    remove = async id => {
        const { ok } = await remove({
            path: `/competence/${id}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ records, paging }) => ({
                records: records.filter(record => record.id !== id),
                paging: {
                    ...paging,
                    offset: paging.offset - 1,
                    limit: paging.limit + 1
                }
            }))
        }

        return { ok }
    }

    setFilter = (filter = {}, sorting = {}) => {
        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }))

        sorting = {
            ...sortingDefaults(),
            ...sorting
        }

        this.setState(({ filter: previousFilter, sorting: previousSorting }) => {
            const filterChanged = !isEqual(
                withoutEmptyArrays(filter),
                withoutEmptyArrays(previousFilter)
            )

            const sortingChanged = !isEqual(sorting, previousSorting)

            if(!filterChanged && !sortingChanged) {
                return null
            }

            const state = {
                records: [],
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }

            if(filterChanged) {
                state.filter = filter
            }

            if(sortingChanged) {
                state.sorting = sorting
                !!this.sortCacheKey && local.set(this.sortCacheKey, sorting)
            }

            return state
        })
    }

    toggleSorting = field => {
        const sortingOptions = getSortingOptions()

        if(field in sortingOptions) {
            this.setState(({ sorting }) => {
                const toggled = {
                    by: field,
                    direction: (sorting.by === field) ?
                        (sorting.direction === 'asc' ? 'desc' : 'asc') :
                        sortingOptions[field]
                }

                !!this.sortCacheKey && local.set(this.sortCacheKey, toggled)

                return {
                    records: [],
                    sorting: toggled,
                    paging: this.pagingDefaults(),
                    hasFetched: false,
                    autoFetch: false
                }
            })
        }
    }

    setTypes = (ids = [], fetch = true) => {
        this.types = ids
        fetch && this.fetch(true)
    }

    onIntersect = () => {
        const {
            eternal,
            loading,
            paging,
            autoFetch
        } = this.state

        if(!eternal) {
            return
        }

        if(!loading && paging.hasNextPage && autoFetch) {
            this.fetchDebounced()
        }
    }

    clearFlash = () => void this.setState(({ records }) => {
        this.flash.current = null

        return {
            records: [...records]
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <CompetenceRecordsContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </CompetenceRecordsContext.Provider>
        )
    }
}

const getSortingOptions = () => ({
    type_name: 'asc',
    valid_to: 'desc',
    belongs_to: 'asc'
})

const sortingDefaults = () => {
    const sortingOptions = getSortingOptions()

    return {
        by: Object.keys(sortingOptions)[0],
        direction: Object.values(sortingOptions)[0]
    }
}

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

export const useCompetenceRecords = () => useContext(CompetenceRecordsContext)