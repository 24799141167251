import React, { Component, createContext, createRef, useContext } from 'react'
import { usePerson } from 'contexts/person'
import { post, put, remove } from 'api'

const ChildrenContext = createContext()
ChildrenContext.displayName = 'Children'

class ChildrenProvider extends Component {
    constructor(props) {
        super(props)

        this.userId = props.userId
        this.flash = createRef()

        this.state = {
            children: props.person?.children?.value ?? [],
            ...this.getAccess(),

            flash: this.flash,
            clearFlash: this.clearFlash,

            addChild: this.add,
            updateChild: this.update,
            removeChild: this.remove
        }
    }

    componentDidUpdate(props, { viewable, editable }) {
        const access = this.getAccess()

        const viewableChanged = access?.viewable !== viewable
        const editableChanged = access?.editable !== editable

        if(viewableChanged || editableChanged) {
            this.setState(access)
        }
    }

    add = async body => {
        const { ok, response: child } = await post({
            path: `/users/${this.userId}/children`,
            body
        })

        if(ok && child) {
            this.flash.current = child

            this.setState(({ children }) => ({
                children: [
                    child,
                    ...children
                ]
            }))
        }

        return { ok, response: child }
    }

    update = async (body, childId) => {
        const { ok, response: child } = await put({
            path: `/users/${this.userId}/children/${childId}`,
            body
        })

        if(ok && child) {
            this.setState(({ children }) => {
                const index = children.findIndex(({ id }) => id === childId)
                this.flash.current = child

                return {
                    children: [
                        ...children.slice(0, index),
                        child,
                        ...children.slice(index + 1, children.length)
                    ]
                }
            })
        }

        return { ok, response: child }
    }

    remove = async childId => {
        const { ok } = await remove({
            path: `/users/${this.userId}/children/${childId}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ children }) => {
                children = children.filter(({ id }) => id !== childId)
                return { children }
            })
        }

        return { ok }
    }

    clearFlash = () => this.setState(({ children }) => {
        this.flash.current = null

        return {
            children: [...children]
        }
    })

    getAccess = () => {
        const field = this.props.person?.children

        const {
            viewable = !!field,
            editable = false
        } = field ?? {}

        return {
            viewable,
            editable
        }
    }

    render() {
        const { children = null } = this.props

        return (
            <ChildrenContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </ChildrenContext.Provider>
        )
    }
}

export const useChildren = () => useContext(ChildrenContext)

export default props => {
    const { person } = usePerson()

    return (
        <ChildrenProvider
            {...props}
            person={person} />
    )
}