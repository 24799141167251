import styled from 'styled-components'
import { svgStroke, belowPhone } from 'utilities/styled'
import Paragraph from 'components/typography/caption'
import { Plain } from 'components/button'

export const Content = styled.div`
    padding-inline-start: 40px;
    padding-bottom: 24px;

    ${belowPhone`
        padding-inline-start: 0;
    `}
`

export const Placeholder = styled(Paragraph)`
    max-width: 696px;
    white-space: pre-wrap;
`

export const Tasks = styled.div`
    margin-top: -24px;
`

export const DrawablePanelButton = styled(Plain)`
    svg {
        ${svgStroke('small')}
        margin-inline-end: 8px;
    }
`