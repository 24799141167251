import { requestAccess, revokeAccess } from 'utilities/auth'
import { refreshPayment } from 'utilities/payment'
import { local } from 'utilities/storage'
import { compact } from 'utilities/array'
import { size } from 'utilities/object'
import { setGlobal } from 'utilities/global'
import queryString from 'query-string'
import isNetworkError from 'is-network-error'

export const getUrl = path => {
    const {
        apiBaseUrl,
        stage
    } = local.get('environment') ?? {}

    const reviewApi = local.get('review-api')

    if(!['test', 'stage', 'demo', 'prod'].includes(stage) && !!reviewApi) {
        if(reviewApi === 'test') {
            return `https://test.api.humahr.com${path}`
        }

        return `https://huma-api-pr-${reviewApi}.herokuapp.com${path}`
    }

    if(apiBaseUrl) {
        return `${apiBaseUrl}${path}`
    }

    return null
}

const api = (method = 'get') => async (options = {}) => {
    let {
        path,
        params,
        body,
        headers = {},
        returnsData = true,
        binary = false,
        signal = null,
        attempt = 0
    } = options

    let url = getUrl(path)
    if(!url) {
        global.location.reload()
    }

    const open = !!opendpoints.filter(opendpoint => {
        if(opendpoint.endsWith('/')) {
            const opendpointWithoutTrailingSlash = `/${compact(opendpoint.split('/')).join('/')}`
            return path.startsWith(opendpointWithoutTrailingSlash)
        }

        return path === opendpoint
    }).length

    const { response: tokens } = await requestAccess({ bounce: !open })

    if(!tokens?.accessToken && !open) {
        return {
            ok: false,
            status: '(Aborted)',
            headers: null,
            response: null
        }
    }

    const accessToken = tokens?.accessToken

    if(!!params && size(params)) {
        url = `${url}?${queryString.stringify(params)}`
    }

    const multipart = body instanceof FormData
    if(body && !multipart) {
        body = JSON.stringify(body)
    }

    let response

    try {
        const { release } = local.get('environment') ?? {}
        const locale = local.get('locale') ?? 'en'

        response = await fetch(url, {
            method,
            mode: 'cors',
            ...(body ? { body } : null),
            headers: {
                ...headers,
                'Huma-Client': 'web',
                'Huma-Client-Language': locale,
                ...(release ? { 'Huma-Client-Version': release } : null),
                'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
                ...(body && !multipart ? { 'Content-Type': 'application/json' } : null),
                ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : null)
            },
            signal
        })
    } catch(error) {
        if(isNetworkError(error)) {
            return {
                ok: false,
                status: '(Network error)'
            }
        }
    }

    if(response?.status === 401) {
        if(attempt <= 3) {
            return await api(method)({
                ...options,
                attempt: attempt + 1
            })
        }

        revokeAccess({ bounce: !open })
    }

    const shouldParseResponse = [
        returnsData,
        response && response.type !== 'opaque',
        ![204, 500].includes(response?.status)
    ].every(Boolean)

    const data = shouldParseResponse ?
        await response[binary ? 'blob' : 'json']() :
        null

    if(response?.status === 403 && data?.error_code === 'feature_not_accessible') {
        refreshPayment()
    }

    const {
        ok = false,
        status = '(Aborted)'
    } = response ?? {}

    return {
        ok,
        status,
        headers: makeSenseOfResponseHeaders(response?.headers),
        response: data
    }
}

const opendpoints = [
    '/config/',
    '/auth/',
    '/signup/',
    '/organization/available'
]

export default api

// Method aliases...
export const get = options => api('get')(options)
export const post = options => api('post')(options)
export const put = options => api('put')(options)
export const patch = options => api('PATCH')(options) // Leave uppercased!
export const remove = options => api('delete')(options)

setGlobal('api', {
    get,
    post,
    put,
    patch,
    remove,

    setBaseUrl: apiBaseUrl => {
        local.set('environment', {
            ...local.get('environment'),
            apiBaseUrl
        })
    }
})



const out = (method = 'get') => async (url, options = {}) => {
    let {
        params,
        body,
        headers = {},
        returnsData = true,
        binary = false,
        signal = null,
        ...fetchOptions
    } = options

    if(!!params && size(params)) {
        url = `${url}?${queryString.stringify(params)}`
    }

    const multipart = body instanceof FormData
    if(body && !multipart) {
        body = JSON.stringify(body)
    }

    let response

    try {
        response = await fetch(url, {
            ...fetchOptions,
            method,
            ...(body ? { body } : null),
            headers: {
                ...headers,
                ...(body && !multipart ? { 'Content-Type': 'application/json' } : null)
            },
            signal
        })
    } catch(error) {
        if(isNetworkError(error)) {
            return {
                ok: false,
                status: '(Network error)'
            }
        }
    }

    const shouldParseResponse = [
        returnsData,
        response && response.type !== 'opaque',
        ![204, 403, 404, 500].includes(response?.status)
    ].every(Boolean)

    const data = shouldParseResponse ?
        await response[binary ? 'arrayBuffer' : 'json']() :
        null

    const {
        ok = false,
        status = '(Aborted)'
    } = response ?? {}

    return {
        ok,
        status,
        headers: makeSenseOfResponseHeaders(response?.headers),
        response: data
    }
}

export const outget = (url, options) => out('get')(url, options)
export const outpost = (url, options) => out('post')(url, options)
export const output = (url, options) => out('put')(url, options)
export const outpatch = (url, options) => out('PATCH')(url, options) // Leave uppercased!
export const outremove = (url, options) => out('delete')(url, options)

setGlobal('out', {
    outget,
    outpost,
    output,
    outpatch,
    outremove
})

export const makeSenseOfResponseHeaders = headers => {
    if(!headers) {
        return {}
    }

    headers = (headers instanceof Headers) ?
        headers.entries() :
        Object.entries(headers)

    return [...headers].reduce((accumulator, [key, value]) => ({
        ...accumulator,
        [key]: value
    }), {})
}