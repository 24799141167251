import React, { useState, useEffect, useCallback, useRef, createRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSurvey } from 'contexts/survey'
import { useDirty } from 'contexts/modal'
import { useBreakpoint } from 'hooks/viewport'
import { cls } from 'utilities/dom'
import { getFallbackSymbol } from 'pages/surveys/utilities'
import debounce from 'lodash.debounce'
import { clamp } from 'utilities/math'
import { pick, size } from 'utilities/object'
import { prune } from 'utilities/array'
import {
    Wrapper,
    Header, SymbolAndTitle,
    Layout, Questions, Actions,
    Feedback, FeedbackButton, FeedbackCount
} from './s'
import Symbol from 'components/symbol'
import { Title } from 'components/typography/heading'
import ProgressIndicator from 'components/progress-indicator'
import Question from './question'
import Summary from './summary'
import AnonymousStatus from 'pages/surveys/components/anonymous-status'
import { Plain, Button, ButtonSubmit } from 'components/button'
import { ArrowLeft } from 'styled-icons/feather'

const RespondSurveyQuestions = ({
    started, answerable,
    currentQuestionIndex, setCurrentQuestionIndex,
    reachedQuestionIndex, setReachedQuestionIndex,
    visitedQuestionIds, setVisitedQuestionIds,
    onDone, salt
}) => {
    const {
        getResponseRun,
        respond
    } = useSurvey()

    const [, setDirty] = useDirty()
    const belowPhone = useBreakpoint()('phone')

    const source = getResponseRun()

    const {
        title,
        questions = []
    } = source

    const wrapperRef = useRef()
    const questionsRef = useRef([])

    questionsRef.current = questions.map((_, index) => questionsRef.current[index] ?? {
        block: createRef(),
        field: createRef()
    })

    const [responding, setResponding] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const setFocus = index => questionsRef.current[index]?.field?.current?.focus?.()

    const scrollToIndex = useCallback(index => {
        const targetWrapper = questionsRef.current[index]?.block?.current
        if(!targetWrapper) {
            return
        }

        let behavior = 'smooth'
        if(Math.abs(currentQuestionIndex - index) >= 10) {
            behavior = 'instant'
        }

        targetWrapper.scrollIntoView({
            behavior,
            block: 'start'
        })

        const after = () => {
            setCurrentQuestionIndex(index)
            setFocus(index)
        }

        if(behavior === 'smooth') {
            setTimeout(after, 800)
        }

        if(behavior === 'instant') {
            after()
        }
    }, [currentQuestionIndex, setCurrentQuestionIndex])

    useEffect(() => {
        started && setFocus(0)
    }, [started])

    useEffect(() => {
        // If the user has manually scrolled to a question, update the current question index
        if(!wrapperRef.current || !!submitted) {
            return
        }

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if(entry.isIntersecting) {
                    const toIndex = questionsRef.current.findIndex(({ block }) => block.current === entry.target)

                    const top = entry.intersectionRect.top
                    const bottom = entry.rootBounds.height - entry.intersectionRect.bottom
                    const direction = (top < bottom) ? 'up' : 'down'

                    if(!!~toIndex) {
                        setCurrentQuestionIndex(toIndex)
                        setReachedQuestionIndex(previouslyReachedQuestion => Math.max(previouslyReachedQuestion, toIndex))

                        // If scrolling up from the last question, all questions are visited
                        const questionsToSetVisited = (direction === 'up' && toIndex + 1 === questions.length - 1) ?
                            questions :
                            questions.slice(0, toIndex + (direction === 'down' ? 0 : 1))

                        setVisitedQuestionIds(visitedQuestionIds => prune([
                            ...visitedQuestionIds,
                            ...questionsToSetVisited.map(({ id }) => id)
                        ]))
                    }
                }
            })
        }, {
            root: wrapperRef.current,
            rootMargin: '0px',
            threshold: .5
        })

        questionsRef.current.forEach(({ block }) => observer.observe(block.current))
        return () => observer.disconnect()
    }, [wrapperRef, submitted])

    const scrollNext = useCallback(debounce(() => {
        const setReachedAndVisitedIndices = ({ currentIndex, nextIndex }, visited) => {
            setReachedQuestionIndex(previouslyReachedQuestionIndex => Math.max(previouslyReachedQuestionIndex, nextIndex ?? currentIndex))

            visited && setVisitedQuestionIds(visitedQuestionIds => prune([
                ...visitedQuestionIds,
                questions[currentIndex].id
            ]))
        }

        setCurrentQuestionIndex(currentIndex => {
            const nextIndex = currentIndex + 1
            if(nextIndex < questions.length) {
                const nextWrapper = questionsRef.current[nextIndex]?.block?.current
                if(nextWrapper) {
                    setTimeout(() => scrollToIndex(nextIndex), 100)
                    setReachedAndVisitedIndices({ currentIndex, nextIndex }, true)
                    return nextIndex
                }
            }

            setReachedAndVisitedIndices({ currentIndex }, false)
            return currentIndex
        })
    }, debounceOptions), [questions.length, setCurrentQuestionIndex, setVisitedQuestionIds])

    const scrollPrevious = useCallback(debounce(() => {
        const updateVisitedIds = currentIndex => setVisitedQuestionIds(visitedQuestionIds => prune([
            ...visitedQuestionIds,
            questions[currentIndex].id
        ]))

        setCurrentQuestionIndex(currentIndex => {
            const previousIndex = currentIndex - 1
            if(!!~previousIndex) {
                const previousWrapper = questionsRef.current[previousIndex]?.block?.current
                if(previousWrapper) {
                    setTimeout(() => scrollToIndex(previousIndex), 100)
                    updateVisitedIds(currentIndex)
                    return previousIndex
                }
            }

            return currentIndex
        })
    }, debounceOptions), [questions.length, setCurrentQuestionIndex, setVisitedQuestionIds])

    const submit = async body => {
        if(!answerable) {
            return void setSubmitted(true)
        }

        if(responding) {
            return
        }

        setResponding(true)

        const answers = questions.map(question => ({
            questionId: question.id,
            type: question.type,
            value: body[question.id]
        }))

        const { ok } = await respond({
            type: 'custom',
            answers,
            ...pick(source, 'type', 'id')
        })

        setResponding(false)

        if(ok) {
            setSubmitted(true)
            onDone?.(source)
        }
    }

    const getProgress = ({ fields, errors }) => {
        if(submitted) {
            return 100
        }

        const max = (reachedQuestionIndex + 1) / questions.length * 100

        return clamp(
            100 - (Object.keys(errors).length / Object.keys(fields).length * 100),
            3, max
        )
    }

    const first = currentQuestionIndex === 0
    const last = currentQuestionIndex + 1 === questions.length

    return (
        <Wrapper
            layout="vertical"
            {...(started ? { className: 'running' } : { inert: 'true' })}
            onChange={body => {
                if(!source.preview && !!size(body)) {
                    setDirty(true)
                }
            }}
            onSubmit={submit}>
            {({ fields, errors, trigger }) => {
                const feedback = questions
                    .slice(0, reachedQuestionIndex + 1)
                    .map((question, index) => {
                        if(!(question.id in errors) || !visitedQuestionIds.includes(question.id)) {
                            return null
                        }

                        return {
                            ...question,
                            index,
                            ordinal: index + 1
                        }
                    })
                    .filter(Boolean)

                const showPreviousButton = (!first && !!visitedQuestionIds.length) && !(feedback.length && belowPhone)

                const proceed = index => () => {
                    // If all questions are visited and there are errors, “proceed”
                    // always navigates to the first remaining errored question
                    if(questions.length === visitedQuestionIds.length && !!feedback.length) {
                        let nextErrorIndex = feedback[0].index
                        if(nextErrorIndex === index) {
                            nextErrorIndex = feedback[1]?.index
                        }

                        if(!!~nextErrorIndex) {
                            setCurrentQuestionIndex(nextErrorIndex)
                            scrollToIndex(nextErrorIndex)
                            return
                        }
                    }

                    // Else, proceed to the next question unless it’s the last
                    !last && scrollNext()
                }

                const submissible = !Object.keys(errors).length && !submitted

                return (
                    <>
                        <Header>
                            <SymbolAndTitle>
                                <Symbol
                                    symbol={source.symbol ?? getFallbackSymbol(source)}
                                    size={40} />
                                <Title>{title}</Title>
                            </SymbolAndTitle>
                            <ProgressIndicator
                                values={[getProgress({ fields, errors })]}
                                className="constructive" />
                        </Header>
                        {!submitted && (
                            <Layout>
                                <Questions ref={wrapperRef}>
                                    {questions.map((question, index) => (
                                        <Question
                                            question={question}
                                            fieldRef={questionsRef.current[index].field}
                                            proceed={proceed(index)}
                                            isCurrent={currentQuestionIndex === index}
                                            last={last}
                                            index={index}
                                            salt={salt}
                                            ref={questionsRef.current[index].block}
                                            key={`${salt}:question:${question.id ?? index}`} />
                                    ))}
                                </Questions>
                                <Actions className="spread">
                                    {submissible && <AnonymousStatus className="banner" />}
                                    {(!showPreviousButton && !belowPhone) && <div />}
                                    {showPreviousButton && (
                                        <Plain
                                            onClick={scrollPrevious}
                                            icon={ArrowLeft}>
                                            <FormattedMessage
                                                id="action_previous"
                                                defaultMessage="Previous" />
                                        </Plain>
                                    )}
                                    {!!feedback.length && (
                                        <Feedback>
                                            <FeedbackButton
                                                onClick={() => {
                                                    setCurrentQuestionIndex(feedback[0].index)
                                                    scrollToIndex(feedback[0].index)
                                                }}
                                                disabled={currentQuestionIndex === feedback[0].index}>
                                                <FeedbackCount>{feedback.length}</FeedbackCount>
                                                <span>
                                                    <FormattedMessage
                                                        id="survey_feedback_label"
                                                        defaultMessage="Forgotten something?" />
                                                </span>
                                            </FeedbackButton>
                                        </Feedback>
                                    )}
                                    {!last && (
                                        <Button
                                            className="constructive"
                                            onClick={proceed(currentQuestionIndex)}>
                                            <FormattedMessage
                                                id="action_next"
                                                defaultMessage="Next" />
                                        </Button>
                                    )}
                                    {!!last && (
                                        <ButtonSubmit
                                            className={cls(['constructive', responding && 'loading'])}
                                            disabled={!submissible}
                                            ref={trigger}>
                                            <FormattedMessage
                                                id="action_submit"
                                                defaultMessage="Submit" />
                                        </ButtonSubmit>
                                    )}
                                </Actions>
                            </Layout>
                        )}
                        {submitted && (
                            <Layout
                                className="submitted"
                                ref={wrapperRef}>
                                <Summary answerable={answerable} />
                            </Layout>
                        )}
                    </>
                )
            }}
        </Wrapper>
    )
}

const debounceOptions = { leading: true, trailing: false, maxWait: 500 }

export const hasValue = (value, type) => ({
    text: value => !!value?.trim(),
    single_select: value => !!value,
    multiple_select: value => !!value?.length,
    scale: value => value !== null && !!~value,
    boolean: value => [true, false].includes(value)
})[type]?.(value) ?? !!value

export default RespondSurveyQuestions