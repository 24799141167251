import React, { Component, createContext, useContext } from 'react'
import { get, post, patch, remove } from 'api'
import { useAccess } from 'contexts/access'
import debounce from 'lodash.debounce'
import PubSub from 'pubsub-js'
import isEqual from 'react-fast-compare'
import { size, reduce, withoutEmptyArrays } from 'utilities/object'
import { pruneBy } from 'utilities/array'
import { isofy, getDate } from 'utilities/date-time'
import { enrichEntry } from 'pages/absence/utilities'

const AbsenceEntriesByTimeContext = createContext()
AbsenceEntriesByTimeContext.displayName = 'AbsenceEntriesByTime'

class AbsenceEntriesByTimeProvider extends Component {
    constructor(props) {
        super(props)

        const { eternal = true } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        this.pagingDefaults = pagingDefaults(props?.paging)

        this.state = {
            entries: props?.entries ?? [],
            fixed: !!props?.entries,
            filter: props?.filter ?? {},
            range: props?.range ?? {},
            paging: this.pagingDefaults(),
            eternal,

            fetch: this.fetchDebounced,
            addEntry: this.add,
            updateEntry: this.update,
            updateEntryReview: this.updateReview,
            updateEntryLocally: this.updateLocally,
            endEntry: this.end,
            removeEntry: this.remove,
            setEntriesFilter: this.setFilter,
            setEntriesRange: this.setRange,

            error: null,
            resetError: () => this.setError(null),

            hasFetched: false,
            autoFetch: false,
            fetching: false
        }

        this.subscription = PubSub.subscribe('absenceEntries.refresh', () => {
            this.setState({
                entries: [],
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }, this.fetchDebounced)
        })
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetchDebounced()
    }

    componentDidUpdate({ paging, entries }, { filter, range }) {
        const pagingChanged = !isEqual(paging, this.props?.paging)
        const filterChanged = !isEqual(filter, this.state.filter)
        const rangeChanged = !isEqual(range, this.state.range)

        const state = {}

        if(pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props?.paging)
            state.paging = this.pagingDefaults()
        }

        if(entries !== this.props.entries) {
            state.entries = this.props.entries
            state.fixed = !!this.props.entries
        }

        if(filterChanged || rangeChanged) {
            this.setState(size(state) ? state : null, () => {
                this.fetchDebounced(true)
            })
        }
    }

    componentWillUnmount() {
        this.fetchController.abort()
        PubSub.unsubscribe(this.subscription)
    }

    fetch = async (force = false) => {
        const {
            fetching,
            paging,
            filter,
            range
        } = this.state

        const { fromDate, toDate } = range ?? {}

        if((fetching && !force) || (!fromDate && !toDate)) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({ fetching: true })

        const incrementalFetchEntries = []

        const fetch = async () => {
            const offset = incrementalFetchEntries.length

            const nextPaging = {
                offset,
                limit: paging.limit
            }

            const { ok, response } = await get({
                path: '/absence/entries',
                params: {
                    ...filter,
                    ...nextPaging,
                    fromDate,
                    toDate
                }
            })

            incrementalFetchEntries.push(...response?.items ?? [])

            if(response.items?.length && incrementalFetchEntries.length < response.total) {
                this.setState({
                    paging: {
                        ...paging,
                        ...nextPaging
                    }
                })

                fetch()
            } else {
                if(ok && response) {
                    this.setState(({ entries: previousEntries }) => {
                        const entries = pruneBy([
                            ...previousEntries,
                            ...incrementalFetchEntries.map(enrichEntry)
                        ])

                        return {
                            entries,
                            paging: this.pagingDefaults(),
                            fetching: false,
                            hasFetched: true
                        }
                    })
                }
            }
        }

        fetch()
    }

    add = async ({ body }) => {
        const { ok, response } = await post({
            path: '/absence/entries',
            body
        })

        if(response) {
            if(ok) {
                this.setState(({ entries }) => ({
                    entries: [
                        ...entries,
                        enrichEntry(response)
                    ]
                }))
            } else {
                this.setError({
                    ...response,
                    entry: enrichEntry(body)
                })
            }
        }

        return { ok, response }
    }

    update = async ({ body, entryId, entry }) => {
        const { ok, response } = await patch({
            path: `/absence/entries/${entryId}`,
            body
        })

        if(response) {
            if(ok) {
                this.setState(({ entries }) => {
                    const index = entries.findIndex(({ id }) => id === entryId)

                    return {
                        entries: [
                            ...entries.slice(0, index),
                            enrichEntry(response),
                            ...entries.slice(index + 1, entries.length)
                        ]
                    }
                })
            } else {
                const absenceAdmin = this.props.check('absence:manage')

                if(!(response.errorCode?.includes('overlapping') && absenceAdmin)) {
                    this.setError({
                        ...response,
                        entry
                    })
                }
            }
        }

        return { ok, response }
    }

    updateReview = async (body, entryId) => {
        const { ok, response } = await post({
            path: `/absence/entries/${entryId}/status`,
            body
        })

        if(response) {
            if(ok) {
                this.setState(({ entries }) => {
                    const index = entries.findIndex(({ id }) => id === entryId)

                    return {
                        entries: [
                            ...entries.slice(0, index),
                            enrichEntry(response),
                            ...entries.slice(index + 1, entries.length)
                        ]
                    }
                })

                PubSub.publish('absenceEntries.refresh')
            }
        }

        return { ok, response }
    }

    updateLocally = (body, entryId) => void this.setState(({ entries }) => {
        const index = entries.findIndex(({ id }) => id === entryId)

        const entry = {
            ...entries[index],
            ...body
        }

        return {
            entries: [
                ...entries.slice(0, index),
                entry,
                ...entries.slice(index + 1, entries.length)
            ]
        }
    })

    end = entry => {
        const { id: entryId } = entry
        const endDate = getDate()

        entry.endDate = endDate

        return this.update({
            body: { endDate },
            entryId,
            entry: enrichEntry(entry)
        })
    }

    remove = async entryId => {
        const { ok } = await remove({
            path: `/absence/entries/${entryId}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ entries, paging }) => ({
                entries: entries.filter(entry => entry.id !== entryId),
                paging: {
                    ...paging,
                    offset: paging.offset - 1,
                    limit: paging.limit + 1
                }
            }))
        }

        return { ok }
    }

    setError = data => {
        let error = null

        if(data) {
            const {
                entry: currentEntry,
                values,
                errorCode,
                errorMessage
            } = data

            error = {
                errorType: errorCode.replace('field:', '').replaceAll('-', '_'),
                errorMessage,
                currentEntry,
                overlappingEntries: values?.map(value => ({
                    ...value,
                    user: currentEntry.user
                }))
            }
        }

        this.setState({ error })
    }

    setFilter = (filter = {}) => {
        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }))

        this.setState(({ filter: previousFilter }) => {
            const filterChanged = !isEqual(
                withoutEmptyArrays(filter),
                withoutEmptyArrays(previousFilter)
            )

            if(!filterChanged) {
                return null
            }

            return {
                entries: [],
                filter,
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }
        })
    }

    setRange = range => {
        let { fromDate, toDate } = range

        fromDate = fromDate ? getDate(isofy(fromDate)) : null
        toDate = toDate ? getDate(isofy(toDate)) : null

        this.setState(({ range: previousRange }) => {
            const fromDateChanged = fromDate !== previousRange.fromDate
            const toDateChanged = toDate !== previousRange.toDate

            if(!fromDateChanged && !toDateChanged) {
                return null
            }

            return {
                range: {
                    ...(fromDate ? { fromDate } : null),
                    ...(toDate ? { toDate } : null)
                }
            }
        })
    }

    render() {
        const { children = null } = this.props

        return (
            <AbsenceEntriesByTimeContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </AbsenceEntriesByTimeContext.Provider>
        )
    }
}

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 25,
    ...overrides,
    hasNextPage: false
})

export const useAbsenceEntriesByTime = () => useContext(AbsenceEntriesByTimeContext)

export default props => {
    const { check } = useAccess()

    return (
        <AbsenceEntriesByTimeProvider
            {...props}
            check={check} />
    )
}