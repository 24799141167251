import React, { Component, createContext, useContext } from 'react'
import { createPortal } from 'react-dom'
import { useIntl } from 'react-intl'
import { useOrganization } from 'contexts/organization'
import { useMe } from 'contexts/me'
import { useI18n } from 'contexts/i18n'
import { usePossum } from 'hooks/possum'
import { blankSymbol } from 'pages/handbook'
import { BlobProvider } from '@react-pdf/renderer'
import { get, post, patch, remove } from 'api'
import { saveAs } from 'file-saver'
import { intersectionObserver } from 'utilities/dom'
import PubSub from 'pubsub-js'
import debounce from 'lodash.debounce'
import isEqual from 'react-fast-compare'
import { pick, size, omit, reduce, withoutEmptyArrays } from 'utilities/object'
import { local } from 'utilities/storage'
import PDFContent from 'components/tiptap/output/pdf'
import HandBookPdfFrontPage from 'pages/handbook/components/pdf-front-page'

const HandbookContext = createContext()
HandbookContext.displayName = 'Handbook'

class HandbookProvider extends Component {
    constructor(props) {
        super(props)

        const { eternal = true } = props

        this.searchController = new AbortController()
        this.searchDebounced = debounce(this.search, 100, { maxWait: 500, leading: false, trailing: true })
        this.searchIntersectionObserver = intersectionObserver(this.onSearchIntersect)

        this.pagingDefaults = pagingDefaults(props.paging)
        this.searchPagingDefaults = pagingDefaults(props.search?.paging)

        this.state = {
            handbook: null,
            targetTopicCountAfterImport: null,
            filter: props.filter ?? {},
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { searchIntersecter: this.searchIntersectionObserver.ref } : null),

            fetchHandbook: this.fetch,
            fetchPinnedTopics: this.fetchPinnedTopics,
            addHandbook: this.add,
            updateHandbook: this.update,
            updateOrder: this.updateOrder,
            downloadAsPdf: this.downloadAsPdf,
            importTemplate: this.importTemplate,
            setHandbookFilter: this.setFilter,
            removeHandbook: this.remove,

            addCategory: this.addCategory,
            updateCategory: this.updateCategory,
            removeCategory: this.removeCategory,
            getCategoryById: this.getCategoryById,
            getCategoryByTopicId: this.getCategoryByTopicId,

            addTopic: this.addTopic,
            updateTopic: this.updateTopic,
            removeTopic: this.removeTopic,
            getTopicById: this.getTopicById,
            getTopicsByCategoryId: this.getTopicsByCategoryId,

            notify: this.notify,

            setHandbookSearchFilter: this.setSearchFilter,
            resetHandbookSearchFilter: this.resetSearchFilter,

            hasFetched: false,
            fetchError: false,
            fetching: false,
            generatingPdf: false,

            viewingAs: null,
            setViewingAs: this.setViewingAs,

            search: {
                filter: props.search?.filter ?? {},
                results: [],
                paging: this.searchPagingDefaults(),
                fetching: false,
                loading: false,
                hasFetched: false,
                autoFetch: true
            },

            pdfPortal: null
        }
    }

    componentDidMount() {
        const {
            fetchAccess = true,
            fetchOnMount = true
        } = this.props

        if(fetchAccess && fetchOnMount) {
            this.fetch()
        }
    }

    componentDidUpdate(props, { filter }) {
        const pagingChanged = !isEqual(props.paging, this.props.paging)
        const filterChanged = !isEqual(filter, this.state.filter)

        const { fetchAccess: previousFetchAccess = true } = props
        const { fetchAccess = true } = this.props
        const fetchAccessGained = !previousFetchAccess && fetchAccess

        const state = {}

        if(pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props.paging)
            state.paging = this.pagingDefaults()
        }

        if(pagingChanged || filterChanged || fetchAccessGained) {
            this.setState(size(state) ? state : null, () => {
                if(filterChanged || fetchAccessGained) {
                    this.fetch()
                }
            })
        }
    }

    fetch = async () => {
        const { fetching } = this.state
        if(fetching) {
            return
        }

        this.setState({
            fetching: true,
            fetchError: false
        })

        const { ok: handbookOk, response } = await get({
            path: '/handbook'
        })

        const [handbook] = (response.items ?? [])

        if(handbookOk && handbook) {
            let [
                { ok: categoriesOk, response: categories },
                { ok: topicsOk, response: topics }
            ] = await Promise.all([
                this.fetchCategoriesExhaustively({ id: handbook.id }),
                this.fetchTopicsExhaustively({ id: handbook.id })
            ])

            const ok = categoriesOk && topicsOk

            if(ok) {
                categories.items = categories.items.sort((a, b) => a.index - b.index)

                const categorizedTopics = categories.items.map(category => ({
                    ...category,
                    topics: topics.items
                        .filter(topic => topic.categoryId === category.id)
                        .sort((a, b) => a.index - b.index)
                }))

                this.setState({
                    handbook: {
                        ...handbook,
                        categories: categorizedTopics,
                        pinnedTopics: topics.items.reduce((accumulator, topic) => {
                            if(topic.pinned) {
                                accumulator[topic.id] = topic
                            }

                            return accumulator
                        }, {}),
                        topicCount: topics.total
                    },
                    hasFetched: true,
                    fetchError: false,
                    fetching: false
                })

                const recentSearches = local.get('handbook:recent-searches') ?? []
                const recentSearchesWithoutOrphans = recentSearches.filter(topicId => topics.items.includes(topicId))

                if(recentSearchesWithoutOrphans.length !== recentSearches.length) {
                    local.set('handbook:recent-searches', recentSearchesWithoutOrphans)
                    PubSub.publish('handbook:recent-searches:updated')
                }

            } else if(categoriesOk) {
                this.setState({
                    handbook: {
                        ...handbook,
                        categories: categories.items.map(category => ({
                            ...category,
                            topics: []
                        })),
                        topicCount: 0
                    },
                    hasFetched: true,
                    fetchError: false,
                    targetTopicCountAfterImport: null,
                    fetching: false
                })
            }
        } else {
            this.setState({
                fetching: false,
                fetchError: true,
                hasFetched: true,
                targetTopicCountAfterImport: null
            })

            return { ok: false }
        }
    }

    fetchPinnedTopics = async () => {
        const { fetching } = this.state
        if(fetching) {
            return
        }

        this.setState({ fetching: true })

        const { ok: handbookOk, response: handbooks } = await get({
            path: '/handbook'
        })

        const [handbook] = (handbooks.items ?? [])

        if(handbookOk && handbook) {
            const [
                { ok: topicsOk, response: topics },
                { ok: categoriesOk, response: categories }
            ] = await Promise.all([
                this.fetchTopicsExhaustively({
                    id: handbook.id,
                    filter: { pinned: true }
                }),
                this.fetchCategoriesExhaustively({ id: handbook.id })
            ])

            if(topicsOk && categoriesOk) {
                const categorizedTopics = categories.items.map(category => ({
                    ...category,
                    topics: topics.items
                        .filter(topic => topic.categoryId === category.id)
                        .sort((a, b) => a.index - b.index)
                }))

                this.setState({
                    handbook: {
                        ...handbook,
                        categories: categorizedTopics,
                        pinnedTopics: topics.items.reduce((accumulator, topic) => ({
                            ...accumulator,
                            [topic.id]: topic
                        }), {})
                    },
                    hasFetched: true,
                    fetching: false
                })

                return {
                    ok: true,
                    response: topics.items
                }
            }
        } else {
            this.setState({
                fetching: false,
                hasFetched: true
            })
        }

        return { ok: false }
    }

    fetchCategoriesExhaustively = async ({ id, categories = [], filter: filterOverride }) => {
        const filter = filterOverride ?? this.state.filter ?? null

        const { ok, response } = await get({
            path: `/handbook/${id}/categories`,
            params: {
                ...filter,
                offset: categories.length
            }
        })

        if(ok && response?.items?.length) {
            categories.push(...response.items)

            if(categories.length < response.total) {
                return await this.fetchCategoriesExhaustively({ id, categories })
            }
        }

        return {
            ok,
            response: {
                ...response,
                items: categories
            }
        }
    }

    fetchTopicsExhaustively = async ({ id, topics = [], filter: filterOverride }) => {
        const filter = filterOverride ?? this.state.filter ?? null

        const { ok, response } = await get({
            path: `/handbook/${id}/topics`,
            params: {
                ...filter,
                offset: topics.length
            }
        })

        if(ok && response?.items?.length) {
            topics.push(...response.items)

            if(topics.length < response.total) {
                return await this.fetchTopicsExhaustively({ id, topics, filter })
            }
        }

        return {
            ok,
            response: {
                ...response,
                items: topics
            }
        }
    }

    search = async (force = false) => {
        const {
            handbook,
            search
        } = this.state

        if(search.fetching && !force) {
            return
        }

        if(force || search.fetching) {
            this.searchController.abort()
            this.searchController = new AbortController()
        }

        this.setState(({ search }) => ({
            search: {
                ...search,
                fetching: true,
                ...(search.autoFetch ? { loading: true } : null)
            }
        }))

        const nextPaging = {
            offset: search.hasFetched ? search.paging.offset + search.paging.limit : 0,
            limit: search.paging.limit
        }

        const { ok, response } = await get({
            path: `/handbook/${handbook.id}/topics`,
            params: {
                ...search.filter,
                ...nextPaging
            },
            signal: this.searchController.signal
        })

        if(ok) {
            this.setState(({ search }) => {
                const results = [
                    ...search.results,
                    ...response.items.map(item => ({
                        ...item,
                        category: handbook.categories.find(({ id }) => id === item.categoryId)
                    }))
                ]

                return {
                    search: {
                        ...search,
                        results,
                        paging: {
                            ...search.paging,
                            ...nextPaging,
                            hasNextPage: !!response.items?.length && results.length < (response?.total ?? -1)
                        },
                        hasFetched: true,
                        autoFetch: search.autoFetch || (!!search.results.length && search.hasFetched),
                        fetching: false,
                        loading: false
                    }
                }
            })
        } else {
            this.setState(({ search, eternal }) => ({
                search: {
                    ...search,
                    hasFetched: true,
                    ...(!eternal ? { autoFetch: false } : null),
                    fetching: false,
                    loading: false
                }
            }))
        }

        return { ok, response }
    }

    add = async body => {
        const { ok, response: handbook } = await post({
            path: '/handbook',
            body
        })

        if(ok) {
            this.setState({ handbook })
        }

        return { ok, response: handbook }
    }

    update = async (body, id, options = {}) => {
        const { ok, response } = await patch({
            path: `/handbook/${id}`,
            body
        })

        if(ok && response) {
            const { keep = [] } = options

            this.setState(({ handbook }) => ({
                handbook: {
                    ...omit(response, ...keep),
                    ...pick(handbook, ...[...keep, 'categories', 'pinnedTopics', 'topicCount'])
                }
            }))
        }

        return { ok, response }
    }

    updateOrder = async body => {
        const order = body.categories.map(category => ({
            id: category.id,
            topics: category.topics.map(topic => topic.id)
        }))

        const requests = [
            ...(order.map(({ id: categoryId }, categoryIndex) => {
                const category = this.state.handbook.categories.find(category => category.id === categoryId)

                if(categoryIndex !== category.index) {
                    return patch({
                        path: `/handbook/${this.state.handbook.id}/categories/${categoryId}`,
                        body: {
                            index: categoryIndex
                        }
                    })
                }
            }) ?? []),
            ...(order.map(({ id: categoryId, topics }) => topics.map((topicId, topicIndex) => {
                const topic = this.state.handbook.categories
                    .flatMap(category => category.topics)
                    .find(topic => topic.id === topicId)

                if(topicIndex !== topic.index || categoryId !== topic.categoryId) {
                    return patch({
                        path: `/handbook/${this.state.handbook.id}/topics/${topicId}`,
                        body: {
                            ...(topicIndex !== topic.index && { index: topicIndex }),
                            ...(categoryId !== topic.categoryId && { categoryId })
                        }
                    })
                }
            })) ?? [])
        ]

        const results = await Promise.all(requests.flat(Infinity).filter(Boolean))
        const ok = results.every(({ ok }) => ok)

        if(ok) {
            this.setState(({ handbook }) => ({
                handbook: {
                    ...handbook,
                    categories: order.map(({ id: categoryId }, categoryIndex) => {
                        const category = handbook.categories.find(({ id }) => id === categoryId)

                        return {
                            ...category,
                            index: categoryIndex,
                            topics: order
                                .filter(({ id }) => id === categoryId)
                                .flatMap(({ topics }) => topics)
                                .map((topicId, index) => {
                                    const topic = handbook.categories
                                        .flatMap(({ topics }) => topics)
                                        .find(({ id }) => id === topicId)

                                    return {
                                        ...topic,
                                        index,
                                        categoryId
                                    }
                                }
                            )
                        }
                    })
                }
            }))
        }

        return { ok }
    }

    remove = async id => {
        const { ok } = await remove({
            path: `/handbook/${id}`,
            returnsData: false
        })

        if(ok) {
            this.setState({ handbook: null })
        }

        return { ok }
    }

    importTemplate = async ({ body, handbookId, type = 'template' }) => {
        const { ok, response: categories } = await post({
            path: `/handbook/${handbookId}/import`,
            body: {
                ...body,
                type
            }
        })

        if(ok) {
            this.setState(({ handbook }) => {
                const existingTopicCount = handbook?.categories?.flatMap(({ topics = [] }) => topics).length ?? 0
                const newTopicCount = categories.reduce((count, { topicCount = 0 }) => count + topicCount, 0) ?? 0

                return {
                    targetTopicCountAfterImport: existingTopicCount + newTopicCount,
                }
            }, () => this.fetch())
        }

        return { ok, response: categories }
    }

    addCategory = async ({ body, index }) => {
        if(!~index) {
            return { ok: false }
        }

        const { ok, response: category } = await post({
            path: `/handbook/${this.state.handbook.id}/categories`,
            body: {
                ...body,
                index
            }
        })

        if(ok) {
            this.setState(({ handbook }) => ({
                handbook: {
                    ...handbook,
                    categories: [
                        ...(handbook.categories ?? []).slice(0, index),
                        {
                            ...category,
                            topics: []
                        },
                        ...(handbook.categories ?? []).slice(index)
                    ]
                }
            }), () => {
                if(this.state.handbook.categories.length === 1) {
                    return
                }
                const affectedCategories = this.state.handbook.categories
                    .filter(({ id, index }) => index >= category.index && id !== category.id)

                const unaffectedCategoryCount = this.state.handbook.categories.length - affectedCategories.length

                affectedCategories.forEach(({ id }, index) => void this.updateCategory({
                    body: { index: index + unaffectedCategoryCount },
                    id
                }))
            })
        }

        return { ok, response: category }
    }

    updateCategory = async ({ body, id }) => {
        const { ok, response: category } = await patch({
            path: `/handbook/${this.state.handbook.id}/categories/${id}`,
            body
        })

        if(ok) {
            this.setState(({ handbook }) => {
                const index = handbook.categories.findIndex(category => category.id === id)
                const { categories } = handbook
                const { topics } = categories[index]

                return {
                    handbook: {
                        ...handbook,
                        categories: [
                            ...categories.slice(0, index),
                            {
                                ...category,
                                topics
                            },
                            ...categories.slice(index + 1)
                        ]
                    }
                }
            })
        }

        return { ok, response: category }
    }

    removeCategory = async (id, params = {}) => {
        const { index } = this.state.handbook.categories.findIndex(category => category.id === id)

        const { ok, status } = await remove({
            path: `/handbook/${this.state.handbook.id}/categories/${id}`,
            params,
            returnsData: false
        })

        if(ok || status === 404) {
            this.setState(({ handbook, search }) => ({
                handbook: {
                    ...handbook,
                    categories: handbook.categories.filter(category => category.id !== id),
                    search: {
                        ...search,
                        results: search.results.filter(result => result.categoryId !== id)
                    }
                }
            }), () => {
                // Someone else beat us to deleting this category, which was still available
                // in local state. The indices have thus already been updated.
                if(status === 404) {
                    return
                }

                if(!this.state.handbook.categories?.length) {
                    return
                }

                const affectedCategories = this.state.handbook.categories
                    .filter(category => category.index > index)

                const unaffectedCategoryCount = this.state.handbook.categories.length - affectedCategories.length

                affectedCategories.forEach(({ id }, index) => void this.updateCategory({
                    body: { index: index + unaffectedCategoryCount },
                    id
                }))
            })
        }

        return { ok: ok || status === 404 }
    }

    getCategoryById = id => this.state.handbook.categories
        ?.find(category => category.id === id)

    getCategoryByTopicId = id => this.state.handbook.categories
        ?.find(category => !!category.topics?.find(topic => topic.id === id))

    addTopic = async ({ body, index }) => {
        if(!~index) {
            return { ok: false }
        }

        const { ok, response: topic } = await post({
            path: `/handbook/${this.state.handbook.id}/topics`,
            body: {
                ...body,
                index
            }
        })

        if(ok) {
            PubSub.publish('handbook:topic:created', topic)

            const categoryFilter = category => category.id === topic.categoryId

            this.setState(({ handbook }) => {
                const { categories } = handbook
                const categoryIndex = categories.findIndex(categoryFilter)
                const category = categories[categoryIndex]

                const {
                    topics,
                    topicCount
                } = category

                return {
                    handbook: {
                        ...handbook,
                        categories: [
                            ...categories.slice(0, categoryIndex),
                            {
                                ...category,
                                topics: [
                                    ...topics.slice(0, index),
                                    topic,
                                    ...topics.slice(index)
                                ],
                                topicCount: topicCount + 1
                            },
                            ...categories.slice(categoryIndex + 1)
                        ]
                    }
                }
            }, () => {
                const category = this.getCategoryById(topic.categoryId)
                if(category.topics.length === 1) {
                    return
                }

                const affectedTopics = category.topics
                    .filter(({ id, index }) => index >= topic.index && id !== topic.id)

                const unaffectedTopicCount = category.topics.length - affectedTopics.length

                affectedTopics.forEach(({ id }, index) => void this.updateTopic({
                    body: { index: index + unaffectedTopicCount },
                    id
                }))
            })
        }

        return { ok, response: topic }
    }

    updateTopic = async ({ body, id }) => {
        const previousCategory = this.getCategoryByTopicId(id)

        const { ok, response: topic } = await patch({
            path: `/handbook/${this.state.handbook.id}/topics/${id}`,
            body
        })

        if(ok) {
            PubSub.publish('handbook:topic:updated', topic)

            const currentCategory = this.getCategoryById(topic.categoryId)
            const categoryChanged = previousCategory.id !== currentCategory.id

            const removeTopicFromCategory = category => {
                if(categoryChanged) {
                    const topics = category.topics.filter(topic => topic.id !== id)

                    return {
                        ...category,
                        topics,
                        topicCount: topics.length
                    }
                }

                return category
            }

            this.setState(({ handbook, search }) => {
                const {
                    categories,
                    pinnedTopics = {}
                } = handbook

                const categoryIndex = categories.findIndex(category => category.id === topic.categoryId)
                const category = categories[categoryIndex]
                const { topics } = category
                const topicIndex = categoryChanged ?
                    (topic.index ?? 0) :
                    topics.findIndex(topic => topic.id === id)

                if(topic.pinned) {
                    pinnedTopics[id] = topic
                } else {
                    delete pinnedTopics[id]
                }

                return {
                    handbook: {
                        ...handbook,
                        categories: [
                            ...categories.slice(0, categoryIndex).map(removeTopicFromCategory),
                            {
                                ...category,
                                topics: [
                                    ...topics.slice(0, topicIndex),
                                    topic,
                                    ...topics.slice(topicIndex + (categoryChanged ? 0 : 1))
                                ]
                            },
                            ...categories.slice(categoryIndex + 1).map(removeTopicFromCategory)
                        ],
                        pinnedTopics
                    },
                    search: {
                        ...search,
                        results: search.results.map(result => {
                            if(result.id === id) {
                                return {
                                    ...topic,
                                    category: currentCategory
                                }
                            }

                            return result
                        })
                    }
                }
            })
        }

        return { ok, response: topic }
    }

    removeTopic = async id => {
        const category = this.getCategoryByTopicId(id)
        const topic = this.getTopicById(id)

        const { ok, status } = await remove({
            path: `/handbook/${this.state.handbook.id}/topics/${id}`,
            returnsData: false
        })

        if(ok || status === 404) {
            PubSub.publish('handbook:topic:deleted', topic)

            const categoryFilter = ({ id }) => id === category.id
            const topicFilter = topic => topic.id !== id

            this.setState(({ handbook, search }) => {
                const {
                    categories,
                    pinnedTopics = {}
                } = handbook

                const categoryIndex = categories.findIndex(categoryFilter)
                const category = categories[categoryIndex]

                const {
                    topics,
                    topicCount
                } = category

                return {
                    handbook: {
                        ...handbook,
                        categories: [
                            ...categories.slice(0, categoryIndex),
                            {
                                ...category,
                                topics: topics.filter(topicFilter),
                                topicCount: topicCount - 1
                            },
                            ...categories.slice(categoryIndex + 1)
                        ],
                        pinnedTopics: omit(pinnedTopics, id)
                    },
                    search: {
                        ...search,
                        results: search.results.filter(topicFilter)
                    }
                }
            }, () => {
                // Someone else beat us to deleting this topic, which was still available
                // in local state. The indices have thus already been updated.
                if(status === 404) {
                    return
                }

                // Get the updated topics from state for good measure
                const topics = this.getTopicsByCategoryId(topic.categoryId)

                const affectedTopics = topics.filter(({ index }) => index > topic.index)
                if(!affectedTopics.length) {
                    return
                }

                const unaffectedTopicCount = topics.length - affectedTopics.length

                affectedTopics.forEach(({ id }, index) => void this.updateTopic({
                    body: { index: index + unaffectedTopicCount },
                    id
                }))
            })
        }

        return { ok: ok || status === 404 }
    }

    getTopicById = id => this.state.handbook.categories
        .flatMap(({ topics }) => topics)
        .find(topic => topic.id === id)

    getTopicsByCategoryId = id => this.state.handbook.categories
        .find(category => category.id === id)
        .topics

    notify = async (what, message, id) => {
        const { handbook } = this.state

        const path = {
            handbook: `/handbook/${id}/notify`,
            category: `/handbook/${handbook.id}/categories/${id}/notify`,
            topic: `/handbook/${handbook.id}/topics/${id}/notify`
        }[what]

        if(!path) {
            return { ok: false }
        }

        const { ok } = await post({
            path,
            body: { message },
            returnsData: false
        })

        return { ok }
    }

    setFilter = (filter = {}) => {
        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0 || (typeof value === 'boolean' && (value === true || value === false))) ? { [key]: value } : null)
        }))

        this.setState(({ filter: previousFilter }) => {
            const filterChanged = !isEqual(
                withoutEmptyArrays(filter),
                withoutEmptyArrays(previousFilter)
            )

            if(!filterChanged) {
                return null
            }

            return {
                handbook: {
                    ...this.state.handbook,
                    categories: [],
                    pinnedTopics: {}
                },
                filter,
                paging: this.pagingDefaults(),
                hasFetched: false
            }
        })
    }

    setViewingAs = viewingAs => this.setState({ viewingAs })

    setSearchFilter = (filter = {}, options = {}) => {
        const { merge = false } = options

        const prune = filter => reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }))

        let shouldResetSearch = false
        let filterChanged = false

        this.setState(({ search, eternal }, props) => {
            const nextFilter = prune({
                ...(props.search?.filter ?? null),
                ...(merge ? search.filter : null),
                ...(merge ? filter : prune(filter))
            })

            if((nextFilter.search?.length ?? 0) < 2 && !size(omit(nextFilter, 'search'))) {
                // Short-circuit the search if the search term is too short
                shouldResetSearch = true
                return null
            }

            filterChanged = !isEqual(nextFilter, search.filter)
            if(!filterChanged) {
                return null
            }

            return {
                search: {
                    ...search,
                    filter: nextFilter,
                    results: [],
                    paging: this.searchPagingDefaults(),
                    hasFetched: false,
                    ...(!eternal ? { autoFetch: false } : null)
                }
            }
        }, () => {
            if(shouldResetSearch) {
                this.resetSearchFilter()
            }

            if(filterChanged) {
                this.search() // Debounced in view layer
                PubSub.publish('handbook:search:updated', this.state.search.filter)
            }
        })
    }

    resetSearchFilter = () => void this.setState(({ search, eternal }) => ({
        search: {
            ...search,
            filter: {},
            results: [],
            paging: this.searchPagingDefaults(),
            hasFetched: false,
            ...(!eternal ? { autoFetch: false } : null)
        }
    }), () => PubSub.publish('handbook:search:reset'))

    downloadAsPdf = async ({ filter = {}, dryRun = false }) => {
        this.setState({ generatingPdf: true })

        const { handbook } = this.state
        const { organization } = this.props

        const handbookTitle = this.props.formatMessage({
            id: 'handbook_company_title',
            defaultMessage: '{pname} handbook'
        }, { pname: this.props.possessify(organization.name) })

        const content = handbook.categories.reduce((categories, {
            title: categoryTitle,
            summary: categorySummary = [],
            symbol: categorySymbol,
            topics = []
        }) => {
            if(!topics.some(({ status }) => status === 'published')) {
                return categories
            }

            topics = topics
                .filter(({ status }) => status === 'published')
                .filter(({ shares = [] }) => {
                    if(!!size(filter)) {
                        if(filter.include === 'organization') {
                            return shares.some(({ type }) => type === 'organization')
                        }

                        return shares.some(({ type, id }) => {
                            if(type === 'organization' && !!filter.includeSharedWithEveryone) {
                                return true
                            }

                            if(id === filter.include) {
                                return true
                            }

                            return false
                        })
                    }

                    return true
                })
                .reduce((accumulator, {
                    title: topicTitle,
                    symbol: topicSymbol,
                    content
                    // links, language, pinned?
                }) => {
                    if(!content.length || !content.some(({ content }) => !!content?.length)) {
                        return accumulator
                    }

                    return [
                        ...accumulator,
                        {
                            type: 'heading',
                            attrs: { level: 3 },
                            content: [
                                {
                                    type: 'emoji',
                                    attrs: {
                                        name: topicSymbol?.emoji ?? blankSymbol.emoji
                                    }
                                },
                                {
                                    type: 'text',
                                    text: ` ${topicTitle}`
                                }
                            ]
                        },
                        ...content
                    ]
                }, [])

            if(!topics.length) {
                return categories
            }

            return [
                ...categories,
                {
                    type: 'section',
                    content: [
                        {
                            type: 'heading',
                            attrs: { level: 2 },
                            content: [
                                {
                                    type: 'emoji',
                                    attrs: {
                                        name: categorySymbol?.emoji ?? blankSymbol.emoji
                                    }
                                },
                                {
                                    type: 'text',
                                    text: ` ${categoryTitle}`
                                }
                            ]
                        },
                        ...categorySummary.reduce((accumulator, { content = [] }) => {
                            if(!content?.length) {
                                return accumulator
                            }

                            return [
                                ...accumulator,
                                {
                                    type: 'paragraph',
                                    content
                                }
                            ]
                        }, []),
                        ...topics
                    ]
                }
            ]
        }, [])

        if(dryRun) {
            this.setState({ generatingPdf: false })

            return { ok: !!content?.length }
        }

        const { ok, response } = await new Promise((resolve, reject) => void this.setState({
            pdfPortal: createPortal((
                <BlobProvider document={(
                    <PDFContent
                        frontPage={(
                            <HandBookPdfFrontPage
                                handbook={handbook}
                                formatMessage={this.props.formatMessage}
                                organization={organization}
                                dateLocale={this.props.dateLocale}
                                me={this.props.me} />
                        )}
                        content={content}
                        id={this.state.handbook.id}
                        salt="handbook" />
                )}>
                    {({ blob, error }) => {
                        if(blob) {
                            resolve({ ok: true, response: blob })
                        }

                        if(error) {
                            reject({ ok: false })
                        }

                        return null
                    }}
                </BlobProvider>
            ), document.getElementById('off-screen'))
        }))

        this.setState({
            generatingPdf: false,
            pdfPortal: null
        })

        if(ok && response) {
            saveAs(response, `${handbookTitle}.pdf`)
        }

        return { ok, response }
    }

    onSearchIntersect = () => {
        const {
            eternal,
            search
        } = this.state

        if(!eternal || search.loading || !search.autoFetch || !search.paging?.hasNextPage) {
            return
        }

        this.searchDebounced()
    }

    render() {
        const { children = null } = this.props

        return (
            <HandbookContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
                {this.state.pdfPortal}
            </HandbookContext.Provider>
        )
    }
}

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

export const useHandbook = () => useContext(HandbookContext)

export default props => {
    const { formatMessage } = useIntl()
    const { organization } = useOrganization()
    const { me } = useMe()
    const { dateLocale } = useI18n()
    const possessify = usePossum()

    return (
        <HandbookProvider
            {...props}
            formatMessage={formatMessage}
            organization={organization}
            me={me}
            dateLocale={dateLocale}
            possessify={possessify} />
    )
}