import React, { Component, createContext, useContext } from 'react'
import debounce from 'lodash.debounce'
import { intersectionObserver } from 'utilities/dom'
import isEqual from 'react-fast-compare'
import { size, reduce, withoutEmptyArrays } from 'utilities/object'
import { get } from 'api'

const HandbookTemplatesContext = createContext()
HandbookTemplatesContext.displayName = 'HandbookTemplates'

export default class HandbookTemplatesProvider extends Component {
    constructor(props) {
        super(props)

        const { eternal = true } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        this.intersectionObserver = intersectionObserver(this.onIntersect)

        this.pagingDefaults = pagingDefaults(props?.paging)

        this.state = {
            templates: [],
            total: 0,
            filter: props?.filter ?? {},
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            fetch: this.fetch,

            setTemplatesFilter: this.setFilter,

            hasFetched: false,
            autoFetch: false,
            fetching: false,
            loading: false
        }
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props

        if(fetchOnMount) {
            this.fetchDebounced()
        }
    }

    componentDidUpdate({ paging }, { filter }) {
        const pagingChanged = !isEqual(paging, this.props.paging)
        const filterChanged = !isEqual(filter, this.state.filter)

        const state = {}

        if(pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props.paging)
            state.paging = this.pagingDefaults()
        }

        this.setState(size(state) ? state : null, () => {
            if(filterChanged) {
                this.fetchDebounced(true)
            }
        })
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver.destroy()
    }

    fetch = async (force = false) => {
        const {
            fetching,
            filter,
            paging,
            autoFetch,
            hasFetched,
            eternal
        } = this.state

        if(((fetching || (hasFetched && !eternal)) && !force)) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetched ? (paging.offset + this.pagingDefaults().limit) : 0,
            limit: paging.limit
        }

        const { response, ok } = await get({
            path: '/handbook-templates',
            params: {
                ...filter,
                ...nextPaging
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
            this.setState(({ templates: previousTemplates }) => {
                const previousTemplatesWithoutFetched = previousTemplates.filter(({ id }) => !response.items.find(({ id: templateId }) => templateId === id))

                const templates = [
                    ...previousTemplatesWithoutFetched,
                    ...response.items
                ]

                return {
                    templates,
                    total: response.total,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        limit: this.pagingDefaults().limit,
                        hasNextPage: response.items.length && templates.length < response.total
                    },
                    hasFetched: true,
                    autoFetch: this.props.autoFetch || (!!previousTemplates.length && hasFetched),
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                hasFetched: true,
                autoFetch: false,
                fetching: false,
                loading: false
            })
        }
    }

    setFilter = (filter = {}) => {
        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }))

        this.setState(({ filter: previousFilter }) => {
            const filterChanged = !isEqual(
                withoutEmptyArrays(filter),
                withoutEmptyArrays(previousFilter)
            )

            if(!filterChanged) {
                return null
            }

            // TODO: This code isn’t like other setFilter routines
            // But also, it’s not in use anywhere...
            return {
                templates: [],
                filter: {
                    ...this.props.filter,
                    ...filter,
                    ...(!!size(filter) ? { status: [] } : null)
                },
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }
        })
    }

    render() {
        const { children = null } = this.props

        return (
            <HandbookTemplatesContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </HandbookTemplatesContext.Provider>
        )
    }
}

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

export const useHandbookTemplates = () => useContext(HandbookTemplatesContext)