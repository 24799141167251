import React, { Component, createContext, useContext } from 'react'
import { useIntl } from 'react-intl'
import { get, post } from 'api'
import { intersectionObserver } from 'utilities/dom'
import debounce from 'lodash.debounce'
import isEqual from 'react-fast-compare'
import { size, reduce, withoutEmptyArrays } from 'utilities/object'
import { useSharedIn } from 'hooks/shared-in'

const NewsContext = createContext()
NewsContext.displayName = 'News'

class NewsProvider extends Component {
    constructor(props) {
        super(props)

        const { eternal = true } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        if(eternal) {
            this.intersectionObserver = intersectionObserver(this.onIntersect)
        }

        this.pagingDefaults = pagingDefaults(props?.paging)

        this.state = {
            articles: props?.articles ?? [],
            fixed: !!props?.articles,
            filter: props?.filter ?? {},
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            fetchNews: this.fetch,
            setNewsFilter: this.setFilter,

            addArticle: this.add,

            hasFetched: false,
            autoFetch: false,
            fetching: false,
            loading: false
        }
    }

    componentDidMount() {
        this.fetchDebounced()
    }

    componentDidUpdate({ paging }, { filter }) {
        const pagingChanged = !isEqual(paging, this.props?.paging)
        const filterChanged = !isEqual(filter, this.state.filter)

        const state = {}

        if(pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props?.paging)
            state.paging = this.pagingDefaults()
        }

        this.setState(size(state) ? state : null, () => {
            if(filterChanged) {
                this.fetchDebounced(true)
            }
        })
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver?.destroy?.()
    }

    fetch = async (force = false) => {
        const {
            fixed,
            fetching,
            filter,
            paging,
            autoFetch,
            hasFetched,
            eternal
        } = this.state

        if((fixed || fetching || (hasFetched && !eternal)) && !force) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetched ? paging.offset + paging.limit : 0,
            limit: paging.limit
        }

        const { drafts = false } = this.props

        const path = drafts ?
            '/articles/drafts' :
            '/articles'

        const { response, ok } = await get({
            path,
            params: {
                ...filter,
                ...nextPaging
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
            this.setState(({ articles: previousArticles }) => {
                const articles = [
                    ...previousArticles,
                    ...response.items
                ]

                return {
                    articles,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        hasNextPage: response.items.length && articles.length < response.total
                    },
                    hasFetched: true,
                    autoFetch: !!previousArticles.length && hasFetched,
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                hasFetched: true,
                autoFetch: false,
                fetching: false,
                loading: false
            })
        }
    }

    setFilter = filter => {
        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }))

        const nextFilter = {
            ...(this.props.filter ?? null),
            ...filter
        }

        this.setState(({ filter: previousFilter }) => {
            const filterChanged = !isEqual(
                withoutEmptyArrays(nextFilter),
                withoutEmptyArrays(previousFilter)
            )

            if(!filterChanged) {
                return null
            }

            return {
                articles: [],
                filter: nextFilter,
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }
        })
    }

    add = async () => {
        const title = this.props.formatMessage({
            id: 'article_title_untitled',
            defaultMessage: 'Untitled article'
        })

        const { ok, response } = await post({
            path: '/articles',
            body: { title }
        })

        return { ok, response }
    }

    onIntersect = () => {
        const {
            eternal,
            loading,
            paging,
            autoFetch
        } = this.state

        if(!eternal || loading || !autoFetch || !paging?.hasNextPage) {
            return
        }

        this.fetchDebounced()
    }

    render() {
        const { children = null } = this.props

        return (
            <NewsContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </NewsContext.Provider>
        )
    }
}

export default ({ sharedWithMeBy = [], filter = null, ...props }) => {
    const { formatMessage } = useIntl()

    const {
        sharedIn,
        ready
    } = useSharedIn(sharedWithMeBy)

    if(!ready) {
        return null
    }

    return (
        <NewsProvider
            {...props}
            filter={{
                ...filter,
                ...(sharedIn.length ? { sharedIn } : null)
            }}
            formatMessage={formatMessage} />
    )
}

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

export const useNews = () => useContext(NewsContext)