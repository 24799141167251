import styled from 'styled-components'
import { Grid } from 'components/grid'
import { FlexChildShrink } from 'components/flex'
import MessageBase from 'components/message'
import RadiobuttonsFieldBase from 'components/form/field/radiobuttons'
import StringFieldBase from 'components/form/field/string'

export const Hero = FlexChildShrink

export const Message = styled(MessageBase)`
    margin-block-end: 16px;
`

export const Heading = styled.h1`
    font-size: 24px;
    line-height: 40px;
    font-weight: 500;
`

export const Value = styled(Grid)`
    grid-template-columns: 64px 1fr;
    column-gap: 16px;
    align-items: center;
`

export const RadiobuttonsField = styled(RadiobuttonsFieldBase)`
    margin-block-end: 0;

    label {
        padding: 16px 0;

        &:not(:last-of-type) {
            box-shadow: 0 1px 0 var(--huma-color-border-default);
        }
    }
`

export const StringField = styled(StringFieldBase)`
    margin: 0;
    width: 64px;

    input[type="number"] {
        appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
`