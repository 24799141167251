import React, { Component, createContext, createRef, useContext } from 'react'
import { usePerson } from 'contexts/person'
import { post, put, remove } from 'api'

const EmergencyContactsContext = createContext()
EmergencyContactsContext.displayName = 'EmergencyContacts'

class EmergencyContactsProvider extends Component {
    constructor(props) {
        super(props)

        this.userId = props.userId
        this.flash = createRef()

        this.state = {
            emergencyContacts: props.person?.emergencyContacts?.value ?? [],
            ...this.getAccess(),

            flash: this.flash,
            clearFlash: this.clearFlash,

            addEmergencyContact: this.add,
            updateEmergencyContact: this.update,
            removeEmergencyContact: this.remove
        }
    }

    componentDidUpdate(props, { viewable, editable }) {
        const access = this.getAccess()

        const viewableChanged = access?.viewable !== viewable
        const editableChanged = access?.editable !== editable

        if(viewableChanged || editableChanged) {
            this.setState(access)
        }
    }

    add = async body => {
        const { ok, response: emergencyContact } = await post({
            path: `/users/${this.userId}/emergency`,
            body
        })

        if(ok && emergencyContact) {
            this.flash.current = emergencyContact

            this.setState(({ emergencyContacts }) => ({
                emergencyContacts: [
                    emergencyContact,
                    ...emergencyContacts
                ]
            }))
        }

        return { ok, response: emergencyContact }
    }

    update = async (body, emergencyContactId) => {
        const { ok, response: emergencyContact } = await put({
            path: `/users/${this.userId}/emergency/${emergencyContactId}`,
            body
        })

        if(ok && emergencyContact) {
            this.setState(({ emergencyContacts }) => {
                const index = emergencyContacts.findIndex(({ id }) => id === emergencyContactId)
                this.flash.current = emergencyContact

                return {
                    emergencyContacts: [
                        ...emergencyContacts.slice(0, index),
                        emergencyContact,
                        ...emergencyContacts.slice(index + 1, emergencyContacts.length)
                    ]
                }
            })
        }

        return { ok, response: emergencyContact }
    }

    remove = async emergencyContactId => {
        const { ok } = await remove({
            path: `/users/${this.userId}/emergency/${emergencyContactId}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ emergencyContacts }) => {
                emergencyContacts = emergencyContacts.filter(({ id }) => id !== emergencyContactId)
                return { emergencyContacts }
            })
        }

        return { ok }
    }

    clearFlash = () => this.setState(({ emergencyContacts }) => {
        this.flash.current = null

        return {
            emergencyContacts: [...emergencyContacts]
        }
    })

    getAccess = () => {
        const field = this.props.person?.emergencyContacts

        const {
            viewable = !!field,
            editable = false
        } = field ?? {}

        return {
            viewable,
            editable
        }
    }

    render() {
        const { children = null } = this.props

        return (
            <EmergencyContactsContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </EmergencyContactsContext.Provider>
        )
    }
}

export const useEmergencyContacts = () => useContext(EmergencyContactsContext)

export default props => {
    const { person } = usePerson()

    return (
        <EmergencyContactsProvider
            {...props}
            person={person} />
    )
}