import React, { Component, createContext, useContext } from 'react'
import { get, post } from 'api'
import { prune } from 'utilities/array'
import { rolesOrder } from 'pages/settings/pages/roles/utilities'
import PubSub from 'pubsub-js'

const RolesByUserContext = createContext()
RolesByUserContext.displayName = 'RolesByUser'

class RolesByUserProvider extends Component {
    constructor(props) {
        super(props)

        this.fetchController = new AbortController()

        this.setId(props.id)

        this.state = {
            roles: [],
            total: 0,

            addGrant: this.addGrant,

            updateUnitsLocally: this.updateUnitsLocally,

            fetching: false,
            hasFetched: false
        }

        this.refreshSubscription = PubSub.subscribe('rolesByUser.refresh', (_, id) => {
            let affected = true

            this.setState(() => {
                if(this.id !== id) {
                    affected = false
                    return null
                }

                return {
                    roles: [],
                    hasFetched: false
                }
            }, () => {
                affected && this.fetch()
            })
        })
    }

    componentDidMount() {
        const {
            fetchAccess = true,
            fetchOnMount = true
        } = this.props

        if(fetchAccess && fetchOnMount) {
            this.fetch()
        }
    }

    componentDidUpdate({ id }) {
        if(id !== this.props.id) {
            this.replace(this.props.id)
        }
    }

    componentWillUnmount() {
        this.fetchController.abort()
        PubSub.unsubscribe(this.refreshSubscription)
    }

    fetch = async (force = false) => {
        if(!this.id || (this.state.fetching && !force)) {
            return
        }

        if(force) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({ fetching: true })

        const { ok, response } = await get({
            path: `/roles/users/${this.id}`,
            signal: this.fetchController.signal
        })

        if(ok && response) {
            let roles = response.items

            roles = prune([
                ...roles
                    .filter(({ role }) => !role.updatable)
                    .sort(({ role: one }, { role: two }) => {
                        return rolesOrder.indexOf(one.name) - rolesOrder.indexOf(two.name)
                    }), // Locked roles
                ...roles.filter(({ role }) => role.assignable && role.updatable && !role.deletable), // Unlocked roles (previously locked)
                roles.find(({ role }) => role.name === 'supervisor'),
                ...roles.filter(({ role }) => !role.assignable),
                ...roles.filter(({ role }) => role.assignable && role.updatable && role.deletable)
            ])

            this.setState({
                roles,
                total: response.total,
                hasFetched: true,
                fetching: false
            })
        } else {
            this.setState({ fetching: false })
        }
    }

    addGrant = async ({ roleId, body }) => {
        const { ok, response: grants } = await post({
            path: `/roles/${roleId}/grants`,
            body
        })

        if(ok && grants) {
            PubSub.publish('rolesByUser.refresh', this.id)
        }

        return { ok }
    }

    updateUnitsLocally = ({ roleId, units = [] }) => {
        this.setState(({ roles }) => {
            const index = roles.findIndex(({ role }) => role.id === roleId)

            const role = roles[index]
            role.units = units

            return {
                roles: roles.with(index, role)
            }
        })
    }

    setId = id => this.id = id

    replace = id => {
        this.setId(id)

        this.setState({ roles: [] }, () => {
            this.fetch(true)
        })
    }

    render() {
        const { children = null } = this.props

        return (
            <RolesByUserContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </RolesByUserContext.Provider>
        )
    }
}

export const useRolesByUser = () => useContext(RolesByUserContext)

export default RolesByUserProvider