import React, { Component, createContext, useContext } from 'react'
import { useAccess } from 'contexts/access'
import { useMe } from 'contexts/me'
import { get, post, patch, remove } from 'api'
import { unique, without, compact } from 'utilities/array'
import { reduce } from 'utilities/object'
import { categoriesInOrder } from 'utilities/categories'
import { validate as uuidValidate, version as uuidVersion } from 'uuid'

const isUuid = uuid => uuidValidate(uuid) && uuidVersion(uuid) === 4

const RoleContext = createContext()
RoleContext.displayName = 'Role'

class RoleProvider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            role: null,
            domain: props?.domain,
            permissions: {},
            availablePermissions: {},
            deleted: false,

            fetchRole: this.fetch,
            updateRole: this.update,
            removeRole: this.remove,

            addGrant: this.addGrant,
            removeGrant: this.removeGrant,

            addPermissions: this.addPermissions,
            removePermissions: this.removePermissions
        }
    }

    componentDidMount() {
        const {
            id,
            fetchOnMount = true,
            fetchNamedOnMount = true
        } = this.props

        // If the id is not a uuid, we need to fetch the role
        // in order to get it for the non-read CRUD operations
        if(fetchOnMount || (!!id && !isUuid(id) && fetchNamedOnMount)) {
            this.fetch()
        }
    }

    getRoleContext = () => ({
        domain: this.state.role?.domain ?? this.props.domain,
        id: this.state.role?.id ?? this.props.id
    })

    fetch = async () => {
        const {
            domain,
            id
        } = this.getRoleContext()

        if(!domain || !id) {
            return { ok: false }
        }

        return new Promise((resolve, reject) => {
            const fetch = async () => {
                const [
                    { ok: roleOk, response: role },
                    { ok: permissionsOk, response: availablePermissions }
                ] = await Promise.all([
                    get({ path: `/roles/${id}` }),
                    get({
                        path: `/roles/permissions`,
                        params: { domain }
                    })
                ])

                if(roleOk && permissionsOk) {
                    const composedPermissions = composePermissions(role, availablePermissions)

                    return void this.setState({
                        role,
                        availablePermissions,
                        permissions: composedPermissions
                    }, () => resolve({ ok: roleOk, response: role }))
                }

                reject({ ok: false, response: null })
            }

            fetch()
        }).catch(() => {})
    }

    update = async body => {
        const {
            domain,
            id
        } = this.getRoleContext()

        const { ok, response: role } = await patch({
            path: `/roles/${id}`,
            body: {
                ...body,
                domain
            }
        })

        if(ok) {
            this.setState(({ role: previousRole }) => ({
                role: {
                    ...role,
                    grants: previousRole.grants
                }
            }))
        }

        return { ok }
    }

    remove = async () => {
        const { id } = this.getRoleContext()

        const {
            isItMyOwnId,
            refreshPermissions
        } = this.props

        const { ok } = await remove({
            path: `/roles/${id}`,
            returnsData: false
        })

        if(ok) {
            this.setState({ deleted: true }, () => {
                if(this.state.role?.grants) {
                    const isGrantee = this.state.role.grants
                        .flatMap(({ user }) => user)
                        .map(({ id }) => isItMyOwnId(id))
                        .some(Boolean)

                    isGrantee && refreshPermissions()
                }
            })
        }
    }

    addGrant = async ({ userIds, ...body }) => {
        const {
            domain,
            id
        } = this.getRoleContext()

        const {
            isItMyOwnId,
            refreshPermissions
        } = this.props

        const { ok, response: grants, status } = await post({
            path: `/roles/${id}/grants`,
            body: {
                ...body,
                userIds,
                domain
            }
        })

        if(ok && grants) {
            this.setState(({ role }) => ({
                role: {
                    ...role,
                    grants
                }
            }), () => {
                const isGrantee = userIds
                    .map(id => isItMyOwnId(id))
                    .some(Boolean)

                isGrantee && refreshPermissions()
            })
        }

        return { ok: ok || status === 409 }
    }

    removeGrant = async ({ userId, ...body }) => {
        const {
            domain,
            id
        } = this.getRoleContext()

        const {
            isItMyOwnId,
            refreshPermissions
        } = this.props

        const { ok, response: grants } = await remove({
            path: `/roles/${id}/grants`,
            body: {
                ...body,
                userId,
                domain
            }
        })

        if(ok && grants) {
            this.setState(({ role }) => ({
                role: {
                    ...role,
                    grants
                }
            }), () => {
                isItMyOwnId(userId) && refreshPermissions()
            })
        }

        return { ok }
    }

    addPermissions = async permissions => {
        const { id } = this.getRoleContext()

        const {
            isItMyOwnId,
            refreshPermissions
        } = this.props

        const updatedPermissions = unique([
            ...(this.state.role?.permissions ?? []),
            ...permissions
        ])

        this.optimisticallyUpdatePermissions(updatedPermissions)

        const { ok } = await post({
            path: `/roles/${id}/permissions`,
            body: { permissions },
            returnsData: false
        })

        if(ok && this.state.role?.grants) {
            const isGrantee = this.state.role.grants
                .flatMap(({ user }) => user)
                .map(({ id }) => isItMyOwnId(id))
                .some(Boolean)

            isGrantee && refreshPermissions()
        }

        return this.updateOrRevertRolePermissions(ok, updatedPermissions)
    }

    removePermissions = async permissions => {
        const { id } = this.getRoleContext()

        const {
            isItMyOwnId,
            refreshPermissions
        } = this.props

        const updatedPermissions = without(this.state.role?.permissions ?? [], permissions)
        this.optimisticallyUpdatePermissions(updatedPermissions)

        const { ok } = await remove({
            path: `/roles/${id}/permissions`,
            body: { permissions },
            returnsData: false
        })

        if(ok && this.state.role?.grants) {
            const isGrantee = this.state.role.grants
                .flatMap(({ user }) => user)
                .map(({ id }) => isItMyOwnId(id))
                .some(Boolean)

            isGrantee && refreshPermissions()
        }

        return this.updateOrRevertRolePermissions(ok, updatedPermissions)
    }

    optimisticallyUpdatePermissions = updatedPermissions => void this.setState(({ role, availablePermissions }) => {
        if(!role) {
            return null
        }

        return {
            permissions: composePermissions({
                ...role,
                permissions: updatedPermissions
            }, availablePermissions)
        }
    })

    updateOrRevertRolePermissions = (ok, updatedPermissions) => {
        const { role } = this.state
        if(!role) {
            return false
        }

        if(!ok) {
            this.setState(({ role, availablePermissions }) => ({
                permissions: composePermissions(role, availablePermissions)
            }))
        }

        this.setState(({ role }) => ({
            role: {
                ...role,
                permissions: updatedPermissions
            }
        }))

        return ok
    }

    render() {
        const { children = null } = this.props

        return (
            <RoleContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </RoleContext.Provider>
        )
    }
}

const formatPermissions = (actions, rolePermissions) => {
    const options = reduce(actions, (accumulator, action, type, index) => {
        // Prepend disabled 'None' option.
        // If the first action is true (the boolean), it means that the next explicit
        // option is the lowest possible level.
        if(index === 0) {
            const disabled = typeof action === 'boolean'

            accumulator = [
                ...accumulator,
                {
                    // Leave the value like this so the <select value> doesn’t
                    // get two empty option values to match its value against
                    value: disabled ? 'none' : '',
                    i18n: 'role_permission_none',
                    disabled,
                    type: 'none'
                }
            ]
        }

        const accumulativeValues = Object.values(actions)
            .slice(0, index + 1)
            .map(value => typeof value === 'string' ? value : null)

        const accumulativeKeys = Object.keys(actions).slice(0, index + 1)

        return [
            ...accumulator,
            {
                type,
                value: compact(accumulativeValues).join('+'),
                i18n: `role_permission_${accumulativeKeys.join('_')}`
            }
        ]
    }, [])

    const value = Object.values(actions)
        .filter(action => typeof action === 'string')
        .filter(action => rolePermissions.includes(action))
        .join('+') || ''

    return {
        value,
        options
    }
}

export const composePermissions = (role = {}, availablePermissions = {}) => {
    const composed = reduce(availablePermissions, (accumulator, availablePermissions, realm) => {
        const filteredAvailablePermissions = !role.deletable ?
            availablePermissions.filter(({ excludeRoleNames }) => !excludeRoleNames?.length || !excludeRoleNames?.includes(role.name)) :
            availablePermissions

        const humaAvailablePermissions = filteredAvailablePermissions.filter(({ actions }) => Object.values(actions)
            .every(action => !action.startsWith?.('user:custom:'))
        )

        const customAvailablePermissions = filteredAvailablePermissions.filter(({ actions }) => Object.values(actions)
            .some(action => action.startsWith?.('user:custom:'))
        )

        return {
            ...accumulator,
            ...(!!humaAvailablePermissions.length ? {
                [realm]: humaAvailablePermissions.map(({ actions, ...rest }) => ({
                    ...rest,
                    ...formatPermissions(actions, role.permissions ?? [])
                }))
            } : null),
            ...(!!customAvailablePermissions.length ? {
                [`${realm}_custom`]: customAvailablePermissions.map(({ actions, ...rest }) => ({
                    ...rest,
                    ...formatPermissions(actions, role.permissions ?? [])
                }))
            } : null)
        }
    }, {})

    const sorted = Object.entries(composed)
        .sort(([one], [two]) => {
            const oneIndex = categoriesInOrder.indexOf(one)
            const twoIndex = categoriesInOrder.indexOf(two)

            if(!!~oneIndex && !~twoIndex) {
                return -1
            } else if(!~oneIndex && !!~twoIndex) {
                return 1
            } else if(!~oneIndex && !~twoIndex) {
                return 0
            }

            return oneIndex - twoIndex
        })

    return Object.fromEntries(sorted)
}

export default props => {
    const { refreshPermissions } = useAccess()
    const { isItMyOwnId } = useMe()

    return (
        <RoleProvider
            {...props}
            isItMyOwnId={isItMyOwnId}
            refreshPermissions={refreshPermissions} />
    )
}

export const useRole = () => useContext(RoleContext)