import React, { Component, createContext, useContext, useState } from 'react'

const ModalContext = createContext()
ModalContext.displayName = 'Modal'

class ModalProvider extends Component {
    constructor(props) {
        super(props)

        const dismiss = props.dismiss ?? (() => {})

        this.state = {
            dismiss,

            dirty: false,
            setDirty: this.setDirty
        }
    }

    setDirty = dirty => void this.setState({ dirty })

    render() {
        const { children = null } = this.props

        return (
            <ModalContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </ModalContext.Provider>
        )
    }
}

export const useModal = () => useContext(ModalContext)

export const useDirty = () => {
    const modal = useModal()
    const localDirty = useState(false)

    return !!modal ?
        [modal.dirty, modal.setDirty] :
        localDirty
}

export default ModalProvider