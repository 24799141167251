import React from 'react'
import styled from 'styled-components'
import { belowTablet, fromLaptop } from 'utilities/styled'
import { H4 } from 'components/typography/heading'
import { Grid } from 'components/grid'
import { paragraphCss } from 'components/typography/paragraph'
import { h3Css } from 'components/typography/heading'
import { Plain } from 'components/button'

export const Agenda = styled.div`
    margin-block-end: 24px;
    width: 100%;
`

const HeadingWrap = styled.div`
    position: relative;
    z-index: 1;

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        z-index: -1;

        border-top: 1px solid var(--huma-color-border-default);
        width: 100%;
    }
`

const HeadingElement = styled(H4)`
    display: inline-flex;

    padding-inline-end: 16px;
    background-color: var(--huma-color-surface-default);

    font-size: 16px;
    line-height: 24px;
`

export const Heading = props => (
    <HeadingWrap>
        <HeadingElement {...props} />
    </HeadingWrap>
)

export const Points = styled(Grid)`
    grid-auto-rows: auto;
    grid-auto-flow: row;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: 40px;

    padding-block: 20px;

    &.small {
        grid-row-gap: 24px;
    }
`

export const Point = styled.div`
    position: relative;

    [data-rbd-droppable-id] & {
        margin-block-end: 40px;

        ${Points}.small & {
            margin-block-end: 24px;
        }
    }

    &.is-dragging::before {
        content: "";
        position: absolute;
        inset: -16px;

        border-radius: 8px;
        background-color: var(--huma-color-surface-elevated);
        box-shadow: var(--huma-shadow-lowest);
    }
`

export const PointHeader = styled(Grid)`
    --drag: [drag] 24px;
    --ordinal: [ordinal] 32px;
    --title: [title] minmax(0, 1fr);
    --actions: [actions] 40px;

    grid-template-columns:
        var(--ordinal)
        var(--title);
    align-items: start;
    column-gap: 24px;

    width: 100%;

    ${Point}.has-drag-handle & {
        grid-template-columns:
            var(--drag)
            var(--ordinal)
            var(--title);

        ${belowTablet`
            margin-inline-start: -24px;
            width: calc(100% + 24px);

            column-gap: unset;
        `}

        ${fromLaptop`
            margin-inline-start: -48px;
            width: calc(100% + 48px);
        `}
    }

    ${Point}.has-actions & {
        grid-template-columns:
            var(--ordinal)
            var(--title)
            var(--actions);
    }

    ${Point}.has-actions.has-drag-handle & {
        grid-template-columns:
            var(--drag)
            var(--ordinal)
            var(--title)
            var(--actions);
    }

    ${belowTablet`
        grid-auto-flow: row;
        grid-auto-rows: auto;
        row-gap: 8px;
    `}
`

export const DragColumn = styled.div`
    grid-column: drag;

    padding-block-start: 8px;

    color: var(--huma-color-foreground-minimal);

    &.disabled {
        cursor: not-allowed;

        color: var(--huma-color-foreground-disabled);
    }

    &:empty {
        display: none;
    }
`

export const AddPoint = styled(Grid)`
    grid-template-columns: [ordinal] 32px [title] minmax(0, 1fr);
    align-items: start;
    column-gap: 24px;
    width: 100%;

    ${belowTablet`
        grid-template-columns: [ordinal] 32px [title] 1fr;
        grid-auto-rows: auto;
        row-gap: 8px;

        &.has-actions {
            grid-template-columns: [ordinal] 32px [title] 1fr [actions] 40px;
        }
    `}
`

export const PointOrdinal = styled.p`
    position: relative;
    grid-column: ordinal;

    border-radius: 100%;
    width: 32px;
    height: 32px;
    background-color: var(--huma-color-surface-constructive-minimal);

    transition: all .1s ease-in-out;

    ${paragraphCss}
    text-align: center;
    line-height: 32px;
    color: var(--huma-color-foreground-constructive-bold);

    ${Points}:not(.small) &,
    ${Point}.is-dragging & {
        margin-block-start: 4px;
    }

    &.inactive {
        background-color: var(--huma-color-surface-disabled);

        color: var(--huma-color-foreground-disabled);
    }
`

export const PointTitle = styled.p`
    position: relative;

    grid-column: title;

    margin: 0;

    word-break: break-word;
    white-space: pre-wrap;
    overflow-wrap: anywhere;

    ${Points}.small & {
        padding-block-start: 4px;

        line-height: 1.5;
    }

    ${Points}:not(.small) &,
    ${Point}.is-dragging & {
        ${h3Css}

        padding-block-start: 8px;

        line-height: 1.2;
    }

    ${Point}.has-drag-handle & {
        ${belowTablet`
            margin-inline: 24px;
        `}
    }
`

export const PointActions = styled.div`
    grid-column: actions;
`

export const PointContent = styled.div`
    position: relative;

    padding: 8px 0 0 56px;
`

export const Notes = styled.p`
    ${paragraphCss}

    border-radius: 0 16px 16px 16px;
    padding: 8px 16px;
    background-color: var(--huma-color-surface-minimal);

    white-space: pre-wrap;
    word-break: break-word;
`

export const AddFromTemplateButton = styled(Plain).attrs({ className: 'constructive' })`
    margin-inline-start: 56px;
`