import React, { Component, createContext, useContext } from 'react'
import { get, patch, remove } from 'api'
import PubSub from 'pubsub-js'

const EquipmentTypeContext = createContext()
EquipmentTypeContext.displayName = 'EquipmentType'

export default class EquipmentTypeProvider extends Component {
    constructor(props) {
        super(props)

        this.setId(props.id)

        this.state = {
            type: null,
            deleted: false,

            fetchType: this.fetch,
            updateType: this.update,
            removeType: this.remove
        }

        PubSub.subscribe('equipmentType.refresh', () => this.fetch())
    }

    componentDidMount() {
        this.fetch()
    }

    componentDidUpdate({ id }) {
        if(id !== this.props.id) {
            this.setId(this.props.id)
            this.replace()
        }
    }

    componentWillUnmount() {
        PubSub.unsubscribe('equipmentType.refresh')
    }

    setId = id => this.id = id

    fetch = async () => {
        const { response: type, ok } = await get({ path: `/equipment-types/${this.id}` })
        !!ok && this.setState({ type })
    }

    update = async (body, id) => {
        const { ok, response: type } = await patch({
            path: `/equipment-types/${id}`,
            body
        })

        if(ok && type) {
            this.setState({ type })
        }

        return { ok, response: type }
    }

    remove = async (id, params) => {
        const { ok } = await remove({
            path: `/equipment-types/${id}`,
            params,
            returnsData: false
        })

        !!ok && this.setState({ deleted: true })

        return { ok }
    }

    replace = () => this.setState({ type: null }, this.fetch)

    render() {
        const { children = null } = this.props

        return (
            <EquipmentTypeContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </EquipmentTypeContext.Provider>
        )
    }
}

export const useEquipmentType = () => useContext(EquipmentTypeContext)