import React, { Component, createContext, useContext, createRef } from 'react'
import { useAccess } from 'contexts/access'
import { get, post, patch, put, remove } from 'api'
import { pick, omit, reduce, size } from 'utilities/object'
import { sequentially } from 'utilities/async'
import { throttle } from 'utilities/function'
import { enrichEvent } from 'pages/meetings/utilities'
import isEqual from 'react-fast-compare'

const MeetingEventContext = createContext()
MeetingEventContext.displayName = 'MeetingEvent'

class MeetingEventProvider extends Component {
    constructor(props) {
        super(props)

        this.setId(props.id)
        this.setChanged = throttle(this.setChanged, 250)
        this.flash = createRef()

        this.state = {
            event: null,
            remoteEvent: null,
            errors: null,
            changed: false,
            saving: false,
            deleted: false,

            fetchEvent: this.fetch,
            setChanged: this.setChanged,
            updateEvent: this.update,
            saveEvent: this.save,
            changeStatus: this.changeStatus,
            removeEvent: this.remove,

            addAgendaPoint: this.addAgendaPoint,
            updateAgendaPoint: this.updateAgendaPoint,
            removeAgendaPoint: this.removeAgendaPoint,
            addAgendaPointsFromTemplate: this.addAgendaPointsFromTemplate,

            addTask: this.addTask,
            updateTask: this.updateTask,
            assignTask: this.assignTask,
            toggleTaskCompleted: this.toggleTaskCompleted,
            removeTask: this.removeTask,

            setErrors: this.setErrors,

            flash: this.flash,
            clearFlash: this.clearFlash
        }
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        !!fetchOnMount && this.fetch()
    }

    componentDidUpdate({ id }) {
        if(id !== this.props.id) {
            this.replace(pick(this.props, 'id'))
        }
    }

    setId = id => this.id = id

    fetch = async () => {
        const { response, ok } = await get({ path: `/performance/meetings/${this.id}` })

        if(ok && response) {
            const event = enrichEvent(response)

            this.setState({
                event,
                remoteEvent: event
            })
        }
    }

    // Local update only
    update = (body, options = {}) => void this.setState(({ event }) => ({
        event: {
            ...event,
            ...omit(body, 'meetingHost')
        }
    }), () => {
        const {
            after,
            silent = false
        } = options

        if(!silent) {
            this.setChanged()
        }

        if(typeof after === 'function') {
            after()
        }
    })

    save = async (options = {}) => {
        const { silent = true } = options

        !silent && this.setState({ saving: true })

        const {
            body,
            agenda
        } = this.getChanges()

        if(!!body?.host) {
            body.host = body.host.id
        }

        if(!!body?.attendees?.length) {
            body.attendees = body.attendees.map(({ id }) => id)
        }

        if(!!body?.concerns) {
            body.concerns = body.concerns.id
        }

        let updatedEvent = null
        let updatedAgendaPoints = []

        let results = await Promise.all([
            ...(body ? [
                patch({
                    path: `/performance/meetings/${this.id}`,
                    body
                })
            ] : []),
            ...(agenda?.map(({ id, ...body }) => patch({
                path: `/performance/meetings/${this.id}/points/${id}`,
                body: getTieredAgendaPoint(body, this.props.access)
            })) ?? [])
        ])

        const ok = results.every(({ ok }) => ok)
        if(ok) {
            results = results.map(({ response }) => response)

            if(body) {
                updatedEvent = results.shift().response
            }

            updatedAgendaPoints = results

            this.setState(({ event: previousEvent }) => {
                const nextEvent = {
                    ...(updatedEvent ?
                        enrichEvent(updatedEvent) :
                        previousEvent
                    ),
                    agenda: (previousEvent.agenda ?? []).map(agendaPoint => {
                        const updatedAgendaPoint = updatedAgendaPoints.find(({ id }) => id === agendaPoint.id)
                        return updatedAgendaPoint ?? agendaPoint
                    }),
                    tasks: previousEvent.tasks ?? []
                }

                return {
                    event: nextEvent,
                    remoteEvent: nextEvent,
                    changed: false,
                    ...(!silent ? { saving: false } : null)
                }
            })
        } else if(!silent) {
            this.setState({ saving: false })
        }

        return { ok }
    }

    // Local and remote update
    remove = async () => {
        const { ok } = await remove({
            path: `/performance/meetings/${this.id}`,
            returnsData: false
        })

        !!ok && this.setState({
            changed: false,
            deleted: true
        })

        return { ok }
    }

    // Local and remote update
    changeStatus = async body => {
        this.setState({ saving: true })

        let ok = false

        const { ok: saveOk } = await this.save({ silent: true })
        if(saveOk) {
            const result = await post({
                path: `/performance/meetings/${this.id}/status`,
                body
            })

            ok = result.ok
        }

        if(ok) {
            this.setState(({ event }) => {
                event = {
                    ...event,
                    ...body
                }

                return {
                    event,
                    remoteEvent: event,
                    saving: false
                }
            })
        } else {
            this.setState({ saving: false })
        }

        return { ok }
    }

    setErrors = errors => void this.setState({ errors })

    // Local and remote update
    addAgendaPoint = async ({ body, flash = true }) => {
        this.setState({ saving: true })

        const { response: agendaPoint, ok } = await post({
            path: `/performance/meetings/${this.id}/points`,
            body
        })

        this.setState(({ event, remoteEvent }) => {
            if(flash) {
                this.flash.current = agendaPoint
            }

            return {
                ...((ok && agendaPoint) ? {
                    event: {
                        ...event,
                        agenda: [
                            ...event.agenda,
                            agendaPoint
                        ]
                    },
                    remoteEvent: {
                        ...remoteEvent,
                        agenda: [
                            ...remoteEvent.agenda,
                            agendaPoint
                        ]
                    }
                } : null),
                saving: false
            }
        })

        return { ok, response: agendaPoint }
    }

    // Local and optional remote update
    updateAgendaPoint = async ({ body, id, remote = false }) => {
        let ok = true

        let response = {
            ...this.state.event.agenda.find(point => point.id === id),
            ...body
        }

        if(remote) {
            const result = await patch({
                path: `/performance/meetings/${this.id}/points/${id}`,
                body: getTieredAgendaPoint(body, this.props.access)
            })

            ok = result.ok
            response = result.response
        }

        this.setState(({ event, remoteEvent }) => {
            const index = event.agenda.findIndex(point => point.id === id)

            const state = {
                event: {
                    ...event,
                    agenda: [
                        ...event.agenda.slice(0, index),
                        response,
                        ...event.agenda.slice(index + 1, event.agenda.length)
                    ]
                }
            }

            if(remote && ok) {
                state.remoteEvent = {
                    ...remoteEvent,
                    agenda: [
                        ...remoteEvent.agenda.slice(0, index),
                        response,
                        ...remoteEvent.agenda.slice(index + 1, remoteEvent.agenda.length)
                    ]
                }
            }

            return state
        }, !remote ? this.setChanged : null)

        return { ok, response }
    }

    // Local and remote update
    removeAgendaPoint = async id => {
        this.setState({ saving: true })

        const { ok } = await remove({
            path: `/performance/meetings/${this.id}/points/${id}`,
            returnsData: false
        })

        this.setState(({ event, remoteEvent }) => {
            const filter = point => point.id !== id

            return {
                ...(ok ? {
                    event: {
                        ...event,
                        agenda: event.agenda.filter(filter)
                    },
                    remoteEvent: {
                        ...remoteEvent,
                        agenda: remoteEvent.agenda.filter(filter)
                    }
                } : null),
                saving: false
            }
        }, this.setChanged)

        return { ok }
    }

    // Local and remote update
    addAgendaPointsFromTemplate = async selectedTemplate => {
        let ok = false

        if(!!selectedTemplate?.agenda?.length) {
            const results = await sequentially(
                selectedTemplate.agenda,
                body => this.addAgendaPoint({ body, flash: false })
            )

            ok = results.every(({ ok }) => ok)
        }

        return { ok }
    }

    // Local and remote update
    addTask = async ({ body }) => {
        const { ok, response: task } = await post({
            path: `/performance/meetings/${this.id}/tasks`,
            body: {
                ...body,
                notify: true
            }
        })

        if(ok && task) {
            this.setState(({ event, remoteEvent }) => {
                this.flash.current = task

                // These are always in sync, so just use the local state as the source
                const tasks = [
                    ...(event?.tasks ?? []),
                    task
                ]

                return {
                    event: { ...event, tasks },
                    remoteEvent: { ...remoteEvent, tasks }
                }
            })
        }

        return { ok, response: task }
    }

    updateTask = async ({ body, id }) => {
        const { event } = this.state
        const taskToUpdate = event.tasks.find(task => task.id === id)

        const wasAssignedTo = taskToUpdate?.assignedTo?.id ?? taskToUpdate?.assignedTo
        const assignedTo = body.assignedTo?.id ?? body.assignedTo

        body = {
            ...body,
            assignedTo,
            notify: assignedTo && wasAssignedTo !== assignedTo
        }

        const { ok, response: task } = await patch({
            path: `/performance/meetings/${this.id}/tasks/${id}`,
            body
        })

        if(ok && task) {
            this.setState(({ event, remoteEvent }) => {
                const index = event.tasks.findIndex(task => task.id === id)

                // These are always in sync, so just use the local state as the source
                const tasks = [
                    ...event.tasks.slice(0, index),
                    task,
                    ...event.tasks.slice(index + 1, event.tasks.length)
                ]

                return {
                    event: { ...event, tasks },
                    remoteEvent: { ...remoteEvent, tasks }
                }
            })
        }

        return { ok, response: task }
    }

    assignTask = (id, assignedTo) => this.updateTask({
        body: { assignedTo },
        id
    })

    toggleTaskCompleted = async taskId => {
        const { event } = this.state
        const index = event.tasks.findIndex(({ id }) => id === taskId)
        let task = event.tasks[index]

        const { response, ok } = await put({ path: `/tasks/${taskId}/togglecompleted` })

        if(ok) {
            task = {
                ...task,
                ...response
            }

            this.setState(({ event, remoteEvent }) => {
                // These are always in sync, so just use the local state as the source
                const tasks = [
                    ...event.tasks.slice(0, index),
                    task,
                    ...event.tasks.slice(index + 1, event.tasks.length)
                ]

                return {
                    event: { ...event, tasks },
                    remoteEvent: { ...remoteEvent, tasks }
                }
            })
        }

        return { ok, response: task }
    }

    removeTask = async body => {
        const { ok } = await remove({
            path: `/tasks/${body.id}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ event, remoteEvent }) => {
                // These are always in sync, so just use the local state as the source
                const tasks = event.tasks.filter(task => task.id !== body.id)

                return {
                    event: { ...event, tasks },
                    remoteEvent: { ...remoteEvent, tasks }
                }
            })
        }

        return { ok }
    }

    getChanges = () => {
        const {
            event,
            remoteEvent
        } = this.state

        const body = omit(event, 'agenda', 'tasks')
        const remoteBody = omit(remoteEvent, 'agenda', 'tasks')

        const changedBody = reduce(body, (accumulator, value, key) => {
            const different = ['date', 'description'].includes(key) ?
                areDifferentAndNotBothFalsy(value, remoteBody[key]) :
                !isEqual(value, remoteBody[key])

            if(!different) {
                return accumulator
            }

            return {
                ...accumulator,
                [key]: value
            }
        })

        const { agenda } = event
        const { agenda: remoteAgenda } = remoteEvent

        const changedAgenda = (agenda ?? []).reduce((accumulator, point) => [
            ...accumulator,
            ...(areAgendaPointsDifferent(
                point,
                remoteAgenda.find(({ id }) => point.id === id) ?? {}
            ) ? [point] : [])
        ], [])

        return {
            body: size(changedBody) ? changedBody : null,
            agenda: changedAgenda,
            changed: !!size(changedBody) || !!changedAgenda.length
        }
    }

    setChanged = () => void this.setState(pick(this.getChanges(), 'changed'))

    replace = id => {
        this.setId(id)

        this.setState({
            event: null,
            remoteEvent: null,
            changed: false
        }, this.fetch)
    }

    render() {
        const { children = null } = this.props

        return (
            <MeetingEventContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </MeetingEventContext.Provider>
        )
    }
}

const areDifferentAndNotBothFalsy = (one, two) => one !== two && !(!one && !two)

const areAgendaPointsDifferent = (one, two) => {
    const {
        notes: oneNotes,
        ...oneRest
    } = one

    const {
        notes: twoNotes,
        ...twoRest
    } = two

    const notesAreDifferent = areDifferentAndNotBothFalsy(oneNotes, twoNotes)
    const restIsDifferent = !isEqual(oneRest, twoRest)

    return notesAreDifferent || restIsDifferent
}

const getTieredAgendaPoint = (point, access) => access.checkFeature('meeting-agenda-points') ?
    point :
    omit(point, 'title')

export default props => {
    const access = useAccess()

    return (
        <MeetingEventProvider
            {...props}
            access={access} />
    )
}

export const useMeetingEvent = () => useContext(MeetingEventContext)