import React, { Component, createContext, createRef, useContext } from 'react'
import { get, post, patch, remove } from 'api'

const CustomProfileFieldsContext = createContext()
CustomProfileFieldsContext.displayName = 'CustomProfileFields'

export default class CustomProfileFieldsProvider extends Component {
    constructor(props) {
        super(props)

        this.flash = createRef(null)

        this.state = {
            fields: [],
            sections: {},

            flash: this.flash,
            clearFlash: this.clearFlash,

            fetchFields: this.fetch,
            addField: this.add,
            updateField: this.update,
            removeField: this.remove,

            fetching: false,
            hasFetched: false
        }
    }

    componentDidMount() {
        const {
            fetchAccess = true,
            fetchOnMount = true
        } = this.props

        if(fetchAccess && fetchOnMount) {
            this.fetch()
        }
    }

    componentDidUpdate(props) {
        const { fetchAccess: previousFetchAccess = true } = props
        const { fetchAccess = true } = this.props
        const fetchAccessGained = !previousFetchAccess && fetchAccess

        if(fetchAccessGained) {
            this.fetch()
        }
    }

    fetch = async () => {
        this.setState({ fetching: true })

        const { ok, response } = await get({ path: '/users/custom-fields' })

        this.setState({
            ...(ok ? {
                sections: getFieldsBySection(response.items),
                fields: response.items
            } : null),
            fetching: false,
            hasFetched: true
        })
    }

    add = async body => {
        const { ok, response: field } = await post({
            path: '/users/custom-fields',
            body
        })

        if(ok && field) {
            this.flash.current = field

            this.setState(({ fields: previousFields }) => {
                const fields = [
                    field,
                    ...previousFields
                ]

                return {
                    sections: getFieldsBySection(fields),
                    fields
                }
            })
        }

        return { ok, response: field }
    }

    update = async (body, id) => {
        const { ok, response: field } = await patch({
            path: `/users/custom-fields/${id}`,
            body
        })

        if(ok && field) {
            this.setState(({ fields: previousFields }) => {
                this.flash.current = field

                const index = previousFields.findIndex(field => field.id === id)
                const fields = [
                    ...previousFields.slice(0, index),
                    field,
                    ...previousFields.slice(index + 1, previousFields.length)
                ]

                return {
                    sections: getFieldsBySection(fields),
                    fields
                }
            })
        }

        return { ok, response: field }
    }

    remove = async id => {
        const { ok } = await remove({
            path: `/users/custom-fields/${id}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ fields: previousFields }) => {
                const fields = previousFields.filter(field => field.id !== id)

                return {
                    sections: getFieldsBySection(fields),
                    fields
                }
            })
        }

        return { ok }
    }

    clearFlash = () => void this.setState(({ fields }) => {
        this.flash.current = null

        return {
            fields: [...fields]
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <CustomProfileFieldsContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </CustomProfileFieldsContext.Provider>
        )
    }
}

const getFieldsBySection = fields => fields.reduce((accumulator, field) => {
    if(!(field.section in accumulator)) {
        accumulator[field.section] = []
    }

    accumulator[field.section].push(field)
    return accumulator
}, {
    intro: [],
    employment: [],
    personalia: []
})

export const useCustomProfileFields = () => useContext(CustomProfileFieldsContext)