import React, { Component, createContext, useContext } from 'react'
import { useEnvironment } from 'contexts/environment'
import { useOrganization } from 'contexts/organization'
import { post } from 'api'
import { v4 as uuid } from 'uuid'
import { local } from 'utilities/storage'
import { compact } from 'utilities/array'

const ContentAssistantContext = createContext()
ContentAssistantContext.displayName = 'ContentAssistant'

class ContentAssistantProvider extends Component {
    constructor(props) {
        super(props)

        this.fetchController = new AbortController()

        this.systemUUID = uuid()

        this.state = {
            settings: local.get('content-assistant:settings'),
            dialogue: this.props.dialogue ?? [], // What’s being communicated with the API
            messages: this.props.messages ?? [], // What’s being displayed to the user

            promptAssistant: this.promptAssistant,
            retryLatestPrompt: this.retryLatestPrompt,
            abortPrompt: this.abortPrompt,
            appendPrompt: this.appendPrompt,
            removePrompt: this.removePrompt,
            resetDialogue: this.reset,

            appendMessage: this.appendMessage,

            userAborted: false,
            loading: false
        }
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetch()
    }

    componentWillUnmount() {
        this.fetchController.abort()
    }

    setSystemPrompt = () => {
        this.setState({
            dialogue: [
                {
                    id: this.systemUUID,
                    role: 'system',
                    content: cleanPrompt({
                        prompt: this.state.settings?.baseContext,
                        values: {
                            orgName: this.props.organization?.name
                        }
                    })
                }
            ]
        })
    }

    fetch = async () => {
        if(this.state.settings) {
            return void this.setSystemPrompt()
        }

        const { ok, response: settings } = await this.props.fetchFromS3('/templates/content-assistant/base.json')

        if(ok && settings) {
            this.setState({ settings }, this.setSystemPrompt)
            local.set('content-assistant:settings', settings, { expiry: 30 })
        }
    }

    promptAssistant = async ({ prompt, displayPrompt, retryId = null, ...promptProps }) => {
        this.fetchController.abort()
        this.fetchController = new AbortController()

        const promptUUID = uuid()

        this.setState({ userAborted: false })

        return new Promise((resolve, reject) => {
            this.setState(({ dialogue: previousDialogue, messages: previousMessages }) => ({
                dialogue: [
                    ...previousDialogue
                        .filter(({ error }) => !error)
                        .filter(({ id }) => id !== retryId),
                    {
                        id: promptUUID,
                        role: 'user',
                        content: prompt,
                        ...promptProps
                    }
                ],
                messages: [
                    ...previousMessages
                        .filter(({ error }) => !error)
                        .filter(({ id }) => id !== retryId),
                    {
                        id: promptUUID,
                        prompt: displayPrompt,
                        output: null
                    }
                ],
                loading: true
            }), async () => {
                const { signal } = this.fetchController

                try {
                    const { ok, response } = await post({
                        path: '/content-assistance',
                        body: {
                            messages: this.state.dialogue
                        },
                        signal
                    })

                    if(ok) {
                        this.setState(({ dialogue: previousDialogue, messages: previousMessages }) => ({
                            dialogue: [
                                ...previousDialogue,
                                {
                                    id: uuid(),
                                    questionId: promptUUID,
                                    role: 'assistant',
                                    content: response.text
                                }
                            ],
                            messages: previousMessages.map(message => {
                                if(message.id === promptUUID) {
                                    return {
                                        ...message,
                                        output: response.text
                                    }
                                }

                                return message
                            }),
                            loading: false
                        }))

                        resolve({ ok, response })
                    } else {
                        throw new Error('API response not OK')
                    }
                } catch(error) {
                    if(error.name === 'AbortError') {
                        this.setState({ loading: false })

                        reject({ ok: false, response: null })
                    } else {
                        this.setState(({ messages: previousMessages }) => ({
                            messages: previousMessages.map(message => {
                                if(message.id === promptUUID) {
                                    return {
                                        ...message,
                                        output: null,
                                        error: true
                                    }
                                }

                                return message
                            }),
                            loading: false
                        }))

                        reject({ ok: false, response: null })
                    }
                }
            })
        })
    }

    retryLatestPrompt = async () => {
        const {
            dialogue = [],
            messages = []
        } = this.state

        const latestPrompt = dialogue
            .toReversed()
            .find(({ role }) => role === 'user')

        let ok = false

        if(latestPrompt) {
            const displayPrompt = messages.find(({ id }) => id === latestPrompt.id)?.prompt

            const result = await this.promptAssistant({
                prompt: latestPrompt.content,
                displayPrompt,
                retryId: latestPrompt.id
            })

            ok = result.ok
        }

        return { ok }
    }

    abortPrompt = () => {
        this.fetchController.abort()
        this.setState({ userAborted: true })
    }

    appendPrompt = ({ user, assistant }) => {
        this.setState(({ dialogue }) => ({
            dialogue: [
                ...dialogue,
                ...compact([
                    user && {
                        ...user,
                        role: 'user'
                    },
                    assistant && {
                        ...assistant,
                        role: 'assistant'
                    }
                ])
            ]
        }))
    }

    removePrompt = id => {
        this.setState(({ dialogue, messages }) => ({
            dialogue: dialogue.filter(({ id: dialogueId, questionId }) => dialogueId !== id && questionId !== id),
            messages: messages.filter(message => message.id !== id)
        }))
    }

    appendMessage = body => {
        this.setState(({ messages }) => ({
            messages: [
                ...messages,
                body
            ]
        }))
    }

    reset = () => {
        this.setState({
            dialogue: [],
            messages: []
        })
    }

    render() {
        const { children = null } = this.props

        return (
            <ContentAssistantContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </ContentAssistantContext.Provider>
        )
    }
}

const cleanPrompt = ({ prompt, values = {} }) => {
    if(prompt.match(/<.*?>/)) {
        prompt = prompt.replace(/<.*?>/g, match => {
            const key = match.replace(/<|>/g, '')

            return values[key]
        })
    }

    return prompt
}

export const useContentAssistant = () => useContext(ContentAssistantContext)

export default props => {
    const { fetchFromS3 } = useEnvironment()
    const { organization } = useOrganization()

    return (
        <ContentAssistantProvider
            {...props}
            organization={organization}
            fetchFromS3={fetchFromS3} />
    )
}