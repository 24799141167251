import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import StatusLabelBase from 'components/status-label'

export const StatusLabel = styled(StatusLabelBase)`
    column-gap: 8px;

    svg {
        ${svgStroke('small')}
    }

    &.hero {
        justify-content: center;
        column-gap: 8px;
        width: 100%;
        height: 40px;
        margin-block-end: 24px;
        font-size: 16px;
    }

    &.banner {
        justify-content: center;
        column-gap: 8px;
        height: 36px;
        position: absolute;
        inset-block-start: 0;
        inset-inline: 0;
        border-radius: 0;
        transform: translateY(-100%);
    }
`