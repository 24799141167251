import React, { useEffect, useState, Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { isSameDay, isSameYear, isThisYear } from 'date-fns'
import { useMe } from 'contexts/me'
import { useAccess } from 'contexts/access'
import { isofy } from 'utilities/date-time'
import { DateRangeWrapper, Division, Constructive } from './s'
import DateRange from 'components/date-range'

const AbsenceDate = ({
    start, end, onClick, short = false, showStartDate = true, hideSameYear = false,
    as: Wrapper = Division, dateRangeWrapper: RangeWrapper = DateRangeWrapper, separator = '–',
    user = {},
    ...props
}) => {
    const { formatMessage } = useIntl()

    const { check } = useAccess()
    const { isItMyOwnId } = useMe()
    const absenceAdmin = check('absence:manage')

    const absenceAccess = absenceAdmin || isItMyOwnId(user?.id)

    const defaultFormat = short ?
        'P' :
        formatMessage({
            id: 'date_fns_format_day_short_month_year_friendly',
            defaultMessage: 'LLL d, yyyy'
        })

    const [startFormat, setStartFormat] = useState(null)
    const [endFormat, setEndFormat] = useState(null)

    start = isofy(start)
    end = isofy(end)

    const EndDateWrapper = (onClick && absenceAccess) ?
        Constructive :
        Fragment

    useEffect(() => {
        setStartFormat(formatStartDate())
        setEndFormat(formatEndDate())
    }, [])

    const formatStartDate = () => {
        if(!end) {
            return defaultFormat
        }

        if(isSameDay(start, end) && !hideSameYear) {
            return defaultFormat
        }

        const sameYear = (isSameYear(start, end) && isThisYear(start))

        if(sameYear || !!hideSameYear) {
            return formatMessage({
                id: 'date_fns_format_day_short_month_friendly',
                defaultMessage: 'LLL d'
            })
        }

        return defaultFormat
    }

    const formatEndDate = () => {
        if(!!hideSameYear && short) {
            return defaultFormat
        }

        const sameYear = (isSameYear(start, end) && isThisYear(end))
        if(!!hideSameYear && sameYear) {
            return formatMessage({
                id: 'date_fns_format_day_short_month_friendly',
                defaultMessage: 'LLL d'
            })
        }

        return defaultFormat
    }

    if(!startFormat) {
        return null
    }

    return (
        <Wrapper {...props}>
            <DateRange
                as={RangeWrapper}
                start={start}
                showStartDate={showStartDate}
                end={end}
                startFormat={startFormat}
                separator={separator}
                endFormat={endFormat}
                endEmptyText={(
                    <EndDateWrapper>
                        <FormattedMessage
                            id={(onClick && absenceAccess) ?
                                'absence_action_set_end_date' :
                                'absence_message_missing_end_date'
                            }
                            defaultMessage={(onClick && absenceAccess) ?
                                'Set end date' :
                                'No end date'
                            } />
                    </EndDateWrapper>
                )} />
        </Wrapper>
    )
}

export default AbsenceDate
