import React from 'react'
import Symbol from 'components/symbol'
import { fallbackSymbol } from 'pages/processes'
import { CloseButton } from 'components/button'
import { Sheriff as Heading } from 'components/typography/heading'
import Paragraph from 'components/typography/paragraph'
import Meta from './meta'
import Statistics from './statistics'

const PreviewProcessTemplateHero = ({ template, dismiss }) => (
    <>
        <div>
            <Symbol
                symbol={template?.symbol ?? fallbackSymbol[template.type]}
                size={56} />
            <CloseButton onClick={dismiss} />
        </div>
        <Heading as="h2">{template.name}</Heading>
        {template?.description && (
            <Paragraph className="preamble compact">{template.description}</Paragraph>
        )}
        <Meta template={template} />
        <Statistics template={template} />
    </>
)

export default PreviewProcessTemplateHero