import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { getPlaceholderTranslation } from 'pages/processes/pages/process/task-groups/group'
import { Placeholder, Content, Tasks } from 'pages/processes/pages/template/task-groups/group/s'
import DrawablePanel from 'components/drawable-panel'
import Title from './title'
import Task from 'lists/tasks/task'

const TaskGroup = ({ type, placeholder = false, when, dueAtOffsetDays, drawable, tasks = [], salt }) => {
    const placeholderTranslation = placeholder ?
        getPlaceholderTranslation(type, when) :
        null

    return (
        <DrawablePanel
            {...{
                ...drawable,
                open: !!tasks.length
            }}
            heading={(
                <Title
                    type={type}
                    dueAtOffsetDays={dueAtOffsetDays}
                    placeholder={placeholder}
                    when={when} />
            )}
            salt={`${salt}:drawable`}>
            <Content>
                {!!placeholderTranslation && (
                    <Placeholder>
                        <FormattedMessage {...placeholderTranslation} />
                    </Placeholder>
                )}
                {!placeholderTranslation && (
                    <Tasks>
                        {tasks.map(task => (
                            <Task
                                task={{
                                    ...task,
                                    parent: { type }
                                }}
                                mode={`${type}:template`}
                                key={`${salt}:task:${task.id}`} />
                        ))}
                    </Tasks>
                )}
            </Content>
        </DrawablePanel>
    )
}

export default memo(TaskGroup)