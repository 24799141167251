import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useOrganization } from 'contexts/organization'
import { useSurvey } from 'contexts/survey'
import { useDirty } from 'contexts/modal'
import { getFallbackSymbol } from 'pages/surveys/utilities'
import { pick, size } from 'utilities/object'
import { Layout } from '../s'
import Hero from '../hero'
import Intro from '../intro'
import Message from 'components/message'
import { Activity as Pulse } from 'styled-icons/feather'
import {
    Form, Header, SymbolAndTitle,
    QuestionsLayout, Questions, Question, Field, QuestionTitleWrapper, QuestionTitle,
    Actions
} from './s'
import { Required } from 'components/form/field/s'
import Symbol from 'components/symbol'
import { Title } from 'components/typography/heading'
import RatingField from 'components/form/field/rating'
import TextField from 'components/form/field/text'
import AnonymousStatus from 'pages/surveys/components/anonymous-status'
import { ButtonSubmit } from 'components/button'
import Summary from 'pages/surveys/components/questions/respond/summary'

const EnpsSurveyRespondContent = ({ defaults, answerable, onDone, salt }) => {
    const { formatMessage } = useIntl()

    const { organization } = useOrganization()

    const {
        getResponseRun,
        respond
    } = useSurvey()

    const [, setDirty] = useDirty()

    const [started, setStarted] = useState(false)
    const [responding, setResponding] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const source = getResponseRun()

    const submit = async body => {
        if(!answerable) {
            return void setSubmitted(true)
        }

        setResponding(true)

        const { ok } = await respond({
            ...body,
            ...pick(source, 'id', 'type')
        })

        setResponding(false)

        if(ok) {
            setSubmitted(true)
            onDone?.(source)
        }
    }

    const { anonymous = true } = source

    return (
        <Layout>
            <Hero started={started} />
            <Intro
                started={started}
                start={() => setStarted(true)}>
                <Message
                    type="neutral"
                    {...(!anonymous ? { className: 'compact' } : null)}
                    message={formatMessage({
                        id: 'employee_satisfaction_survey_respond_message_pulse',
                        defaultMessage: 'This survey will give {organization} a pulse on the satisfaction of the company and help them give you as good a workplace as possible.'
                    }, { organization: organization.name })}
                    icon={Pulse} />
            </Intro>
            <Form
                layout="vertical"
                {...(started ? { className: 'running' } : { inert: 'true' })}
                onChange={body => {
                    if(!source.preview && !!size(body)) {
                        setDirty(true)
                    }
                }}
                onSubmit={submit}>
                {({ errors, values, trigger }) => (
                    <>
                        <Header>
                            <SymbolAndTitle>
                                <Symbol
                                    symbol={source.symbol ?? getFallbackSymbol(source)}
                                    size={40} />
                                <Title>{source.title}</Title>
                            </SymbolAndTitle>
                        </Header>
                        {!submitted && (
                            <QuestionsLayout>
                                <Questions>
                                    <Question>
                                        <QuestionTitleWrapper>
                                            <QuestionTitle>
                                                <FormattedMessage
                                                    id="employee_satisfaction_scale_enps_question"
                                                    defaultMessage="On a scale from zero to ten, how likely is it that you would recommend {orgName} as a place to work?"
                                                    values={{ orgName: organization.name }} />
                                                <Required>*</Required>
                                            </QuestionTitle>
                                        </QuestionTitleWrapper>
                                        <Field>
                                            <RatingField
                                                salt={salt}
                                                label={false}
                                                name="score"
                                                field={{
                                                    value: defaults?.score ?? null,
                                                    required: true,
                                                    unsettable: false,
                                                    min: 0,
                                                    max: 10,
                                                    include: 'always'
                                                }}
                                                controlProps={{ autoFocus: true }}
                                                optionsClassName="spread" />
                                        </Field>
                                        <Field>
                                            <TextField
                                                salt={salt}
                                                label={formatMessage({
                                                    id: (typeof values.score === 'number') ?
                                                        'employee_satisfaction_survey_label_tell_more' :
                                                        'employee_satisfaction_survey_label_tell_more_no_score',
                                                    defaultMessage: (typeof values.score === 'number') ?
                                                        'Do you want to tell us a bit more about why you’re giving {organizationName} a score of {score}?' :
                                                        'Do you want to tell us a bit more about the score you’re giving {organizationName}?'
                                                }, {
                                                    organizationName: organization.name,
                                                    score: values.score
                                                })}
                                                name="comment"
                                                field={{ include: 'touched' }} />
                                        </Field>
                                    </Question>
                                </Questions>
                                <Actions className="spread">
                                    <AnonymousStatus className="banner" />
                                    <ButtonSubmit
                                        className={`constructive${responding ? ' loading' : ''}`}
                                        disabled={!!size(errors) || responding || submitted}
                                        ref={trigger}>
                                        <FormattedMessage
                                            id="action_submit"
                                            defaultMessage="Submit" />
                                    </ButtonSubmit>
                                </Actions>
                            </QuestionsLayout>
                        )}
                        {submitted && (
                            <QuestionsLayout className="submitted">
                                <Summary answerable={answerable} />
                            </QuestionsLayout>
                        )}
                    </>
                )}
            </Form>
        </Layout>
    )
}

export default EnpsSurveyRespondContent