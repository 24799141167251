import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import PendingSurveyRunsProvider, { usePendingSurveyRuns } from 'contexts/survey-pending-runs'
import { useLocationQueryIntent } from 'hooks/location-intent'
import SurveyProvider from 'contexts/survey'
import { Heading } from '../s'
import PendingSurveyRuns from 'pages/surveys/lists/pending-survey-runs'
import RespondToSurveyRun from 'pages/surveys/modals/survey-run/respond'
import { Scrollable as Modal, ModalHeader } from 'modals/generic'
import Message from 'components/message'
import {
    EmojiUnhappyFilled as Error,
    EmojiSadFilled as Warning,
    EmojiHappyFilled as Success
} from 'components/feather'

const salt = 'survey:respond'

const SurveyRespond = () => {
    const { formatMessage } = useIntl()

    const {
        pendingSurveyRuns = [],
        hasFetched,

        completeSurveyRun,
        checkSurveyRunStatus
    } = usePendingSurveyRuns()

    const [run, setRun] = useState(null)
    const [unresolvedStatus, setUnresolvedStatus] = useState(null)
    const [responding, setResponding] = useState(null)

    // If we make a change to not fetch the survey runs up front, or
    // the list of runs grows too long or is limited to only one run etc.,
    // this needs to be changed to useQueryResolveItem instead
    useLocationQueryIntent({
        intent: 'survey-respond',
        action: ({ runId, ...defaults }) => setRun({
            id: runId,
            defaults
        })
    })

    useEffect(() => {
        if(!!run?.id && hasFetched) {
            const match = pendingSurveyRuns?.find(({ id }) => id === run.id)

            if(match) {
                setResponding({
                    run: match,
                    defaults: run.defaults
                })

                setRun(null)
            } else {
                (async () => {
                    const { response } = await checkSurveyRunStatus(run.id)
                    !!response.status && setUnresolvedStatus(response.status)
                })()
            }
        }
    }, [run?.id, pendingSurveyRuns?.map(({ id }) => id).join('+'), hasFetched])

    const status = getStatusData(unresolvedStatus)

    return (
        <>
            {(!!pendingSurveyRuns?.length) && (
                <>
                    <Heading className="spacious">
                        <FormattedMessage
                            id="dashboard_surveys_heading"
                            defaultMessage="Surveys awaiting your response" />
                    </Heading>
                    <PendingSurveyRuns respond={run => setResponding({ run })} />
                </>
            )}
            <SurveyProvider
                fetchOnMount={false}
                respondRun={responding?.run ?? null}
                key={responding?.run?.id ?? 'empty'}>
                <RespondToSurveyRun
                    show={!!responding}
                    dismiss={() => setResponding(null)}
                    defaults={responding?.defaults ?? null}
                    answerable
                    onDone={completeSurveyRun}
                    salt={`${salt}:preview`} />
            </SurveyProvider>
            <Modal
                show={!!unresolvedStatus}
                dismiss={() => setUnresolvedStatus(null)}>
                {!!status && (
                    <>
                        <ModalHeader
                            heading={formatMessage(status.heading)}
                            dismiss={() => setUnresolvedStatus(null)} />
                        <Message
                            className="compact"
                            type={status.message.type}
                            icon={status.message.icon}
                            message={formatMessage(status.message.translation)} />
                    </>
                )}
            </Modal>
        </>
    )
}

const getStatusData = status => ({
    responded: {
        heading: {
            id: 'employee_satisfaction_survey_response_error_object_duplicate_title',
            defaultMessage: 'Already submitted'
        },
        message: {
            type: 'success',
            icon: Success,
            translation: {
                id: 'employee_satisfaction_survey_response_error_object_duplicate',
                defaultMessage: 'Nice to see your engagement, but your response has already been submitted.'
            }
        }
    },
    expired: {
        heading: {
            id: 'employee_satisfaction_survey_response_error_survey_expired_title',
            defaultMessage: 'Survey has ended'
        },
        message: {
            type: 'warning',
            icon: Warning,
            translation: {
                id: 'employee_satisfaction_survey_response_error_survey_expired',
                defaultMessage: 'The response period for this survey has ended.'
            }
        }
    },
    'not-found': {
        heading: {
            id: 'survey_run_status_not_found_title',
            defaultMessage: 'Survey not found'
        },
        message: {
            type: 'error',
            icon: Error,
            translation: {
                id: 'survey_run_status_not_found_message',
                defaultMessage: 'We could not find the survey. It could have been deleted, or there could be something wrong with the link you followed.'
            }
        }
    }
})[status] ?? null

export default props => {
    const { checkModule } = useAccess()

    if(!checkModule('surveys')) {
        return null
    }

    return (
        <PendingSurveyRunsProvider>
            <SurveyRespond {...props} />
        </PendingSurveyRunsProvider>
    )
}