import React, { useState, useRef, useCallback } from 'react'
import {
    Hero, Message, Heading,
    RadiobuttonsField, StringField, Value
} from './s'
import Paragraph from 'components/typography/paragraph'
import Caption from 'components/typography/caption'
import Form from 'components/form/controller'
import Actions from 'components/form/actions'
import { Plain, Button } from 'components/button'
import { integerPattern } from 'utilities/regex'

const Content = ({ offset: initialOffset, defaultOffset = 7, salt, ...props }) => {
    const getDialFromOffset = useCallback(offset => {
        const negative = !!offset && offset < 0
        const neutral = !offset || offset === 0
        const positive = !!offset && offset > 0

        if(![negative, neutral, positive].some(Boolean)) {
            return 'uninitialized'
        }

        return neutral ?
            'neutral' :
            negative ?
                'negative' :
                'positive'
    }, [])

    const [dial, setDial] = useState(getDialFromOffset(initialOffset))

    const [offset, setOffset] = useState({
        negative: getDialFromOffset(initialOffset) === 'negative' ? Math.abs(initialOffset) : defaultOffset,
        neutral: 0,
        positive: getDialFromOffset(initialOffset) === 'positive' ? Math.abs(initialOffset) : defaultOffset
    })

    const beforeRadio = useRef()
    const beforeInput = useRef()
    const afterRadio = useRef()
    const afterInput = useRef()

    const {
        heading,
        label,
        optionLabelFormatters,
        message,
        dismiss,
        doneAction,
        cancelAction
    } = props

    const cancel = cancelAction()

    const done = useCallback(
        doneAction({ picked: polarize(offset[dial], dial) }),
        [dial, Object.values(offset).join('+')]
    )

    return (
        <>
            <Hero>
                {!!label && <Caption className="compact">{label}</Caption>}
                {!!message && <Message {...message} />}
                <Heading>
                    {heading}
                </Heading>
            </Hero>
            <Form layout="vertical">
                <RadiobuttonsField
                    salt={salt}
                    name="dial"
                    field={{ required: true }}
                    direction="rtl"
                    label={false}
                    options={[
                        {
                            id: 'neutral',
                            name: optionLabelFormatters?.neutral?.(),
                            value: 'neutral',
                            checked: dial === 'neutral'
                        },
                        {
                            id: 'negative',
                            name: (
                                <Value>
                                    <StringField
                                        salt={salt}
                                        className="compact"
                                        name="negative"
                                        field={{
                                            value: offset.negative,
                                            include: 'always',
                                            required: true
                                        }}
                                        controlProps={{
                                            type: 'number',
                                            min: 0,
                                            max: 36500,
                                            step: 1,
                                            pattern: integerPattern,
                                            onFocus: () => beforeRadio.current?.click()
                                        }}
                                        onChange={({ negative }) => setOffset(offset => ({
                                            ...offset,
                                            negative: !!negative ?
                                                Math.abs(negative) :
                                                null
                                        }))}
                                        ref={beforeInput} />
                                    <Paragraph
                                        as="span"
                                        className="compact">
                                        {optionLabelFormatters?.negative?.()}
                                    </Paragraph>
                                </Value>
                            ),
                            value: 'negative',
                            checked: dial === 'negative',
                            ref: beforeRadio,
                            onClick: () => beforeInput.current?.focus(),
                            className: 'align-with-input'
                        },
                        {
                            id: 'positive',
                            name: (
                                <Value>
                                    <StringField
                                        salt={salt}
                                        className="compact"
                                        name="positive"
                                        field={{
                                            value: offset.positive,
                                            include: 'always',
                                            required: true
                                        }}
                                        controlProps={{
                                            type: 'number',
                                            min: 0,
                                            max: 36500,
                                            step: 1,
                                            pattern: integerPattern,
                                            onFocus: () => afterRadio.current?.click()
                                        }}
                                        onChange={({ positive }) => setOffset(offset => ({
                                            ...offset,
                                            positive: !!positive ?
                                                Math.abs(positive) :
                                                null
                                        }))}
                                        ref={afterInput} />
                                    <Paragraph
                                        as="span"
                                        className="compact">
                                        {optionLabelFormatters?.positive?.()}
                                    </Paragraph>
                                </Value>
                            ),
                            value: 'positive',
                            checked: dial === 'positive',
                            ref: afterRadio,
                            onClick: () => afterInput.current?.focus(),
                            className: 'align-with-input'
                        }
                    ]}
                    onChange={({ dial }) => setDial(dial)} />
                <Actions>
                    <Plain
                        className={cancel.effect}
                        onClick={cancel.onClick}>
                        {cancel.label}
                    </Plain>
                    <Button
                        className={done.effect}
                        onClick={() => {
                            done.onClick()
                            dismiss()
                        }}
                        disabled={dial === 'uninitialized' || (dial === 'negative' && !offset.negative) || (dial === 'positive' && !offset.positive)}>
                        {done.label}
                    </Button>
                </Actions>
            </Form>
        </>
    )
}

const polarize = (offset, polarity) => {
    if(polarity === 'negative') {
        return -Math.abs(offset)
    }

    if(polarity === 'positive') {
        return Math.abs(offset)
    }

    return offset
}

export default Content