import React, { useState, useEffect } from 'react'
import { useProcessTemplates } from 'contexts/process-templates'
import { Wrapper, LoadingContainer } from './s'
import Loader from 'components/loader'
import Hero from './hero'
import TaskGroups from './task-groups'

const PreviewProcessTemplate = ({ template, dismiss, salt }) => {
    const {
        fetchTemplate,
        fetchHumaTemplate
    } = useProcessTemplates()

    const [loading, setLoading] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)
    const [currentTemplate, setCurrentTemplate] = useState(template)

    useEffect(() => {
        const fetch = async () => {
            setLoading(true)

            const get = (!!template?.providedByHuma || template?.providedBy) ? fetchHumaTemplate : fetchTemplate

            const { ok, response } = await get(template.id)

            setLoading(false)

            if(ok && response) {
                setHasFetched(true)
                setCurrentTemplate({
                    ...template,
                    ...response
                })
            }
        }

        fetch()
    }, [])

    return (
        <Wrapper>
            <Hero
                template={currentTemplate}
                dismiss={dismiss} />
            {(!loading && !!hasFetched) && (
                <TaskGroups
                    template={currentTemplate}
                    salt={`${salt}:${currentTemplate.id}`} />
            )}
            {!!loading && (
                <LoadingContainer>
                    <Loader />
                </LoadingContainer>
            )}
        </Wrapper>
    )
}

export default PreviewProcessTemplate