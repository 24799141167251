import React, { useState, cloneElement, useEffect } from 'react'
import { useIntl } from 'react-intl'
import OffsetPicker from 'pages/processes/pages/template/edit-task/offset-picker'

const StandaloneDueDatePicker = ({ trigger: Trigger, offset, onChange, salt, ...props }) => {
    const { formatMessage } = useIntl()

    const [value, setValue] = useState(offset)
    const [picking, setPicking] = useState(false)

    useEffect(() => {
        if(!!value || value === 0) {
            onChange?.(value)
        }
    }, [value])

    return (
        <>
            {cloneElement(Trigger, { onClick: e => {
                e.stopPropagation()
                setPicking(true)
            } })}
            <OffsetPicker
                {...props}
                show={picking}
                heading={formatMessage({
                    id: 'noun_due_date',
                    defaultMessage: 'Due date'
                })}
                offset={value}
                salt={salt}
                dismiss={() => setPicking(false)}
                cancelAction={() => ({
                    label: formatMessage({
                        id: 'action_cancel',
                        defaultMessage: 'Cancel'
                    }),
                    effect: 'neutral',
                    onClick: () => setPicking(false)
                })}
                doneAction={({ picked }) => ({
                    label: formatMessage({
                        id: 'action_done',
                        defaultMessage: 'Done'
                    }),
                    effect: 'constructive',
                    onClick: () => {
                        setPicking(false)
                        setValue(picked)
                    }
                })} />
        </>
    )
}

export default StandaloneDueDatePicker