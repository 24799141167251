import React, { Component, createContext, useContext } from 'react'
import { useMe } from 'contexts/me'
import { get, post, patch, put, remove, outget } from 'api'
import { fieldFilled } from 'utilities/access'
import PubSub from 'pubsub-js'

const PersonContext = createContext()
PersonContext.displayName = 'Person'

class PersonProvider extends Component {
    constructor(props) {
        super(props)

        this.setId(props.id)

        this.state = {
            person: null,
            coverColors: {
                value: null,
                loading: false
            },
            deleted: false,

            replace: this.replace,

            fetchPerson: this.fetch,
            createPerson: this.create,
            updatePerson: this.update,
            updatePersonLocally: this.updateLocally,
            deactivatePerson: this.deactivate,
            reactivatePerson: this.reactivate,
            removePerson: this.remove,

            uploadPersonAvatar: this.uploadAvatar,
            removePersonAvatar: this.removeAvatar,

            uploadPersonCover: this.uploadCover,
            removePersonCover: this.removeCover,

            setPersonAffiliation: this.setAffiliation,

            fetching: false,
            hasFetched: false
        }

        PubSub.subscribe('person.refresh', () => this.fetch())
    }

    componentDidMount() {
        const {
            fetchAccess = true,
            fetchOnMount = true
        } = this.props

        if(fetchAccess && fetchOnMount) {
            this.fetch()
        }
    }

    componentDidUpdate(props) {
        const idChanged = props.id !== this.props.id

        const { fetchAccess: previousFetchAccess = true } = props
        const { fetchAccess = true } = this.props
        const fetchAccessGained = !previousFetchAccess && fetchAccess

        if(idChanged) {
            this.replace(this.props.id)
        }

        if(!idChanged && fetchAccessGained) {
            this.fetch()
        }
    }

    componentWillUnmount() {
        PubSub.unsubscribe('person.refresh')
    }

    setId = id => this.id = id

    fetch = async () => {
        const { fetching } = this.state

        if(!this.id || fetching) {
            return
        }

        this.setState({ fetching: true })

        const { ok, response: person } = await get({ path: `/users/${this.id}` })

        if(!!ok) {
            this.setState({
                person,
                hasFetched: true,
                fetching: false
            }, this.getCoverColorsFromImgix)
        } else {
            this.setState({
                hasFetched: true,
                fetching: false
            })
        }

        return { ok, response: person }
    }

    create = async body => {
        const { ok, response: person } = await post({
            path: '/users',
            body
        })

        if(ok && person) {
            this.setId(person.id)
            this.setState({ person })
        }

        return { ok, response: person }
    }

    update = async update => {
        const { ok, response: person } = await patch({
            path: `/users/${this.id}`,
            body: update
        })

        if(ok && person) {
            this.setState({ person })

            if(this.props.me.isItMyOwnId(this.id)) {
                this.props.me.updateMe()
            }
        }

        return { ok, response: person }
    }

    updateLocally = (update, what) => void this.setState(({ person }) => ({
        person: {
            ...person,
            [what]: {
                ...person[what],
                value: update
            }
        }
    }))

    uploadAvatar = async body => {
        const { ok, response: avatarImage } = await post({
            path: `/users/${this.id}/avatar-image`,
            body
        })

        if(ok && avatarImage) {
            this.setState(({ person }) => ({
                person: {
                    ...person,
                    avatarImage: {
                        ...person.avatarImage,
                        value: avatarImage
                    },
                    avatarUrl: {
                        ...person.avatarUrl,
                        value: avatarImage.url
                    }
                }
            }), () => {
                this.getCoverColorsFromImgix()

                if(this.props.me.isItMyOwnId(this.id)) {
                    this.props.me.updateMe()
                }
            })
        }

        return { ok, response: avatarImage }
    }

    uploadCover = async body => {
        const { ok, response } = await post({
            path: `/users/${this.id}/cover-image`,
            body
        })

        if(ok && response) {
            this.setState(({ person }) => ({
                person: {
                    ...person,
                    coverImageUrl: {
                        ...person.coverImageUrl,
                        value: response.url
                    }
                }
            }))
        }

        return { ok, response }
    }

    removeAvatar = async () => {
        const { ok } = await remove({
            path: `/users/${this.id}/avatar-image`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ person, coverColors }) => ({
                person: {
                    ...person,
                    avatarImage: {
                        ...person.avatarImage,
                        value: null
                    },
                    avatarUrl: {
                        ...person.avatarUrl,
                        value: null
                    }
                },
                coverColors: {
                    ...coverColors,
                    value: null
                }
            }), () => {
                if(this.props.me.isItMyOwnId(this.id)) {
                    this.props.me.updateMe()
                }
            })
        }

        return { ok }
    }

    removeCover = async () => {
        const { ok } = await remove({
            path: `/users/${this.id}/cover-image`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ person }) => ({
                person: {
                    ...person,
                    coverImageUrl: {
                        ...person.coverImageUrl,
                        value: null
                    }
                }
            }))
        }

        return { ok }
    }

    getCoverColorsFromImgix = async () => {
        if(!this.state.person.avatarImage?.value?.paletteUrl) {
            return
        }

        const avatarImageFilled = fieldFilled(this.state.person.avatarImage)
        const coverFilled = fieldFilled(this.state.person.coverImageUrl)

        if(!!avatarImageFilled && !coverFilled) {
            this.setState(({ coverColors }) => ({
                coverColors: {
                    ...coverColors,
                    loading: true
                }
            }))

            setTimeout(() => {
                this.setState(({ coverColors }) => ({
                    coverColors: {
                        ...coverColors,
                        loading: false
                    }
                }))
            }, 500)

            const { ok, response } = await outget(this.state.person.avatarImage.value.paletteUrl)

            if(ok && response) {
                const from = response.dominant_colors.vibrant_dark?.hex ?? response.dominant_colors.muted_dark?.hex
                const to = response.dominant_colors.vibrant_light?.hex ?? response.dominant_colors.muted_light?.hex

                if(from && to) {
                    this.setState(({ coverColors }) => ({
                        coverColors: {
                            ...coverColors,
                            value: [from, to]
                        }
                    }))
                }
            }
        }
    }

    setAffiliation = async (groups, type) => {
        const { ok } = await put({
            path: `/users/${this.id}/${type}`,
            body: groups.map(({ id }) => ({ id })),
            returnsData: false
        })

        if(ok) {
            this.setState(({ person }) => ({
                person: {
                    ...person,
                    [type]: {
                        ...person[type],
                        value: groups
                    }
                }
            }), () => {
                if(this.props.me.isItMyOwnId(this.id)) {
                    this.props.me.updateMe()
                }
            })
        }

        return { ok }
    }

    deactivate = async () => {
        const { ok } = await post({
            path: `/users/${this.id}/deactivate`,
            returnsData: false
        })

        return { ok }
    }

    reactivate = async body => {
        const { ok } = await post({
            path: `/users/${this.id}/activate`,
            body,
            returnsData: false
        })

        return { ok }
    }

    remove = async () => {
        const { ok } = await remove({
            path: `/users/${this.id}`,
            returnsData: false
        })

        !!ok && this.setState({ deleted: true })

        return { ok }
    }

    replace = id => {
        this.setId(id)

        this.setState({
            person: null,
            coverColors: {
                value: null,
                loading: false
            },
            fetching: false,
            hasFetched: false
        }, this.fetch)
    }

    render() {
        const { children = null } = this.props

        return (
            <PersonContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </PersonContext.Provider>
        )
    }
}

export const usePerson = () => useContext(PersonContext)

export default props => {
    const me = useMe()

    return (
        <PersonProvider
            {...props}
            me={me} />
    )
}