import React, { Component, createContext, useContext, createRef } from 'react'
import { intersectionObserver } from 'utilities/dom'
import debounce from 'lodash.debounce'
import isEqual from 'react-fast-compare'
import { size } from 'utilities/object'
import { get, post, put, remove } from 'api'

const CompetenceTypesContext = createContext()
CompetenceTypesContext.displayName = 'CompetenceTypes'

export default class CompetenceTypesProvider extends Component {
    constructor(props) {
        super(props)

        const { eternal = true } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        if(eternal) {
            this.intersectionObserver = intersectionObserver(this.onIntersect)
        }

        this.pagingDefaults = pagingDefaults(props?.paging)

        this.flash = createRef(null)

        this.state = {
            types: props?.types ?? [],
            filter: props?.filter ?? {},
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            flash: this.flash,
            clearFlash: this.clearFlash,

            fetchTypes: this.fetch,
            addType: this.add,
            updateType: this.update,
            removeType: this.remove,
            setTypesFilter: this.setFilter,

            hasFetched: false,
            autoFetch: false,
            fetching: false,
            loading: false
        }
    }

    componentDidMount() {
        const {
            fetchAccess = true,
            fetchOnMount = true
        } = this.props

        if(fetchAccess && fetchOnMount) {
            this.fetchDebounced()
        }
    }

    componentDidUpdate(props, { filter }) {
        const pagingChanged = !isEqual(props.paging, this.props.paging)
        const filterChanged = !isEqual(filter, this.state.filter)

        const { fetchAccess: previousFetchAccess = true } = props
        const { fetchAccess = true } = this.props
        const fetchAccessGained = !previousFetchAccess && fetchAccess

        const state = {}

        if(pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props.paging)
            state.paging = this.pagingDefaults()
        }

        this.setState(size(state) ? state : null, () => {
            if(filterChanged || fetchAccessGained) {
                this.fetchDebounced(true)
            }
        })
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver?.destroy?.()
    }

    fetch = async (force = false) => {
        const { fetchExhaustively } = this.props

        const {
            paging,
            filter,
            hasFetched,
            autoFetch,
            fetching,
            eternal
        } = this.state

        if((fetching || (hasFetched && !eternal)) && !force) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetched ? (paging.offset + this.pagingDefaults()) : 0,
            limit: paging.limit
        }

        const { response, ok } = fetchExhaustively ?
            await this.fetchExhaustively() :
            await get({
                path: '/competence-types',
                params: {
                    ...filter,
                    ...nextPaging
                },
                signal: this.fetchController.signal
            })

        if(ok && response) {
            this.setState(({ types: previousTypes }) => {
                const previousTypesWithoutFetched = previousTypes.filter(({ id: addedId }) => {
                    return !response.items.find(({ id: itemId }) => addedId === itemId)
                })

                const types = [
                    ...previousTypesWithoutFetched,
                    ...response.items
                ]

                return {
                    types,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        limit: this.pagingDefaults().limit,
                        hasNextPage: response.items.length && types.length < response.total
                    },
                    hasFetched: true,
                    autoFetch: hasFetched,
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                hasFetched: true,
                autoFetch: false,
                fetching: false,
                loading: false
            })
        }
    }

    fetchExhaustively = async (types = []) => {
        const { filter } = this.state

        const { response, ok } = await get({
            path: '/competence-types',
            params: {
                ...filter,
                offset: types.length
            }
        })

        if(ok && response?.items?.length) {
            types.push(...response.items)

            if(types.length < response.total) {
                return await this.fetchExhaustively(types)
            }
        }

        return {
            ok,
            response: {
                ...response,
                items: types
            }
        }
    }

    add = async body => {
        const { ok, response: type } = await post({
            path: '/competence-types',
            body
        })

        if(ok && type) {
            this.setState(({ types }) => ({
                types: [
                    type,
                    ...types
                ]
            }))
        }

        return { ok, response: type }
    }

    update = async (body, id) => {
        const { ok, response: type } = await put({
            path: `/competence-types/${id}`,
            body,
            returnsData: false
        })

        if(ok && type) {
            this.setState(({ types }) => {
                const index = types.findIndex(type => type.id === id)

                this.flash.current = type

                return {
                    types: [
                        ...types.slice(0, index),
                        type,
                        ...types.slice(index + 1, types.length)
                    ]
                }
            })
        }

        return { ok, response: type }
    }

    remove = async id => {
        const { ok } = await remove({
            path: `/competence-types/${id}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ types, paging }) => ({
                types: types.filter(type => type.id !== id),
                paging: {
                    ...paging,
                    offset: paging.offset - 1,
                    limit: paging.limit + 1
                }
            }))
        }

        return { ok }
    }

    setFilter = filter => void this.setState({
        types: [],
        filter,
        paging: this.pagingDefaults(),
        hasFetched: false,
        autoFetch: false
    })

    onIntersect = () => {
        const {
            eternal,
            loading,
            paging,
            autoFetch
        } = this.state

        if(!eternal) {
            return
        }

        if(!loading && paging.hasNextPage && autoFetch) {
            this.fetchDebounced()
        }
    }

    clearFlash = () => void this.setState(({ types }) => {
        this.flash.current = null

        return {
            types: [...types]
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <CompetenceTypesContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </CompetenceTypesContext.Provider>
        )
    }
}

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

export const useCompetenceTypes = () => useContext(CompetenceTypesContext)