import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSurvey } from 'contexts/survey'
import { getFallbackSymbol, useFormatSurveyRunDuration } from 'pages/surveys/utilities'
import { Intro, Greeting, Clock, StartButton } from './s'
import Symbol from 'components/symbol'
import { Title } from 'components/typography/heading'
import Paragraph from 'components/typography/paragraph'
import AnonymousStatus from 'pages/surveys/components/anonymous-status'

const SurveyRespondIntro = ({ children, started, start }) => {
    const { getResponseRun } = useSurvey()
    const source = getResponseRun()
    const formatSurveyRunDuration = useFormatSurveyRunDuration()

    return (
        <Intro {...(started ? {
            className: 'running',
            inert: 'true'
        } : null)}>
            <Greeting>
                <Symbol
                    symbol={source.symbol ?? getFallbackSymbol(source)}
                    size={56} />
                <Title className="compact">{source.title}</Title>
                {!!source.description && (
                    <Paragraph className="preamble compact">{source.description}</Paragraph>
                )}
                {(source.type === 'custom') && (
                    <Paragraph className="caption interpoint-divider compact">
                        <span>
                            <Clock size={14} />
                            {formatSurveyRunDuration(source)}
                        </span>
                        <span>
                            <FormattedMessage
                                id="questions_count"
                                defaultMessage="{count, plural, =0 {} =1 {1 question} other {{count} questions}}"
                                values={{ count: source.questions.length }} />
                        </span>
                    </Paragraph>
                )}
                <AnonymousStatus />
                <StartButton
                    onClick={start}
                    className="constructive">
                    <FormattedMessage
                        id="action_start"
                        defaultMessage="Start" />
                </StartButton>
            </Greeting>
            {!!children && (
                <div>
                    {children}
                </div>
            )}
        </Intro>
    )
}

export default SurveyRespondIntro