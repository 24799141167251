import React, { Component, createContext, useContext, createRef } from 'react'
import { get, post, patch, remove } from 'api'
import { intersectionObserver } from 'utilities/dom'
import debounce from 'lodash.debounce'
import isEqual from 'react-fast-compare'
import { size, reduce, withoutEmptyArrays } from 'utilities/object'

const CompetenceProfilesContext = createContext()
CompetenceProfilesContext.displayName = 'CompetenceProfiles'

export default class CompetenceProfilesProvider extends Component {
    constructor(props) {
        super(props)

        const { eternal = true } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        this.intersectionObserver = intersectionObserver(this.onIntersect)
        this.pagingDefaults = pagingDefaults(props?.paging)

        this.flash = createRef()

        this.state = {
            profiles: [],
            filter: props?.filter ?? {},
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            flash: this.flash,
            clearFlash: this.clearFlash,

            fetchProfiles: this.fetch,
            addProfile: this.add,
            updateProfile: this.update,
            updateProfileLocally: this.updateLocally,
            pushProfile: this.push,
            togglePrivacy: this.togglePrivacy,
            removeProfile: this.remove,
            setProfilesFilter: this.setFilter,

            hasFetched: false,
            autoFetch: false,
            fetching: false,
            loading: false
        }
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetchDebounced()
    }

    componentDidUpdate({ paging }, { filter }) {
        const pagingChanged = !isEqual(paging, this.props.paging)
        const filterChanged = !isEqual(filter, this.state.filter)

        const state = {}

        if(pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props?.paging)
            state.paging = this.pagingDefaults()
        }

        if(filterChanged) {
            this.setState(size(state) ? state : null, () => {
                this.fetchDebounced(true)
            })
        }
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver.destroy()
    }

    fetch = async (force = false) => {
        const {
            fetching,
            filter,
            paging,
            autoFetch,
            hasFetched,
            eternal
        } = this.state

        if((fetching || (hasFetched && !eternal)) && !force) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetched ? (paging.offset + this.pagingDefaults().limit) : 0,
            limit: paging.limit
        }

        const { response, ok } = await get({
            path: '/competence-profiles',
            params: {
                ...filter,
                ...nextPaging
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
            this.setState(({ profiles: previousProfiles }) => {
                const previousProfilesWithoutFetched = previousProfiles.filter(({ id: addedId }) => {
                    return !response.items.find(({ id: itemId }) => itemId === addedId)
                })

                const profiles = [
                    ...previousProfilesWithoutFetched,
                    ...response.items
                ]

                return {
                    profiles,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        limit: this.pagingDefaults().limit,
                        hasNextPage: response.items.length && profiles.length < response.total
                    },
                    hasFetched: true,
                    autoFetch: this.props.autoFetch || (!!previousProfiles.length && hasFetched),
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                hasFetched: true,
                autoFetch: false,
                fetching: false,
                loading: false
            })
        }
    }

    add = async body => {
        const { ok, response: profile } = await post({
            path: '/competence-profiles',
            body
        })

        if(ok && profile) {
            this.setState(({ profiles }) => {
                this.flash.current = profile

                return {
                    profiles: [
                        ...profiles,
                        {
                            ...profile,
                            targetsCount: profile.targets.length
                        }
                    ]
                }
            })
        }

        return { ok, profile }
    }

    update = async (body, id) => {
        const { ok, response: profile } = await patch({
            path: `/competence-profiles/${id}`,
            body
        })

        if(ok && profile) {
            this.setState(({ profiles }) => {
                const index = profiles.findIndex(({ id: profileId }) => profileId === id)

                this.flash.current = profile

                return {
                    profiles: [
                        ...profiles.slice(0, index),
                        {
                            ...profiles[index],
                            ...profile,
                            targetsCount: profile.targets.length
                        },
                        ...profiles.slice(index + 1, profiles.length)
                    ]
                }
            })
        }

        return { ok, profile }
    }

    updateLocally = (body, id) => void this.setState(({ profiles }) => {
        const index = profiles.findIndex(({ id: profileId }) => profileId === id)

        const profile = {
            ...profiles[index],
            ...body
        }

        this.flash.current = profile

        return {
            profiles: [
                ...profiles.slice(0, index),
                {
                    ...profile,
                    targetsCount: profile.targets.length
                },
                ...profiles.slice(index + 1, profiles.length)
            ]
        }
    })

    togglePrivacy = async ({ id, public: isPublic }) => {
        const { ok, profile } = await this.update({ public: !isPublic }, id)

        return { ok, profile }
    }

    push = profile => void this.setState(({ profiles }) => ({
        profiles: [
            ...profiles,
            {
                ...profile,
                targetsCount: profile.targets.length
            }
        ]
    }))

    remove = async id => {
        const { ok } = await remove({
            path: `/competence-profiles/${id}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ profiles, paging }) => ({
                profiles: profiles.filter(({ id: profileId }) => profileId !== id),
                paging: {
                    ...paging,
                    offset: paging.offset - 1,
                    limit: paging.limit + 1
                }
            }))
        }

        return { ok }
    }

    setFilter = (filter = {}) => {
        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }))

        const nextFilter = {
            ...(this.props.filter ?? null),
            ...filter
        }

        this.setState(({ filter: previousFilter }) => {
            const filterChanged = !isEqual(
                withoutEmptyArrays(nextFilter),
                withoutEmptyArrays(previousFilter)
            )

            if(!filterChanged) {
                return null
            }

            return {
                profiles: [],
                filter: nextFilter,
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }
        })
    }

    onIntersect = () => {
        const {
            eternal,
            loading,
            paging,
            autoFetch
        } = this.state

        if(!eternal) {
            return
        }

        if(!loading && paging.hasNextPage && autoFetch) {
            this.fetchDebounced()
        }
    }

    clearFlash = () => void this.setState(({ profiles }) => {
        this.flash.current = null

        return {
            profiles: [...profiles]
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <CompetenceProfilesContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </CompetenceProfilesContext.Provider>
        )
    }
}

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

export const useCompetenceProfiles = () => useContext(CompetenceProfilesContext)
