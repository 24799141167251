import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useOrganization } from 'contexts/organization'
import { getEmojiByType } from 'pages/processes/utilities'
import { titleTranslationGetter } from 'pages/processes/pages/process/task-groups/group/title'
import { getTitleOffsetTranslation } from 'pages/processes/pages/template/task-groups/group/title'
import { Title, Emoji, Columns, Misty } from 'pages/processes/pages/template/task-groups/group/title/s'

const TaskGroupTitle = ({ type, dueAtOffsetDays: dueAt, placeholder, when }) => {
    const { organization } = useOrganization()

    if((!placeholder && when !== 'undecided' && !dueAt && dueAt !== 0) || !organization) {
        return null
    }

    const getTitleTranslation = titleTranslationGetter(type, {
        misty: chunks => <Misty className="compact">{chunks}</Misty>,
        organization: organization.name
    })

    if(placeholder) {
        return (
            <>
                {(when === 'on') && (
                    <Title className="on">
                        <Emoji>
                            {getEmojiByType(type)}
                        </Emoji>
                        <FormattedMessage {...getTitleTranslation('on')} />
                    </Title>
                )}
                {(when !== 'on') && (
                    <Title>
                        <FormattedMessage {...getTitleTranslation(when)} />
                    </Title>
                )}
            </>
        )
    }

    return (
        <Title {...(when === 'on' ? { className: 'on' } : null)}>
            {(when === 'on') && (
                <Emoji>
                    {getEmojiByType(type)}
                </Emoji>
            )}
            <Columns>
                <span>
                    {(when === 'undecided') && (
                        <FormattedMessage
                            id="employee_onboarding_no_due_date"
                            defaultMessage="No due date" />
                    )}
                    {(when === 'on') && <Misty className="compact">{' · '}<FormattedMessage {...getTitleTranslation('on')} /></Misty>}
                    {['before', 'after'].includes(when) &&
                        <FormattedMessage
                            {...getTitleOffsetTranslation(type, when)}
                            values={{
                                misty: chunks => <Misty className="compact">{chunks}</Misty>,
                                count: Math.abs(dueAt)
                            }} />
                    }
                </span>
            </Columns>
        </Title>
    )
}

export default memo(TaskGroupTitle)