import React, { useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { getUserAgent } from 'utilities/user-agent'
import { size, pick } from 'utilities/object'
import { typeToFieldMap, getPropsByType } from 'pages/surveys/components/field'

const FieldPropsProvider = ({ field, fieldRef, proceed, index, current, whistle, salt }) => {
    const { formatMessage } = useIntl()

    const { os: { isMacOS } } = getUserAgent()

    const Field = typeToFieldMap?.[field.meta.type] ?? null
    const getProps = useCallback(getPropsByType(field.meta.type), [field.meta.type])

    useEffect(() => {
        const handleKeyDown = e => {
            const { key, metaKey, ctrlKey } = e
            const modifierPressed = (!!metaKey && !!isMacOS) || (!!ctrlKey && !isMacOS)

            if(key === 'Enter' && (
                (field.meta.type === 'text') ||
                (field.meta.type === 'textarea' && modifierPressed)
            )) {
                e.preventDefault()
                proceed?.()
            }
        }

        fieldRef.current?.addEventListener('keydown', handleKeyDown)
        return () => fieldRef.current?.removeEventListener('keydown', handleKeyDown)
    }, [field.meta.type])


    if(!Field) {
        return null
    }

    const props = getProps({
        props: {
            salt,
            name: field.id,
            label: false,
            field: {
                ...pick(field, 'value', 'required', ...getTypeFieldPropNames(field.meta.type)),
                include: 'always',
                unsettable: false,
                ...((!!field.min || field.min === 0) ? { min: field.min } : null),
                ...(field.max ? { max: field.max } : null),
                ...(field.meta?.variant ? { variant: field.meta.variant } : null),
                ...(shortcutTypes.includes(field.meta.type) ? {
                    enableShortcuts: current
                } : null)
            },
            controlProps: {
                placeholder: field.placeholder,
                ...(index === 0 ? { autoFocus: true } : null),
            },
            ...(!!size(field.meta?.labels) ? { labels: field.meta?.labels } : null),
            ...(!!field.options?.length ? {
                options: optionsMapper({ options: field.options, type: field.meta.type })
            } : null),
            ...(autoProceedTypes.includes(field.meta.type) ? {
                onChange: () => setTimeout(() => proceed?.(), 333)
            } : null),
            whistle
        },
        formatMessage
    })

    return (
        <Field
            {...props}
            className="compact"
            {...((field.type === 'text') ? { ref: fieldRef } : null)}
            key={`${salt}:${field.name}`} />
    )
}

const shortcutTypes = ['radiobuttons', 'checkboxes']
const autoProceedTypes = ['radiobuttons', 'rating', 'scale', 'boolean']

const getTypeFieldPropNames = type => ({
    checkboxes: ['min', 'max']
})[type] ?? []

const optionsMapper = ({ options, type }) => {
    if(type === 'radiobuttons') {
        return options.map(({ value, label }) => ({ value, name: label }))
    }

    if(type === 'checkboxes') {
        return options.map(({ value, label }) => ({ id: value, name: label }))
    }

    return options
}

export default FieldPropsProvider