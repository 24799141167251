import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'
import Paragraph from 'components/typography/caption'

export const Content = styled.div`
    padding-inline-start: 40px;
    padding-block-end: 24px;

    ${belowPhone`
        padding-inline-start: 0;
    `}
`

export const Placeholder = styled(Paragraph)`
    max-width: 696px;

    white-space: pre-wrap;
`

export const Tasks = styled.div`
    margin-block-start: -24px;
`