import styled from 'styled-components'
import {
    svgStroke,
    fromTablet, belowTablet
} from 'utilities/styled'
import { Container as ContainerBase } from 'utilities/modal'
import { Clock as ClockBase } from 'styled-icons/feather'
import { Button, CloseButton as CloseButtonBase } from 'components/button'

export const Container = styled(ContainerBase)`
    margin-block: 15px; /* Sorry, it just works */
    height: min(1048px, calc(100svh - 32px));
    max-height: unset;
    padding: 0;

    &.preview {
        border: 4px dashed var(--huma-color-border-default);
    }
`

export const Layout = styled.div`
    timeline-scope: --survey-scroll;
    overflow: hidden;

    display: grid;
    grid-template-rows:
        [hero] max-content
        [header] max-content
        [content] minmax(0, 1fr);
    grid-template-columns: 1fr;

    height: 100%;

    ${belowTablet`
        grid-template-columns: minmax(0, 1fr);
    `}
`

export const Intro = styled.div`
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    grid-row: header / content-end;
    grid-column: 1 / -1;
    justify-content: center;
    gap: 40px;

    margin-inline: auto;
    max-width: var(--huma-global-content-tight-width);
    width: 100%;
    padding-block-end: 16px;
    padding-inline: 32px;

    transition: .2s ease;
    transition-property: opacity, transform, display;
    transition-behavior: allow-discrete;

    &.running {
        opacity: 0;

        ${fromTablet`
            transform: translateY(-32px);
        `}

        ${belowTablet`
            transform: translateX(-32px);
        `}

        @supports (transition-behavior: allow-discrete) {
            display: none;
        }
    }
`

export const Greeting = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
`

export const Clock = styled(ClockBase)`
    ${svgStroke()}

    margin-block-start: -2px;
    margin-inline-end: 4px;
`

export const StartButton = styled(Button)`
    align-self: start;
`

export const Hero = styled.div`
    position: sticky;
    top: 0;
    grid-row: hero;
    grid-column: 1 / -1;

    padding-inline: 56px;

    ${belowTablet`
        padding-inline: 32px;
    `}
`

export const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-block: 12px;
`

export const CloseButton = styled(CloseButtonBase)`
    position: relative;
    top: 0;
    left: -8px;
`