import React, { Component, createContext, useContext } from 'react'
import { useCompetenceRecords } from 'contexts/competence-records'
import { get, post, patch, remove } from 'api'

const CompetenceRecordContext = createContext()
CompetenceRecordContext.displayName = 'CompetenceRecord'

class CompetenceRecordProvider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            record: null,
            deleted: false,

            fetchRecord: this.fetch,
            addRecord: this.add,
            updateRecord: this.update,
            removeRecord: this.remove,

            uploadDocumentation: this.uploadDocumentation,
            removeDocumentation: this.removeDocumentation,
            getDocumentationPreviewUrl: this.getDocumentationPreviewUrl,
            getDocumentationDownloadUrl: this.getDocumentationDownloadUrl
        }
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetch()
    }

    fetch = async (id = this.props.id) => {
        if(!id) {
            return
        }

        const { response: record, ok } = await get({ path: `/competence/${id}` })

        !!ok && this.setState({ record })

        return { ok, response: record }
    }

    add = async body => {
        const { response, ok } = await post({
            path: '/competence',
            body
        })

        if(ok && response) {
            this.props.records.pushRecord(response)
        }

        return { ok, response }
    }

    update = async body => {
        const { id } = this.props

        const { ok, response: record } = await patch({
            path: `/competence/${id}`,
            body
        })

        if(ok && record) {
            this.setState({ record })
            this.props.records.updateRecordLocally(body, id)
        }

        return { ok, response: record }
    }

    remove = async () => {
        const { id } = this.props

        const { ok } = await remove({
            path: `/competence/${id}`,
            returnsData: false
        })

        !!ok && this.setState({ deleted: true })

        return { ok }
    }

    uploadDocumentation = async (body, recordId) => {
        const { ok, response } = await post({
            path: `/competence/${recordId}/attachments`,
            body
        })

        if(ok && response) {
            this.setState(({ record }) => ({
                record: {
                    ...record,
                    attachments: [
                        ...record?.attachments ?? [],
                        response
                    ]
                }
            }))

            const { record } = this.state

            this.props.records.updateRecordLocally({
                ...record,
                attachments: [
                    ...record?.attachments ?? [],
                    response
                ]
            }, recordId)
        }

        return { ok, response }
    }

    removeDocumentation = async (recordId, id) => {
        const { ok } = await remove({
            path: `/competence/${recordId}/attachments/${id}`,
            returnsData: false
        })

        this.setState(({ record }) => ({
            record: {
                ...record,
                attachments: record.attachments.filter(({ id: attachmentId }) => attachmentId !== id)
            }
        }))

        this.props.records.updateRecordLocally({
            ...this.state.record,
            attachments: this.state.record.attachments.filter(({ id: attachmentId }) => attachmentId !== id)
        }, recordId)

        return { ok }
    }

    getDocumentationPreviewUrl = (recordId, attachmentId) => {
        return `/competence/${recordId}/attachments/${attachmentId}/preview`
    }

    getDocumentationDownloadUrl = (recordId, attachmentId) => {
        return `/competence/${recordId}/attachments/${attachmentId}/download`
    }

    render() {
        const { children = null } = this.props

        return (
            <CompetenceRecordContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </CompetenceRecordContext.Provider>
        )
    }
}

export default props => {
    const records = useCompetenceRecords()

    return (
        <CompetenceRecordProvider
            {...props}
            records={records} />
    )
}

export const useCompetenceRecord = () => useContext(CompetenceRecordContext)