import React, { Component, createContext, createRef, useContext } from 'react'
import { intersectionObserver } from 'utilities/dom'
import isEqual from 'react-fast-compare'
import { get, post, put, patch, remove } from 'api'
import debounce from 'lodash.debounce'
import { size, reduce, withoutEmptyArrays } from 'utilities/object'

const TasksContext = createContext()
TasksContext.displayName = 'Tasks'

export default class TasksProvider extends Component {
    constructor(props) {
        super(props)

        const { eternal = true } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500 })

        if(eternal) {
            this.intersectionObserver = intersectionObserver(this.onIntersect)
        }

        this.pagingDefaults = pagingDefaults(props?.paging)

        this.flash = createRef()

        this.state = {
            tasks: props?.tasks ?? [],
            total: 0,
            filter: props?.filter ?? {},
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            fetch: this.fetch,
            setTasksFilter: this.setFilter,

            fetchTask: this.fetchTask,
            addTask: this.addTask,
            updateTask: this.updateTask,
            assignTask: this.assignTask,
            toggleTaskCompleted: this.toggleTaskCompleted,
            removeTask: this.removeTask,

            flash: this.flash,
            clearFlash: this.clearFlash,

            hasFetched: false,
            autoFetch: false,
            fetching: false,
            loading: false
        }
    }

    componentDidMount() {
        const {
            fetchAccess = true,
            fetchOnMount = true
        } = this.props

        if(fetchAccess && fetchOnMount) {
            this.fetchDebounced()
        }
    }

    componentDidUpdate(props, { filter }) {
        const pagingChanged = !isEqual(props.paging, this.props.paging)
        const filterChanged = !isEqual(filter, this.state.filter)
        const tasksChanged = !isEqual(props.tasks, this.props.tasks)

        const { fetchAccess: previousFetchAccess = true } = props
        const { fetchAccess = true } = this.props
        const fetchAccessGained = !previousFetchAccess && fetchAccess

        const state = {}

        if(pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props?.paging)
            state.paging = this.pagingDefaults()
        }

        if(tasksChanged) {
            state.tasks = this.props.tasks
            state.fixed = !!this.props.tasks
        }

        this.setState(size(state) ? state : null, () => {
            if(filterChanged || fetchAccessGained) {
                this.fetchDebounced(true)
            }
        })
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver?.destroy?.()
    }

    fetch = async (force = false) => {
        const {
            fixed,
            fetching,
            filter,
            paging,
            autoFetch,
            hasFetched,
            eternal
        } = this.state

        if((fixed || fetching || (hasFetched && !eternal)) && !force) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetched ? (paging.offset + this.pagingDefaults().limit) : 0,
            limit: paging.limit
        }

        const { ok, response } = await get({
            path: '/tasks',
            params: {
                ...filter,
                ...nextPaging
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
            this.setState(({ tasks: previousTasks }) => {
                const previousTasksWithoutFetched = previousTasks.filter(({ id: addedId }) => {
                    return !response.items.find(({ id: itemId }) => addedId === itemId)
                })

                const tasks = [
                    ...previousTasksWithoutFetched,
                    ...response.items
                ]

                return {
                    tasks,
                    total: response.total,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        limit: this.pagingDefaults().limit,
                        hasNextPage: response.items.length && tasks.length < response.total
                    },
                    hasFetched: true,
                    autoFetch: hasFetched,
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                hasFetched: true,
                fetching: false,
                loading: false
            })
        }
    }

    fetchNextTask = async () => {
        const {
            fetching,
            filter,
            paging,
            total
        } = this.state

        if(fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({ fetching: true })

        const { ok, response } = await get({
            path: '/tasks',
            params: {
                ...filter,
                ...paging
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
            this.setState(({ tasks: previousTasks }) => {
                const previousTasksWithoutFetched = previousTasks.filter(({ id: addedId }) => {
                    return !response.items.find(({ id: itemId }) => addedId === itemId)
                })

                const tasks = [
                    ...previousTasksWithoutFetched,
                    ...response.items
                ]

                return {
                    tasks,
                    paging: {
                        ...paging,
                        hasNextPage: response.items.length && tasks.length < total
                    },
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                fetching: false,
                loading: false
            })
        }
    }

    fetchTask = async id => await get({ path: `/tasks/${id}` })

    addTask = async ({ body, disappearsFromView }) => {
        const { ok, response: task } = await post({
            path: '/tasks',
            body
        })

        if(ok && task && !disappearsFromView?.(task)) {
            this.setState(({ tasks }) => {
                this.flash.current = task

                return {
                    tasks: [
                        task,
                        ...tasks
                    ]
                }
            })
        }

        return { ok, response: task }
    }

    updateTask = async ({ body, id, disappearsFromView }) => {
        const { ok, response: task } = await patch({
            path: `/tasks/${id}`,
            body
        })

        if(ok && task) {
            this.setState(({ tasks }) => {
                if(!!disappearsFromView?.(task)) {
                    return {
                        tasks: tasks.filter(task => task.id !== id)
                    }
                }

                const index = tasks.findIndex(task => task.id === id)
                this.flash.current = task

                return {
                    tasks: [
                        ...tasks.slice(0, index),
                        task,
                        ...tasks.slice(index + 1, tasks.length)
                    ]
                }
            })
        }

        return { ok, response: task }
    }

    assignTask = (id, assignedTo) => this.updateTask({
        body: { assignedTo },
        id
    })

    toggleTaskCompleted = async (id, fetchNext = false) => {
        const {
            tasks,
            paging,
            total: previousTotal
        } = this.state
        const index = tasks.findIndex(task => task.id === id)
        let task = tasks[index]

        const { response, ok } = await put({ path: `/tasks/${id}/togglecompleted` })

        if(ok) {
            task = {
                ...task,
                ...response
            }

            const checked = !!response?.completedAt
            const total = checked ? previousTotal - 1 : previousTotal + 1
            const shouldFetchNextPage = (!!fetchNext && !!paging?.hasNextPage && checked)

            this.setState(({ tasks }) => ({
                tasks: [
                    ...tasks.slice(0, index),
                    task,
                    ...tasks.slice(index + 1, tasks.length)
                ],
                ...(shouldFetchNextPage ? {
                    paging: {
                        ...paging,
                        offset: paging.limit + paging.offset - 1,
                        limit: 1,
                        hasNextPage: tasks.length < total
                    }
                } : null)
            }), () => shouldFetchNextPage && this.fetchNextTask(true))
        }

        return { ok, response: task }
    }

    removeTask = async id => {
        const { ok } = await remove({
            path: `/tasks/${id}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ total, tasks, paging }) => ({
                tasks: tasks.filter(task => task.id !== id),
                total: total - 1,
                paging: {
                    ...paging,
                    offset: paging.offset - 1,
                    limit: paging.limit + 1
                }
            }))
        }

        return { ok }
    }

    setFilter = (filter = {}) => {
        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }))

        const nextFilter = {
            ...(this.props.filter ?? null),
            ...filter
        }

        this.setState(({ filter: previousFilter }) => {
            const filterChanged = !isEqual(
                withoutEmptyArrays(nextFilter),
                withoutEmptyArrays(previousFilter)
            )

            if(!filterChanged) {
                return null
            }

            return {
                tasks: [],
                filter: nextFilter,
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }
        })
    }

    onIntersect = () => {
        const {
            eternal,
            loading,
            paging,
            autoFetch
        } = this.state

        if(!eternal) {
            return
        }

        if(!loading && paging.hasNextPage && autoFetch) {
            this.fetchDebounced()
        }
    }

    clearFlash = () => void this.setState(({ tasks }) => {
        this.flash.current = null

        return {
            tasks: [...tasks]
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <TasksContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </TasksContext.Provider>
        )
    }
}

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

export const useTasks = () => useContext(TasksContext)