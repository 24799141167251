import React, { Component, createContext, useContext } from 'react'
import { useAuth, tripletexQueryParamNames } from 'contexts/auth'
import { useI18n } from 'contexts/i18n'
import { useLocation, useNavigate } from 'react-router-dom'
import { get, post, getUrl } from 'api'
import { local as storage } from 'utilities/storage'
import { sleep } from 'utilities/async'
import queryString from 'query-string'
import paths from 'app/paths'
import { omit } from 'utilities/object'

const LoginContext = createContext()
LoginContext.displayName = 'Login'

class LoginProvider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            native: props.auth.native,
            step: 1,
            organizationId: null,
            providers: {},
            provider: null,
            code: null,
            key: props.auth.key,
            keyPrefilled: props.auth.keyPrefilled,
            previouslyUsedKey: props.auth.previouslyUsedKey,
            keySubmitted: false,
            otp: {},
            forgot: false,
            recipient: {},
            checkingKey: false,
            keyNotFound: false,
            backendError: false,
            networkError: false,
            username: null,
            finalizing: false,
            tokenFailed: false,

            loading: false,

            checkKey: this.checkKey,
            forgotKey: this.forgotKey,
            findKey: this.findKey,
            resetErrors: this.resetErrors,

            connectWithSocialProvider: this.connectWithSocialProvider,

            connectWithInternalProvider: this.connectWithInternalProvider,
            finalizeWithInternalOtpProvider: this.finalizeWithInternalOtpProvider,

            goToFirstStep: this.goToFirstStep,
            goToPreviousStep: this.goToPreviousStep
        }
    }

    componentDidMount() {
        const {
            auth,
            location: { search }
        } = this.props

        const {
            code,
            state,
            arrived,
            ...query
        } = queryString.parse(search)

        // First, check if we loaded the app as a result of
        // returning from a social provider. Return early if so.
        if(code && state) {
            const {
                key,
                organizationId,
                provider,
                referrer,
                integration
            } = JSON.parse(state)

            const authReferralState = {
                referrer,
                integration
            }

            const loginState = {
                key,
                organizationId,
                code,
                provider
            }

            return void auth.setReferralState(
                authReferralState,
                () => this.setState(
                    loginState,
                    () => {
                        // Remove state from URL
                        this.props.navigate(paths.root, { replace: true })
                        this.finalizeWithSocialProvider()
                    }
                )
            )
        }

        // Second, check if we loaded the app as a result of arriving
        // on the correct Huma domain after a successful login.
        // Return early if so.
        if(arrived) {
            const {
                accessToken,
                refresh_token,
                referrer,
                integration
            } = JSON.parse(arrived)

            const authReferralState = {
                referrer,
                integration
            }

            return void auth.setReferralState(
                authReferralState,
                () => {
                    auth.onSignIn({
                        accessToken,
                        refresh_token
                    })
                }
            )
        }

        requestAnimationFrame(() => {
            this.props.navigate(
                queryString.stringifyUrl({
                    url: paths.root,
                    query: omit(query, ...tripletexQueryParamNames)
                }),
                { replace: true }
            )
        })

        // If not, we want to fetch the providers for the key, if given
        const { key } = this.state
        if(!!key) {
            this.checkKey({ key })
        }
    }

    forgotKey = () => this.setState({
        step: 2,
        key: '',
        forgot: true
    })

    findKey = async ({ method, ...details }) => {
        const body = {
            method,
            username: details[method]
        }

        this.setState({ loading: true })

        const { ok } = await post({
            path: '/auth/forgot/key',
            body,
            returnsData: false
        })

        if(ok) {
            this.setState({
                step: 3,
                recipient: {
                    ...details,
                    method
                }
            })
        }

        this.setState({ loading: false })
    }

    checkKey = async ({ key }) => {
        key = key.trim()

        this.resetErrors({ checkingKey: true })

        const { ok, status } = await this.fetchProviders(key)

        const isNumericHttpStatus = Number.isFinite(Number(status))

        this.setState({
            keySubmitted: true,
            checkingKey: false,
            ...(ok && {
                step: 2,
                key,
                forgot: false
            }),
            ...(!ok && {
                keyNotFound: isNumericHttpStatus && status < 500,
                backendError: isNumericHttpStatus && status >= 500,
                networkError: status === '(Network error)'
            })
        })

        return { ok }
    }

    resetErrors = (state = {}) => void this.setState({
        ...state,
        keyNotFound: false,
        backendError: false,
        networkError: false
    })

    fetchProviders = async key => {
        const { ok, response, status } = await get({
            path: '/auth/providers',
            params: { key }
        })

        if(ok && response) {
            const { organizationId, ...providers } = response
            const { hostname } = global.location

            this.setState({
                organizationId,
                providers: {
                    ...providers,
                    ...(hostname.endsWith('.herokuapp.com') ? { social: [] } : null)
                }
            })
        }

        return { ok, status }
    }

    connectWithSocialProvider = async provider => {
        const {
            native: {
                initial,
                session,
                platform
            },
            organizationId,
            key
        } = this.state

        const { auth } = this.props

        storage.set('login:key', key)

        // Wait for a brief moment to make sure the key is written to storage
        await sleep(20)

        global.location = queryString.stringifyUrl({
            url: getUrl(`/auth/providers/${provider}`),
            query: {
                key,
                redirectUri: this.buildRedirectUri(),
                state: JSON.stringify({
                    organizationId,
                    key,
                    provider,
                    ...(!!auth.referrer ? { referrer: auth.referrer } : null),
                    ...(!!auth.integration ? { integration: auth.integration } : null),
                    ...((!!initial || !!session) ? { native: platform } : null)
                })
            }
        })
    }

    finalizeWithSocialProvider = async () => {
        this.setState({
            step: 3,
            finalizing: true
        })

        const {
            organizationId,
            code,
            provider
        } = this.state

        const { ok, response } = await post({
            path: '/auth/oauth/token',
            body: {
                redirectUri: this.buildRedirectUri(),
                grant_type: 'authorization_code',
                client_id: organizationId,
                code,
                provider
            }
        })

        if(!ok) {
            this.setState({
                username: response.interpolations.id,
                finalizing: false,
                tokenFailed: true
            })
        }

        if(ok && response) {
            this.props.auth.onSignIn(response)
        }
    }

    connectWithInternalProvider = async ({ method, password, ...data }) => {
        const {
            organizationId,
            key
        } = this.state

        storage.set('login:key', key)

        this.setState({ loading: true })

        // Wait for a brief moment to make sure the key is written to storage
        await new Promise(resolve => global.setTimeout(resolve, 20))

        if(password) {
            return await this.connectWithInternalPasswordProvider({
                grant_type: 'password',
                client_id: organizationId,
                username: data[method],
                password
            })
        }

        const protocol = {
            email: 'email',
            phone: 'sms'
        }[method]

        return await this.connectWithInternalOtpProvider({
            grant_type: `${protocol}_otp`,
            client_id: organizationId,
            method,
            protocol,
            ...data
        })
    }

    connectWithInternalPasswordProvider = async data => {

    }

    connectWithInternalOtpProvider = async ({ grant_type, method, protocol, ...body }) => {
        this.setState({
            step: 3,
            otp: {
                grant_type,
                method,
                ...body
            }
        })

        await post({
            path: `/auth/passwordless/${protocol}`,
            body,
            returnsData: false
        })

        this.setState({ loading: false })

        // Deliberately avoid giving feedback to the user here.
        // !response.ok means they’re not registered in the given
        // organization, but they’ll have to go back themselves
        // ‘if they for some reason didn’t receive a code’ ( ͡° ͜ʖ ͡°)
    }

    finalizeWithInternalOtpProvider = async ({ code }) => {
        this.setState({ finalizing: true })

        const {
            otp,
            key
        } = this.state

        const { ok, response } = await post({
            path: '/auth/oauth/token',
            body: {
                ...omit(otp, 'method'),
                code
            }
        })

        if(!ok) {
            this.setState({
                finalizing: false,
                tokenFailed: true
            })
        }

        if(ok && response) {
            this.props.auth.onSignIn({ ...response, key })
        }
    }

    buildRedirectUri() {
        const { protocol, hostname, host } = global.location
        const localhost = hostname === 'localhost'
        const heroku = hostname.endsWith('.herokuapp.com')

        const redirectHost = localhost ?
            host :
            heroku ?
                hostname :
                `auth.${host.split('.').slice(1).join('.')}`

        return `${protocol}//${redirectHost}${paths.root}`
    }

    goToFirstStep = () => this.setState({
        step: 1,
        keyPrefilled: false
    })

    goToPreviousStep = () => this.setState(({ step, forgot }) => {
        if(step === 1) {
            this.props.navigate(paths.root, { replace: true })
            return null
        }

        const state = {
            step: step - 1
        }

        if(step === 2 && forgot) {
            state.forgot = false
        }

        return state
    })

    render() {
        const { children = null } = this.props

        return (
            <LoginContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </LoginContext.Provider>
        )
    }
}

export default props => {
    const auth = useAuth()
    const { locale } = useI18n()
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <LoginProvider
            {...props}
            auth={auth}
            locale={locale}
            location={location}
            navigate={navigate} />
    )
}

export const useLogin = () => useContext(LoginContext)