import React, { Component, createContext, useContext, createRef } from 'react'
import { get, post, remove } from 'api'
import { getPluralizedType } from 'pages/processes/utilities'
import { useAccess } from 'contexts/access'
import { intersectionObserver } from 'utilities/dom'
import { local } from 'utilities/storage'
import debounce from 'lodash.debounce'
import isEqual from 'react-fast-compare'
import { size, reduce, withoutEmptyArrays } from 'utilities/object'

const ProcessesContext = createContext()
ProcessesContext.displayName = 'Processes'

class ProcessesProvider extends Component {
    constructor(props) {
        super(props)

        const {
            type,
            eternal = true
        } = props

        this.fetchController = new AbortController()
        this.fetchDebounced = debounce(this.fetch, 100, { maxWait: 500, leading: false, trailing: true })

        this.intersectionObserver = intersectionObserver(this.onIntersect)
        this.sortCacheKey = props?.sortCacheKey

        let sorting = sortingDefaults(type)
        if(this.sortCacheKey) {
            const cachedSorting = local.get(this.sortCacheKey)
            if(cachedSorting) {
                sorting = cachedSorting
            }
        }

        this.pagingDefaults = pagingDefaults(props?.paging)

        this.flash = createRef()

        this.state = {
            processes: [],
            type,
            filter: props?.filter ?? {},
            sorting,
            paging: this.pagingDefaults(),
            eternal,
            ...(eternal ? { intersecter: this.intersectionObserver.ref } : null),

            flash: this.flash,
            clearFlash: this.clearFlash,

            archiveProcess: this.archiveProcess,
            unarchiveProcess: this.unarchiveProcess,
            removeProcess: this.removeProcess,

            fetch: this.fetch,
            toggleSorting: this.toggleSorting,
            setProcessesFilter: this.setFilter,

            hasFetched: false,
            autoFetch: false,
            fetching: false,
            loading: false
        }
    }

    componentDidMount() {
        const { fetchOnMount = true } = this.props
        fetchOnMount && this.fetchDebounced()
    }

    componentDidUpdate({ type, paging }, { filter, sorting }) {
        const typeChanged = type !== this.props.type
        const filterChanged = !isEqual(filter, this.state.filter)
        const pagingChanged = !isEqual(paging, this.props.paging)
        const sortingChanged = !isEqual(sorting, this.state.sorting)

        const state = {}

        if(typeChanged) {
            state.type = this.props.type
            state.sorting = sortingDefaults(this.props.type)
        }

        if(typeChanged || pagingChanged) {
            this.pagingDefaults = pagingDefaults(this.props.paging)
            state.paging = this.pagingDefaults()
        }

        this.setState(size(state) ? state : null, () => {
            if(typeChanged || filterChanged || sortingChanged) {
                this.fetchDebounced(true)
            }
        })
    }

    componentWillUnmount() {
        this.fetchController.abort()
        this.intersectionObserver.destroy()
    }

    fetch = async (force = false) => {
        const {
            fetching,
            sorting,
            filter,
            paging,
            autoFetch,
            hasFetched,
            eternal
        } = this.state

        if((fetching || (hasFetched && !eternal)) && !force) {
            return
        }

        if(force || fetching) {
            this.fetchController.abort()
            this.fetchController = new AbortController()
        }

        this.setState({
            fetching: true,
            ...(autoFetch ? { loading: true } : null)
        })

        const nextPaging = {
            offset: hasFetched ? (paging.offset + this.pagingDefaults().limit) : 0,
            limit: paging.limit
        }

        const { ok, response } = await get({
            path: `/${getPluralizedType(this.state.type)}`,
            params: {
                ...getFetchFilter(filter),
                ...nextPaging,
                orderBy: sorting.by,
                orderDirection: sorting.direction
            },
            signal: this.fetchController.signal
        })

        if(ok && response) {
//             let usersOk = false
//             let users = []
//
//             if(!this.props.access.check('users:manage') && !!response.items.length) {
//                 const result = await get({
//                     path: '/users',
//                     params: {
//                         ids: response.items.map(({ user }) => user.id),
//                         permissions: ['unit:users:manage']
//                     }
//                 })
//
//                 usersOk = result.ok
//                 users = result.ok ? result.response.items : []
//             }

            this.setState(({ processes: previousProcesses }) => {
                const previousProcessesWithoutFetched = previousProcesses.filter(({ id: addedId }) => {
                    return !response.items.find(({ id: itemId }) => addedId === itemId)
                })

                const processes = [
                    ...previousProcessesWithoutFetched,
                    ...response.items/*.map(item => ({
                        ...item,
                        access: {
                            unit: !!(usersOk && users?.find(({ id }) => id === item.user.id))
                        }
                    }))*/
                ]

                return {
                    processes,
                    paging: {
                        ...paging,
                        ...nextPaging,
                        limit: this.pagingDefaults().limit,
                        hasNextPage: response.items.length && processes.length < response.total
                    },
                    hasFetched: true,
                    autoFetch: hasFetched,
                    fetching: false,
                    loading: false
                }
            })
        } else {
            this.setState({
                hasFetched: true,
                autoFetch: false,
                fetching: false,
                loading: false
            })
        }
    }

    archiveProcess = async id => {
        const { response, ok } = await post({ path: `/${getPluralizedType(this.state.type)}/${id}/archive` })

        if(ok) {
            this.setState(({ processes, paging }) => ({
                processes: processes.filter(process => process.id !== id),
                paging: {
                    ...paging,
                    offset: paging.offset - 1,
                    limit: paging.limit + 1
                }
            }))
        }

        return { response, ok }
    }

    unarchiveProcess = async id => {
        const { response, ok } = await post({ path: `/${getPluralizedType(this.state.type)}/${id}/unarchive` })

        if(ok) {
            this.setState(({ processes, paging }) => ({
                processes: processes.filter(process => process.id !== id),
                paging: {
                    ...paging,
                    offset: paging.offset - 1,
                    limit: paging.limit + 1
                }
            }))
        }

        return { response, ok }
    }

    removeProcess = async id => {
        const { ok } = await remove({
            path: `/${getPluralizedType(this.state.type)}/${id}`,
            returnsData: false
        })

        if(ok) {
            this.setState(({ processes, paging }) => ({
                processes: processes.filter(event => event.id !== id),
                paging: {
                    ...paging,
                    offset: paging.offset - 1,
                    limit: paging.limit + 1
                }
            }))
        }

        return { ok }
    }

    setFilter = (filter = {}, sorting = {}) => {
        const { type } = this.state

        filter = reduce(filter, (accumulator, value, key) => ({
            ...accumulator,
            ...((!!value || value === 0) ? { [key] : value } : null)
        }))

        const nextFilter = {
            ...(this.props.filter ?? null),
            ...filter
        }

        sorting = {
            ...sortingDefaults(type),
            ...sorting
        }

        this.setState(({ filter: previousFilter, sorting: previousSorting }) => {
            const filterChanged = !isEqual(
                withoutEmptyArrays(nextFilter),
                withoutEmptyArrays(previousFilter)
            )

            const sortingChanged = !isEqual(sorting, previousSorting)

            if(!filterChanged) {
                return null
            }

            const state = {
                processes: [],
                paging: this.pagingDefaults(),
                hasFetched: false,
                autoFetch: false
            }

            if(filterChanged) {
                state.filter = nextFilter
            }

            if(sortingChanged) {
                state.sorting = sorting
                !!this.sortCacheKey && local.set(this.sortCacheKey, sorting)
            }

            return state
        })
    }

    toggleSorting = field => {
        const { type } = this.state
        const sortingOptions = getSortingOptions(type)

        if(field in sortingOptions) {
            this.setState(({ sorting }) => {
                const toggled = {
                    by: field,
                    direction: (sorting.by === field) ?
                        (sorting.direction === 'asc' ? 'desc' : 'asc') :
                        sortingOptions[field]
                }

                !!this.sortCacheKey && local.set(this.sortCacheKey, toggled)

                return {
                    processes: [],
                    sorting: toggled,
                    paging: this.pagingDefaults(),
                    hasFetched: false,
                    autoFetch: false
                }
            })
        }
    }

    onIntersect = () => {
        const {
            eternal,
            loading,
            paging,
            autoFetch
        } = this.state

        if(!eternal) {
            return
        }

        if(!loading && paging.hasNextPage && autoFetch) {
            this.fetchDebounced()
        }
    }

    clearFlash = () => void this.setState(({ processes }) => {
        this.flash.current = null

        return {
            processes: [...processes]
        }
    })

    render() {
        const { children = null } = this.props

        return (
            <ProcessesContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </ProcessesContext.Provider>
        )
    }
}

const getSortingOptions = type => ({
    name: 'asc',
    ...(type === 'onboarding' ? { firstDayOfWork: 'asc' } : null),
    ...(type === 'offboarding' ? { lastDayOfWork: 'asc' } : null),
    ...(type === 'process' ? { referenceDate: 'desc' } : null)
})

const sortingDefaultsMap = {
    process: 'referenceDate'
}

const sortingDefaults = type => {
    const sortingOptions = getSortingOptions(type)

    const by = sortingDefaultsMap?.[type] ?? Object.keys(sortingOptions)[0]
    const direction = sortingOptions[by]

    return { by, direction }
}

const pagingDefaults = (overrides = {}) => () => ({
    offset: 0,
    limit: 10,
    ...overrides,
    hasNextPage: false
})

const getFetchFilter = filter => {
    filter = { ...filter }

    if(!!filter?.concernsId?.length) {
        const [concernsId] = filter.concernsId

        filter.concernsId = concernsId.id
    }

    return filter
}

export const useProcesses = () => useContext(ProcessesContext)

export default props => {
    const access = useAccess()

    return (
        <ProcessesProvider
            {...props}
            access={access} />
    )
}