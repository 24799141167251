import React, { Component, createContext, useContext } from 'react'
import { get, post, patch, remove } from 'api'
import { refreshAbsenceTypes } from 'pages/absence/utilities'

const AbsenceTypeContext = createContext()
AbsenceTypeContext.displayName = 'AbsenceType'

class AbsenceTypeProvider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            type: null,
            deleted: false,

            fetchType: this.fetch,
            updateType: this.update,
            removeType: this.remove,

            updatePolicy: this.updatePolicy,
            updateDefaultPolicy: this.updateDefaultPolicy,
            archivePolicy: this.archivePolicy,

            hasFetched: false
        }
    }

    componentDidMount() {
        !!this.props.id && this.fetch()
    }

    fetch = async typeId => {
        const id = typeId || this.props.id
        const { includeUnassignedUsers = false } = this.props

        const { response: type, ok } = await get({
            path: `/absence/types/${id}`,
            params: { includeUnassignedUsers }
        })

        this.setState({
            ...(ok ? { type } : null),
            hasFetched: true
        })

        return { ok, response: type }
    }

    update = async body => {
        const { id } = this.props

        const { ok, response: type } = await patch({
            path: `/absence/types/${id}`,
            body
        })

        if(ok && type) {
            this.setState({ type })
        }

        return { ok, response: type }
    }

    remove = async () => {
        const { id } = this.props

        const { ok } = await remove({
            path: `/absence/types/${id}`,
            returnsData: false
        })

        !!ok && this.setState({ deleted: true })

        return { ok }
    }

    updatePolicy = async (body, policyId) => {
        const { id } = this.props

        const { ok, response } = await patch({
            path: `/absence/policies/${policyId}`,
            body: {
                ...body,
                typeId: id
            }
        })

        if(ok && response) {
            this.setState(({ type: previousType }) => {
                const shouldSwitchDefault = ('default' in body)
                const shouldArchive = !!body?.archived

                if(shouldSwitchDefault) {
                    const oldDefaultIndex = previousType.policies.findIndex(({ default: isDefault }) => !!isDefault)
                    const newDefaultIndex = previousType.policies.findIndex(({ id }) => id === policyId)

                    const { policies } = previousType

                    policies[oldDefaultIndex] = {
                        ...policies[oldDefaultIndex],
                        default: false
                    }

                    if(!!body?.default) {
                        policies[newDefaultIndex] = {
                            ...policies[newDefaultIndex],
                            default: true
                        }
                    }

                    return {
                        ...previousType,
                        policies
                    }
                }

                const policyIndex = previousType.policies.findIndex(({ id }) => id === policyId)

                const type = {
                    ...previousType,
                    policies: [
                        ...previousType.policies.slice(0, policyIndex),
                        response,
                        ...previousType.policies.slice(policyIndex + 1, previousType.policies.length)
                    ]
                }

                shouldArchive && refreshAbsenceTypes()

                return type
            })
        }

        return { ok, response }
    }

    updateDefaultPolicy = (policyId, toDefault) => this.updatePolicy({ default: toDefault }, policyId)

    archivePolicy = async (policyId, toPolicyId) => {
        const { ok } = await post({
            path: `/absence/policies/${policyId}/archive`,
            body: {
                reAssignUsersToPolicy: toPolicyId,
                fromDate: null
            },
            returnsData: false
        })

        if(ok) {
            this.setState(({ type }) => ({
                type: {
                    ...type,
                    policies: type.policies.filter(policy => policy.id !== policyId)
                }
            }))
        }

        return { ok }
    }

    render() {
        const { children = null } = this.props

        return (
            <AbsenceTypeContext.Provider value={this.state}>
                {(typeof children === 'function') && children(this.state)}
                {(typeof children !== 'function') && children}
            </AbsenceTypeContext.Provider>
        )
    }
}

export default AbsenceTypeProvider

export const useAbsenceType = () => useContext(AbsenceTypeContext)