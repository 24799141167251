import React, { forwardRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useForm } from 'components/form/controller'
import { getUserAgent } from 'utilities/user-agent'
import { pick } from 'utilities/object'
import { compact } from 'utilities/array'
import { hasValue } from '../'
import { Wrapper, TitleWrapper, OrdinalAndTitle, Ordinal, Title, Field, ShortcutParagraph, Shortcut, Footer } from './s'
import { Required } from 'components/form/field/s'
import { Plain } from 'components/button'
import Paragraph from 'components/typography/paragraph'
import FieldPropsProvider from './field'

const Question = forwardRef(({
    question, fieldRef,
    proceed,
    index, isCurrent = false, last = false,
    salt
}, ref) => {
    const {
        fields,
        values
    } = useForm()

    const { isMobile } = getUserAgent()

    const [whistle, setWhistle] = useState(Date.now())

    const field = fields?.[question.id]
    const value = values?.[question.id]

    const showUnsetButton = !question?.required && !!field?.unset
    const unsetButtonDisabled = !hasValue(value, question.type)

    let limitationText = null

    if(question.type === 'multiple_select') {
        if('min' in question && 'max' in question) {
            limitationText = {
                id: 'survey_multiple_select_limitation_both',
                defaultMessage: 'Select at least {min} and max {max} options.'
            }
        }

        if('min' in question && !('max' in question)) {
            limitationText = {
                id: 'survey_multiple_select_limitation_min',
                defaultMessage: '{min, plural, =0 {} =1 {Select at least 1 option.} other {Select at least {min} options.}}'
            }
        }

        if(!('min' in question) && 'max' in question) {
            limitationText = {
                id: 'survey_multiple_select_limitation_max',
                defaultMessage: 'Select up to {max} options.'
            }
        }
    }

    return (
        <Wrapper ref={ref}>
            <TitleWrapper>
                <OrdinalAndTitle>
                    <Ordinal {...(!isCurrent ? { className: 'inactive' } : null)}>
                        {index + 1}
                    </Ordinal>
                    <Title>
                        {question.title}
                        {!!question?.required && <Required>*</Required>}
                    </Title>
                </OrdinalAndTitle>
            </TitleWrapper>
            {question?.description && (
                <Paragraph className="caption compact">{question?.description}</Paragraph>
            )}
            <Field>
                <FieldPropsProvider
                    field={question}
                    fieldRef={fieldRef}
                    proceed={proceed}
                    index={index}
                    current={isCurrent}
                    whistle={whistle}
                    salt={salt} />
                {(['text', 'textarea'].includes(question.meta?.type) && !last && !isMobile) && (
                    <ShortcutParagraph className="caption small compact">
                        <FormattedMessage
                            id="shortcut_to_continue"
                            defaultMessage="Press {shortcut} to continue."
                            values={{
                                shortcut: (
                                    <Shortcut
                                        shortcut={compact([
                                            (question.meta.type === 'textarea') && 'mod',
                                            'enter'
                                        ])}
                                        salt={`${salt}:${question.id}:shortcut`} />
                                )
                            }} />
                    </ShortcutParagraph>
                )}
                {(!!question?.helperText || limitationText || showUnsetButton) && (
                    <Footer {...((!question?.helperText && !limitationText) ? { className: 'only-reset' } : null)}>
                        {(!!question?.helperText || limitationText) && (
                            <Paragraph className="caption compact">
                                {question?.helperText ?? null}
                                {(!!question.helperText && limitationText) && <br />}
                                {limitationText && (
                                    <FormattedMessage
                                        {...limitationText}
                                        values={pick(question, 'min', 'max')} />
                                )}
                            </Paragraph>
                        )}
                        {showUnsetButton && (
                            <Plain
                                className="destructive text-aligned"
                                onClick={() => {
                                    field.unset()
                                    setWhistle(Date.now())
                                }}
                                disabled={unsetButtonDisabled}>
                                <FormattedMessage
                                    id="action_reset"
                                    defaultMessage="Reset" />
                            </Plain>
                        )}
                    </Footer>
                )}
            </Field>
        </Wrapper>
    )
})

export default Question